import { Type, Static } from '@sinclair/typebox';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import getTemplateSchema from '../getTemplateSchema';
import { LabelSchema } from '../../Config/sharedSchemas';

const featureSchema = Type.Object({
  title: LabelSchema({ title: 'Title', description: 'The title of the feature' }),
  featureName: selectableEnum(FeatureName, {
    title: 'Feature Name',
    description: 'The name of the recommended feature'
  })
});

export type Feature = Static<typeof featureSchema>;

const options = Type.Object({
  leftAxisTitle: LabelSchema({ title: 'Left Axis Title', description: 'Feature label for the left axis' }),
  rightAxisTitle: LabelSchema({ title: 'Right Axis Title', description: 'Feature label for the right axis' }),
  recommendedFeatures: Type.Array(featureSchema, {
    title: 'Recommended Features',
    description: 'The list of recommended features to be shown on this page'
  }),
  historicalFeatures: Type.Array(featureSchema, {
    title: 'Historical Features',
    description: 'The list of historical features to be shown on this page'
  }),
  leftRangeStart: Type.Optional(
    Type.Number({
      title: 'Left Range Start',
      description: 'Optional range start value for left axis'
    })
  ),
  leftRangeEnd: Type.Optional(
    Type.Number({
      title: 'Left Range End',
      description: 'Optional range end value for left axis'
    })
  ),
  rightRangeStart: Type.Optional(
    Type.Number({
      title: 'Right Range Start',
      description: 'Optional range start value for right axis'
    })
  ),
  rightRangeEnd: Type.Optional(
    Type.Number({
      title: 'Right Range End',
      description: 'Optional range end value for right axis'
    })
  )
});

export type Options = Static<typeof options>;
export const templateSlug = 'HistoricalRecommendationsMultiAxisPage';

export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Historical Recommendations (Multi Axis)',
  description: 'Historical recommendations page supporting more then 2 features'
});

export type TemplateSchema = Static<typeof templateSchema>;
