import { Type, TObject } from '@sinclair/typebox';
import { LabelSchema } from '../Config/sharedSchemas';

/**
 * Provide a generic schema to declare the configuration of a Template
 */
function getTemplateSchema<TSlug extends string, TOption extends TObject>({
  templateSlug,
  options
}: {
  templateSlug: TSlug;
  options: TOption;
}) {
  return {
    type: Type.Literal('page', {
      default: 'page',
      readOnly: true,
      title: 'Type',
      description: 'Ignore this field'
    }),
    template: Type.Literal(templateSlug, {
      default: templateSlug,
      readOnly: true,
      title: 'Template',
      description: 'Ignore this field'
    }),
    path: Type.String({
      pattern: '^(/[^/ ]*)+/?$',
      title: 'Path',
      description: 'The path at which this page will be available',
      examples: ['/your-page-path']
    }),
    label: LabelSchema({ title: 'Label', description: 'The label of the page e.g. for the NavBar' }),
    featureFlag: Type.Optional(
      Type.String({
        title: 'Feature Flag',
        description: 'The feature flag to enable this page, if you want it to be feature flag protected'
      })
    ),
    options
  };
}

export default getTemplateSchema;
