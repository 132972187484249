import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from './EzerThemeProvider';
import PageContainer from './PageContainer';
import LoadingMessage from './lib/LoadingMessage';

type Props = {
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    position: 'fixed',
    top: `calc(50% - ${spacing(2.25)}px)`,
    right: 0,
    left: 0
  }
}));

const LoadingPage = ({ children }: Props) => {
  const styles = useStyles();
  return (
    <PageContainer>
      <div data-testid="loading-page" className={styles.root}>
        <LoadingMessage>{children}</LoadingMessage>
      </div>
    </PageContainer>
  );
};
export default LoadingPage;
