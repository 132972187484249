import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useNow from '../useNow';

const ONE_SECOND = 1000;

const useCurrentTime = (): Date => {
  const now = useNow();
  const [searchParams] = useSearchParams();
  const isTimeTravelActive = searchParams.has('now');
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, ONE_SECOND);
    return () => clearInterval(intervalId);
  });
  return isTimeTravelActive ? now : currentTime;
};

export default useCurrentTime;
