import * as msg from '../../../constants/Message';
import { useEzerTheme } from '../../../EzerThemeProvider';
import { Feature } from '../schema';
import { LocalisedMessage } from '../../../lib';

type ColumnData = {
  field: string;
  // eslint-disable-next-line
  headerName: any;
  width: number;
};

const Time = () => <LocalisedMessage descriptor={msg.TableTimestamp} />;
const Feedback = () => <LocalisedMessage descriptor={msg.TableFeedback} />;

const useColumns = (features: Feature[]): ColumnData[] => {
  const { spacing } = useEzerTheme();

  const columns = [
    {
      field: 'timestamp',
      headerName: <Time />,
      width: spacing(20)
    }
  ];

  features.forEach(({ featureName, title }) => {
    columns.push({
      field: featureName,
      headerName: <>{title.default}</>,
      width: spacing(30)
    });
  });

  columns.push({ field: 'feedback', headerName: <Feedback />, width: spacing(60) });
  return columns;
};

export default useColumns;
