import { FeatureName } from '../types';
import useMetadata from './useMetadata';

type Response = {
  data: string;
  isLoading: boolean;
};
const useItem = (featureName: FeatureName): Response => {
  const { data, isLoading } = useMetadata([featureName]);
  return {
    data: data?.[featureName]?.unit ?? '',
    isLoading
  };
};
export default useItem;
