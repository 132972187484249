import { Static, Type } from '@sinclair/typebox';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import getTemplateSchema from '../getTemplateSchema';

const toggleOptionSchema = Type.Object({
  buttonText: Type.String({ title: 'Button Title', description: 'Text that show on the toggle button' }),
  rightAxisTitle: Type.String({ title: 'Right Axis Title', description: 'The title of the feature' })
});

const options = Type.Object(
  {
    chartTitle: Type.String({ title: 'Chart Title', description: 'The title of the chart' }),
    featureTitle: Type.String({ title: 'Feture Title', description: 'The title of the feature' }),
    featureName: selectableEnum(FeatureName, {
      title: 'Feature Name',
      description: 'The name of the feature'
    }),
    leftAxisTitle: Type.String({ title: 'Left Axis Title', description: 'The title of the feature' }),
    toggleOptions: Type.Array(toggleOptionSchema, {
      title: 'Toggle Options',
      description: 'The selected option is displayed on the right axis'
    })
  },
  { title: 'Options', description: 'Options for the Historical Weekly Performance Page template' }
);

export type Options = Static<typeof options>;

export const templateSlug = 'HistoricalWeeklyPerformancePage';

export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Historical Weekly Performance Page',
  description: 'Displays weekly performance for given feature'
});

export type TemplateSchema = Static<typeof templateSchema>;

export const templateUiSchema = {
  featureFlag: {
    'ui:options': {
      // placeholder to show how to set a field option
      disabled: false
    }
  }
};
