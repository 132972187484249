import { FeatureName } from '../types';

const getUnit = (featureName: FeatureName): string => {
  // using any here is the cleanest way I can find of addressing an object build with part of an enum
  // will go away when there are units for all the feature names
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const UNITS: any = {
    [FeatureName.s_ph_sil_tput]: 't/h',
    [FeatureName.p_shc]: 'kcal/kg',
    [FeatureName.p_shc2]: 'kcal/kg',
    [FeatureName.p_shc_calc]: 'kcal/kg',
    [FeatureName.p_shc_target]: 'kcal/kg',
    [FeatureName.p_shc_reaction]: 'kcal/kg',
    [FeatureName.f_k_mass]: 't/h',
    [FeatureName.f_pc_burner_coal_mass]: 't/h',
    [FeatureName.s_c_sol_xrf_fcao]: '%',
    [FeatureName.p_ph1_gol_fan_speed_pct]: '%',
    [FeatureName.s_c_sol_sa_ratio]: 'ratio',
    [FeatureName.s_c_sol_xrd_c3s]: '%',
    [FeatureName.p_k_speed]: 'rpm',
    [FeatureName.g_ph_gol_o2]: '%',
    [FeatureName.f_sfc_calc]: 'R$/t',
    // for voto ni
    // [FeatureName.p_ph_gol_fan_speed]: 'rpm',
    [FeatureName.p_ph_gol_fan_speed]: '%',
    [FeatureName.g_k_gol_o2]: '%',
    [FeatureName.g_k_gol_co]: '%',
    // for voto ni
    // [FeatureName.g_k_gol_co]: 'mg/Nm³',
    [FeatureName.g_pc_gol_o2]: '%',
    [FeatureName.g_pc_gol_o2_target]: '%',
    [FeatureName.g_pc_gol_co]: 'ppm',
    [FeatureName.p_k_torque]: 'A',
    [FeatureName.p_k_torque_target]: 'A',
    [FeatureName.s_c_sol_fcao]: '%',
    [FeatureName.f_k_tput]: 't/h',
    [FeatureName.f_k_tput_calc]: 't/h',
    [FeatureName.f_k_tput_augmented]: 't/h',
    [FeatureName.f_pc_burner1_tput]: 't/h',
    [FeatureName.f_pc_burner2_tput]: 't/h',
    [FeatureName.g_pc_gol_temp]: '°C',
    [FeatureName.g_pc_gol_temp_target]: '°C',
    [FeatureName.g_ph_cy5_gol_temp]: '°C',
    [FeatureName.p_ph_cy5_pid_gol_temp_setpoint]: '°C',
    [FeatureName.s_ph_sil_sm]: '%',
    [FeatureName.f_pc_tput]: 't/h',
    [FeatureName.f_pc_af_tput]: 't/h',
    [FeatureName.p_pc_gol_temp]: '°C',
    [FeatureName.g_ph_gol_o2]: '%',
    [FeatureName.p_ph_sil_tput]: 't/h',
    [FeatureName.s_c_sol_xrd_fcao]: '%',
    [FeatureName.g_ph_gol_co]: '%',
    [FeatureName.s_c_sol_xrf_fcao]: '%',
    [FeatureName.s_c_sol_xrf_fcao_soft]: '%',
    [FeatureName.s_ph_sol_doc]: '%',
    [FeatureName.s_ph_sol_doc_soft]: '%',
    [FeatureName.p_k_flame_temp_target]: '°C',
    [FeatureName.g_k_gol_nox_target]: 'ppm',
    [FeatureName.s_c_sol_3cs]: '%',
    [FeatureName.p_k_eo_torque_target]: '%'
  };
  return UNITS[featureName] || '';
};

export default getUnit;
