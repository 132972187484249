import classNames from 'classnames';
import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import Checkbox from '@mui/icons-material/CheckBox';
import CheckboxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { useLegendToggles } from '../../utils';

type Props = {
  id: string;
  supportToggle: boolean;
  children: ReactNode;
};

const useStyles = createUseStyles({
  root: {
    display: 'inline-block',
    marginTop: -14
  },
  supportToggle: {
    cursor: 'pointer'
  },
  icon: {
    position: 'relative',
    top: 6
  }
});
const LegendItem = ({ id, supportToggle, children }: Props) => {
  const styles = useStyles();
  const legendToggles = useLegendToggles();
  const selected = legendToggles.includes(id);

  return (
    <span className={classNames(styles.root, { [styles.supportToggle]: supportToggle })}>
      {children}
      {supportToggle && (
        <>{selected ? <Checkbox className={styles.icon} /> : <CheckboxOutlineBlank className={styles.icon} />}</>
      )}
    </span>
  );
};

export default LegendItem;
