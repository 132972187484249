import type { Locale } from '../useCustomer';

const getDefaultLocale = (language: string): Locale => {
  const SPANISH_LOCALES = ['es', 'es-ES'];
  const PORTUGUESE_LOCALES = ['pt', 'pt-PT', 'pt-BR'];
  if (SPANISH_LOCALES.includes(language)) {
    return 'es';
  }
  if (PORTUGUESE_LOCALES.includes(language)) {
    return 'pt-br';
  }
  return 'en';
};

const useBrowserLocale = (): Locale => {
  const { language } = navigator;
  return getDefaultLocale(language);
};

export default useBrowserLocale;
