import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../EzerThemeProvider';
import Tick from './Tick';
import TimelineItem from './TimelineItem';
import Tooltip from './Tooltip';
import TimelineData from './TimelineData';

type Props = {
  data: TimelineData[];
  dataTestId: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    paddingTop: spacing(1.5),
    paddingBottom: spacing(1),
    position: 'relative'
  },
  list: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
    paddingTop: spacing(0.5),
    paddingRight: spacing(0.25),
    paddingLeft: spacing(0.25),
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    gap: spacing(0.25),
    backgroundColor: palette.carbon,
    borderBottom: `1px solid ${palette.white}`
  },
  item: {
    backgroundColor: palette.leaf,
    height: spacing(4),
    width: spacing(1),
    position: 'relative'
  },
  redItem: {
    backgroundColor: palette.lava
  },
  amberItem: {
    backgroundColor: palette.flame
  },
  blackItem: {
    backgroundColor: palette.charcoal
  }
}));

const TICK_COUNT = 7;

const shouldTickDisplay = (index: number, tickInterval: number, data: TimelineData[]): boolean => {
  // always render a tick at the start and end of the timeline
  if (index === 0 || index === data.length - 1) {
    return true;
  }
  // render a tick at fixed intervals, but only if there is at least one interval in space before the end of the
  // timeline
  if (index % tickInterval === 0 && index <= data.length - tickInterval) {
    return true;
  }

  return false;
};

const Timeline = ({ data, dataTestId }: Props) => {
  const styles = useStyles();

  const tickInterval = Math.round(data.length / TICK_COUNT + 2);
  const ref = useRef<HTMLDivElement>(null);
  const width = ref.current?.getBoundingClientRect().width ?? 0;
  const [activeItem, setActiveItem] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState(0);

  const onHover = (position: number, index: number) => {
    setTooltipPosition(position);
    setActiveItem(index);
  };

  const resetTooltipPosition = useCallback(() => {
    const child = ref.current?.firstChild?.firstChild;
    if (child) {
      // @ts-ignore
      const { offsetLeft = 0, offsetWidth = 0 } = child;
      const position = offsetLeft + offsetWidth / 2;
      onHover(position, 0);
    }
  }, []);

  useEffect(() => {
    resetTooltipPosition();
  }, [resetTooltipPosition]);

  return (
    <div className={styles.root} ref={ref} data-testid={dataTestId}>
      <ul className={styles.list} onMouseLeave={resetTooltipPosition}>
        {data.map((plantStatus, index) => (
          <TimelineItem
            key={plantStatus.timestamp.getTime()}
            className={classNames(
              styles.item,
              { [styles.redItem]: plantStatus.colour === 'red' },
              { [styles.amberItem]: plantStatus.colour === 'amber' },
              { [styles.blackItem]: plantStatus.colour === 'black' }
            )}
            onHover={onHover}
            index={index}>
            {shouldTickDisplay(index, tickInterval, data) && <Tick timestamp={plantStatus.timestamp} />}
          </TimelineItem>
        ))}
      </ul>
      {data.length > 0 && (
        <Tooltip data={data[activeItem]} itemPosition={tooltipPosition} containerWidth={width} index={activeItem} />
      )}
    </div>
  );
};

export default Timeline;
