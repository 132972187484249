import React from 'react';
import { Line as RechartsLine } from 'recharts';

// eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
const Line = (props: any) => <RechartsLine {...props} type="monotone" dot={false} strokeWidth={2} />;
Line.defaultProps = RechartsLine.defaultProps;
Line.displayName = RechartsLine.displayName;
Line.getComposedData = RechartsLine.getComposedData;
Line.repeat = RechartsLine.repeat;
Line.renderDotItem = RechartsLine.renderDotItem;

export default Line;
