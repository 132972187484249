import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../EzerThemeProvider';
import useCurrentShift from './useCurrentShift';

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    display: 'inline-block',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    paddingLeft: spacing(4),
    lineHeight: `${spacing(3)}px`
  }
}));

const CurrentShift = () => {
  const styles = useStyles();
  const currentShift = useCurrentShift();

  return (
    <span className={styles.root}>
      <FormattedMessage defaultMessage="Shift: {currentShift}" id="labels.currentShift" values={{ currentShift }} />
    </span>
  );
};

export default CurrentShift;
