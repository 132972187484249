import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';
import * as msg from '../constants/Message';
import LocalisedMessage from './LocalisedMessage';

type Props = {
  children: ReactNode;
  yAxisLabel: string;
  dataTestId?: string;
  unit?: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    position: 'relative',
    paddingLeft: spacing(2)
  },
  label: {
    fontSize: spacing(2.5),
    color: palette.white,
    textAlign: 'center'
  },
  leftLabel: {
    transform: 'rotate(180deg)',
    top: 0,
    bottom: spacing(2.5),
    left: 0,
    position: 'absolute',
    writingMode: 'vertical-lr'
  }
}));

const LabelledContainer = ({ children, yAxisLabel, dataTestId, unit }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.root} data-testid={dataTestId}>
      {children}
      {yAxisLabel && (
        <div className={classNames(styles.leftLabel, styles.label)}>
          {yAxisLabel}
          {unit && <> ({unit})</>}
        </div>
      )}
      <div className={styles.label}>
        <LocalisedMessage descriptor={msg.Date} />
      </div>
    </div>
  );
};

export default LabelledContainer;
