import React from 'react';
import { QueryClientProvider } from 'react-query';
import client from './client';

type Props = {
  children: React.ReactNode;
};

const ReactQueryProvider = ({ children }: Props) => (
  <QueryClientProvider client={client}>{children}</QueryClientProvider>
);

export default ReactQueryProvider;
