{
  "data": {
    "shc": {
      "currentValue": 123.45,
      "averageValue": 133.45,
      "bestTenPercentValue": 113.45
    },
    "specific_cooler_exhaust_loss": {
      "currentValue": 133.45,
      "averageValue": 123.45,
      "bestTenPercentValue": 113.45
    },
    "clinker_heat_loss": {
      "currentValue": 33,
      "averageValue": 23,
      "bestTenPercentValue": 13
    },
    "calcination_energy": {
      "currentValue": 466,
      "averageValue": 456,
      "bestTenPercentValue": 446
    },
    "shell_loss": {
      "currentValue": 88,
      "averageValue": 78,
      "bestTenPercentValue": 68
    },
    "fuel_evaporation_loss": {
      "currentValue": 22,
      "averageValue": 12,
      "bestTenPercentValue": 2
    },
    "stack_loss": {
      "currentValue": 244,
      "averageValue": 234,
      "bestTenPercentValue": 214
    }
  },
  "plant_status": "OK"
}
