/* eslint-disable @typescript-eslint/no-explicit-any */
import { differenceInSeconds, set, subHours } from 'date-fns';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import usePlantStatusQuery from '../../../../serviceQueries/usePlantStatusQuery';
import { PlantStatusName } from '../../../../types';
import useNow from '../../../../useNow';
import { useMinuteInterval } from '../../../../utils';
import bufferTime from '../bufferTime';
import getRefreshMoment from './getRefreshMoment';

export type PlantStatus = {
  timestamp: Date;
  status: PlantStatusName;
};

type Response = {
  data: PlantStatus[];
  isLoading: boolean;
};

const STATUS_ITEMS_IN_A_DAY = 96;

const usePlantStatus = (): Response => {
  const now = useNow();
  const [endDate, setEndDate] = useState(getRefreshMoment(set(now, { seconds: 0, milliseconds: 0 })));
  const startDate = subHours(endDate, 25);
  const { data: plantStatusData = [], isLoading } = usePlantStatusQuery(startDate, endDate);

  const [searchParams] = useSearchParams();
  const isTimeTravelActive = searchParams.has('now');

  useMinuteInterval(() => {
    if (!isTimeTravelActive) {
      setEndDate(getRefreshMoment(new Date()));
    }
  });

  const orderedData = [...plantStatusData].reverse();

  const data = orderedData
    .reduce((acc: any, datum: any) => {
      if (acc.length === 0 || acc[acc.length - 1].timestamp !== datum.timestamp) {
        acc.push(datum);
      }
      return acc;
    }, [])
    .slice(0, STATUS_ITEMS_IN_A_DAY)
    .map((datum: any) => ({
      timestamp: new Date(datum.timestamp),
      status: datum.status as PlantStatusName
    }));

  // set current status to previous status if the new item is within the buffer time.
  // This is to give enough time for the Back End to clear the initial 'UNKNOWN' out
  if (
    data.length >= 2 &&
    data[0].status === 'UNKNOWN' &&
    differenceInSeconds(now, data[0].timestamp) <= bufferTime.seconds + bufferTime.minutes * 60
  ) {
    data[0].status = data[1].status;
  }

  return { data, isLoading };
};
export default usePlantStatus;
