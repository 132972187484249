/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartConfig } from '../useCustomer';
import * as msg from '../constants/Message';
import useLocalisedLabel from './useLocalisedLabel';
import { LocalisedMessage } from '../lib';

export enum Column {
  Date = 'date',
  Measured = 'measured',
  Predicted = 'predicted'
}

interface ColumnData {
  field: Column;
  headerName: any;
  width: number;
}

const DateTime = () => <LocalisedMessage descriptor={msg.DateTime} />;

const useHistoricalQualityColumnData = (chartConfigs: ChartConfig[]): ColumnData[] => {
  const predictedLabel = useLocalisedLabel(chartConfigs[0].title);
  const measuredLabel = useLocalisedLabel(chartConfigs[1].title);
  return [
    { field: Column.Date, headerName: <DateTime />, width: 200 },
    { field: Column.Predicted, headerName: predictedLabel, width: 180 },
    { field: Column.Measured, headerName: measuredLabel, width: 180 }
  ];
};

export default useHistoricalQualityColumnData;
