import { KpiData } from './useKpiData';

const getBarSize = (data: KpiData) => {
  const barCount = data.periods.length;
  const percentage = 100 / barCount;
  const offset = percentage / 6;
  return Math.round(percentage + offset);
};

export default getBarSize;
