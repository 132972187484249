import { getIsoWeek } from '../utils';
import useAuthorisedQuery from './useAuthorisedQuery';
import { Api } from '../settings';
import { FeatureName } from '../types';

const useWeeklyPerformanceQuery = (featureName: FeatureName, startDate: Date, endDate: Date) => {
  const startPeriod = getIsoWeek(startDate);
  const endPeriod = getIsoWeek(endDate);
  return useAuthorisedQuery(
    ['weekly-performance', startPeriod, endPeriod, featureName],
    `weekly-performance/?feature=${featureName}&start_period=${startPeriod}&end_period=${endPeriod}`,
    {},
    Api.inference
  );
};

export default useWeeklyPerformanceQuery;
