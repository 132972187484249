import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../../../EzerThemeProvider';

type Props = {
  children: string;
  bottom: number;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    fontSize: 10,
    lineHeight: 1,
    position: 'absolute',
    left: 0,
    right: spacing(0.5),
    textAlign: 'right'
  }
}));
const Ticks = ({ children, bottom }: Props) => {
  const styles = useStyles();
  return (
    <li className={styles.root} style={{ bottom: `calc(${bottom}% - 5px)` }}>
      {children}
    </li>
  );
};

export default Ticks;
