const TICK_COUNT = 5;
const useYTicks = (rangeStart?: number, rangeEnd?: number): number[] | undefined => {
  if (rangeStart === undefined || rangeEnd === undefined) {
    return undefined;
  }
  const ticks = [];
  const period = (rangeEnd - rangeStart) / (TICK_COUNT - 1);
  for (let counter = 0; counter < TICK_COUNT; counter++) {
    ticks.push(rangeStart + period * counter);
  }
  return ticks;
};

export default useYTicks;
