import { TogglesData } from '../../../lib/Toggles/Toggles';
import convertStringToKey from './convertStringToKey';

const useTogglesData = (values: string[]): TogglesData =>
  values.map((value) => ({
    value: convertStringToKey(value),
    label: value
  }));

export default useTogglesData;
