import { differenceInMinutes } from 'date-fns';

type FlatData = {
  date: number;
  value?: number;
}[];

const RATE = 5;

const useInterpolatedData = (data: FlatData): FlatData =>
  data.reduce((accumulator: FlatData, item, index) => {
    if (index === 0 || item.value !== undefined) {
      return [...accumulator, item];
    }
    if (differenceInMinutes(new Date(accumulator[accumulator.length - 1].date), new Date(item.date)) >= RATE) {
      return [...accumulator, item];
    }
    return accumulator;
  }, []);

export default useInterpolatedData;
