const getUtcDate = (date: Date) => {
  const formatAsTwoCharacters = (number: number) => `0${number}`.slice(-2);
  const year = date.getUTCFullYear();
  const month = formatAsTwoCharacters(date.getUTCMonth() + 1);
  const day = formatAsTwoCharacters(date.getUTCDate());
  const hours = formatAsTwoCharacters(date.getUTCHours());
  const minutes = formatAsTwoCharacters(date.getMinutes());
  const seconds = formatAsTwoCharacters(date.getUTCSeconds());

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export default getUtcDate;
