import { GasAnalyserData, GasAnalyserDataItem } from '../templates/TodayPage/lib/GasAnalyserSection/useGasAnalyserData';

type ReferenceAreaDataItem = {
  start: number;
  end: number | undefined;
};

const useHighGasAnalyserData = (data: GasAnalyserData): ReferenceAreaDataItem[] => {
  const reducedData = data.reduce((highData: ReferenceAreaDataItem[], item: GasAnalyserDataItem, currentIndex) => {
    const activeDataItem = highData.length > 0 ? highData.find((area) => area.end === undefined) : undefined;

    if (activeDataItem === undefined) {
      if (item.high) {
        // start new data item
        highData.push({
          start: item.date,
          end: undefined
        });
      }
    }
    if (activeDataItem !== undefined) {
      if (!item.high) {
        activeDataItem.end = data[currentIndex - 1].date;
      }
    }
    return highData;
  }, []);
  // close the last segment it it's still open
  if (reducedData.length > 0 && reducedData[reducedData.length - 1].end === undefined) {
    reducedData[reducedData.length - 1].end = data[data.length - 1].date;
  }
  return reducedData;
};
export default useHighGasAnalyserData;
