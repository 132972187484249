import React from 'react';
import { TooltipProps } from 'recharts';
import { FormattedMessage } from 'react-intl';
import { ChartTooltip, LocalisedMessage } from '../../../../lib';
import { getLocalDateAndTime, useTimezone } from '../../../../utils';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import * as msg from '../../../../constants/Message';

type Props = TooltipProps<string, string> & { title: string; baseline?: number; unit?: string };

const DateDescriptor = () => <LocalisedMessage descriptor={msg.Date} />;
const BaselineDescriptor = () => <FormattedMessage defaultMessage="Baseline" id="page.today.kpi.baseline" />;
const TooltipContent = ({ title, label, payload = [], baseline, unit }: Props) => {
  const { palette } = useEzerTheme();
  const payloadItems = payload.map(({ value, color }) => ({
    label: (
      <>
        {title}: {value} {unit}
      </>
    ),
    color
  }));
  const baselineItem = {
    label: (
      <>
        <BaselineDescriptor />: {baseline}
      </>
    ),
    color: palette.white
  };
  const timezone = useTimezone();

  const itemListData = !baseline ? payloadItems : [...payloadItems, baselineItem];

  const tooltipLabel = label ? (
    <>
      <DateDescriptor />: {getLocalDateAndTime(new Date(label), timezone)}
    </>
  ) : (
    <></>
  );
  return <ChartTooltip label={tooltipLabel} itemListData={itemListData} />;
};

export default TooltipContent;
