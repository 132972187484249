import type { PlantData } from './useHistoricalPlantData';
import type { SoftSensor } from './useHistoricalSoftSensors';
import type { FeatureName } from '../types';
import type QualityChartData from '../types/QualityChartData';
import useNewKpiFeatureConfig from './useNewKpiFeatureConfig';
import type { KPIFeatureConfig } from '../Config/sharedSchemas';

const isInBounds = (value: number, kpiFeatureConfig?: KPIFeatureConfig) => {
  if (
    // eslint-disable-next-line
    kpiFeatureConfig === undefined ||
    // eslint-disable-next-line
    kpiFeatureConfig.lowerBound === undefined ||
    kpiFeatureConfig.upperBound === undefined
  ) {
    return true;
  }
  return value > kpiFeatureConfig.lowerBound && value < kpiFeatureConfig.upperBound;
};

const useNewCombinedQualityChartData = (
  modelledData: SoftSensor[],
  measuredData: PlantData,
  modelledFeatureName: FeatureName,
  measuredFeatureName: FeatureName
): QualityChartData => {
  const kpiFeatureConfig = useNewKpiFeatureConfig(modelledFeatureName);
  const { items } = measuredData;

  return items.map((item) => {
    const date = new Date(item.timestamp).getTime();
    const measured = item.data[measuredFeatureName]?.value;
    const predicted = modelledData.find((datum) => datum.timestamp.getTime() === date)?.value;
    return {
      date,
      predicted,
      measured,
      predictedInBounds: predicted === undefined ? true : isInBounds(predicted, kpiFeatureConfig)
    };
  });
};
export default useNewCombinedQualityChartData;
