import { useContext } from 'react';
import { FulfillmentRecorderContext } from '../FulfillmentRecorderProvider';

const useFulfillmentRecorder = () => {
  const context = useContext(FulfillmentRecorderContext);
  if (context === undefined) {
    throw new Error('useFulfillmentRecorder must be used within a FulfillmentRecorderContext');
  }
  return context;
};

export default useFulfillmentRecorder;
