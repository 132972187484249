import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../EzerThemeProvider';

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    display: 'block',
    backgroundColor: palette.white,
    padding: [spacing(1), spacing(2)],
    color: palette.carbon,
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `${palette.blackShadow} 0 0 0 150px inset`
    }
  }
}));

const MenuButton = ({ children, onClick, className }: Props) => {
  const styles = useStyles();
  return (
    <button type="button" className={classNames(styles.root, className)} onClick={onClick}>
      {children}
    </button>
  );
};

export default MenuButton;
