type Options = {
  method?: 'POST' | 'PATCH';
  headers: {
    authorization: string;
  };
  body?: string;
};

const request = (path: string, options: Options) => fetch(path, options);

export default request;
