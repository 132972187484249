import useConfig from '../Config/useConfig';
import { FeatureName } from '../types';

const useRecommendationDataFeatureConfig = (featureName?: FeatureName) => {
  const { data: config } = useConfig();

  return featureName !== undefined
    ? config?.app.recommendationDataFeatureConfigs.find((fc) => fc.feature.name === featureName)
    : undefined;
};

export default useRecommendationDataFeatureConfig;
