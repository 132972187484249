import React from 'react';
import { Bar, ComposedChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CartesianGrid, ChartTitle } from '../../../../lib';
import { useSevenTicks } from '../../utils';
import { getLocalTime, useTimezone } from '../../../../utils';
import { ChartData } from './useKpiByHourData';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import TooltipContent from './TooltipContent';
import { KpiChartConfig } from '../../schema';

type Props = {
  data: ChartData;
  unit: string;
  config: KpiChartConfig;
  startDate: Date;
  endDate: Date;
  addBufferSpace?: boolean;
};

const getBarSize = (periods: unknown[]) => {
  const barCount = periods.length;
  const percentage = 100 / barCount;
  const offset = percentage / 6;
  return Math.round(percentage + offset);
};

const getMaxValue = (data: ChartData, unit: string) => {
  const { periods = [], baseline = 0 } = data;
  const highestValue = periods.reduce(
    (current, period) => (period.averageValue > current ? period.averageValue : current),
    baseline
  );
  return unit === 'kcal/kg' ? Math.ceil(highestValue / 200) * 200 : Math.ceil(highestValue);
};

const Chart = ({ data, unit, config, startDate, endDate, addBufferSpace = false }: Props) => {
  const { palette, spacing } = useEzerTheme();
  const { feature, rangeStart, rangeEnd } = config;
  const { periods = [], baseline = 0 } = data;
  const timezone = useTimezone();
  const ticks = useSevenTicks(startDate, endDate);
  const bottomValue = rangeStart === undefined ? 0 : rangeStart;
  const topValue = rangeEnd === undefined ? getMaxValue(data, unit) : rangeEnd;
  const rightMargin = addBufferSpace ? spacing(9) : 0;
  const leftMargin = addBufferSpace ? spacing(0.5) : 0;
  return (
    <div data-testid="kpi-by-hour-chart">
      <ChartTitle>
        {feature.label.default} ({unit})
      </ChartTitle>
      <ResponsiveContainer width="100%" height={spacing(37)}>
        <ComposedChart data={periods} margin={{ top: spacing(1), right: rightMargin, bottom: 0, left: leftMargin }}>
          <CartesianGrid />
          <Bar dataKey="averageValue" barSize={spacing(getBarSize(periods))} fill={palette.leaf} />
          {baseline !== 0 && (
            <ReferenceLine y={baseline} stroke={palette.white} strokeDasharray="3 3" strokeWidth={2} />
          )}
          <XAxis
            dataKey="hour"
            allowDataOverflow
            tickFormatter={(tick: number) => getLocalTime(new Date(tick), timezone)}
            height={spacing(4)}
            ticks={ticks}
            type="number"
            domain={[ticks[0], ticks[ticks.length - 1]]}
          />
          <YAxis dataKey="averageValue" domain={[bottomValue, topValue]} allowDataOverflow width={spacing(6)} />
          <Tooltip content={<TooltipContent title={feature.label.default} baseline={baseline} unit={unit} />} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
