import { UseQueryResult } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import type { ConfigWrapperType } from '../Config/ConfigSchema';
import { Api } from '../settings';
import useAuthorisedQuery from './useAuthorisedQuery';

const useConfigQuery = (): UseQueryResult<ConfigWrapperType, unknown> => {
  const [search] = useSearchParams();
  const configAliasParam = search.get('configAlias');
  const path = configAliasParam ? `config/?alias=${configAliasParam}` : 'config/';

  return useAuthorisedQuery(path, path, { refetchOnMount: false }, Api.serve);
};

export default useConfigQuery;
