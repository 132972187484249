import { addHours, subHours } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import ChartTitle from '../ChartTitle';
import ChartContainer from './ChartContainer';
import useNow from '../../../../useNow';
import { useHistoricalPlantData, useHistoricalSoftSensors } from '../../../../utils';
import LoadingContent from '../LoadingContent';
import { GasAnalyserChartConfig } from '../../schema';

type Props = {
  config: GasAnalyserChartConfig;
};

const DEFAULT_SAMPLE_RATE = 2;

const GasAnalyserSection = ({ config }: Props) => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const endDate = addHours(now, 6);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const sampleRate = Number(query?.get('sampleRate')) || DEFAULT_SAMPLE_RATE;

  const { data: plantData, isLoading: isLoadingPlantData } = useHistoricalPlantData(
    [config.leftFeatureConfig.feature.name, config.rightFeatureConfig.feature.name],
    startDate,
    endDate,
    sampleRate,
    true
  );

  const { data: performanceData, isLoading: isLoadingPerformanceData } = useHistoricalSoftSensors(
    [config.performanceFeatureConfig.name],
    startDate,
    endDate,
    true
  );

  const isLoading = isLoadingPlantData || isLoadingPerformanceData;

  return (
    <section>
      <ChartTitle>{config.title.default}</ChartTitle>
      {isLoading && <LoadingContent />}
      {!isLoading && (
        <ChartContainer
          config={config}
          startDate={startDate}
          endDate={endDate}
          plantData={plantData}
          performanceData={performanceData}
        />
      )}
    </section>
  );
};

export default GasAnalyserSection;
