{
  "items": [
    {
      "timestamp": "2023-07-19T13:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 358.43,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T13:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 321.82,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T13:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 322.68,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T13:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 324.21,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T12:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 322.09,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T12:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 318.39,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T12:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 317.67,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T12:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 317.86,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T11:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 316.44,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T11:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 314.51,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T11:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 282.09,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T11:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 282.58,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T10:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 284.72,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.25,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T10:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 295.46,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.29,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T10:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 297.44,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T10:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 295.99,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T09:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 304.0,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.33,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T09:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 306.2,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T09:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 304.49,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T09:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 306.98,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T08:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 327.58,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T08:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 320.2,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T08:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 316.21,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T08:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 310.72,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T07:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 305.83,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T07:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 304.94,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T07:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 298.33,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T07:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 297.5,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T06:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 296.96,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T06:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 297.26,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T06:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 297.95,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T06:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 305.04,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T05:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 307.6,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "aboveBounds"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T05:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 309.15,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T05:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 310.4,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T05:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 314.83,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T04:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 313.97,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T04:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 317.32,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T04:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 311.82,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T04:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 302.67,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.42,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T03:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 297.52,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T03:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 295.25,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T03:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 293.87,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.39,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T03:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 289.4,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.36,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T02:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 297.1,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T02:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 299.43,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T02:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 307.92,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T02:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 306.52,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T01:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 307.05,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T01:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 306.35,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T01:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 289.58,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T01:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 291.29,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T00:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 290.02,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T00:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 287.88,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T00:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 286.71,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-19T00:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 285.39,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T23:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 288.34,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.41,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T23:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 288.12,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T23:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 282.14,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T23:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 277.06,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T22:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 277.68,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T22:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 279.23,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T22:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 282.03,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.35,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T22:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 278.24,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.32,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T21:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 271.32,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T21:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 271.09,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T21:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 272.18,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T21:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 274.11,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.3,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T20:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 295.99,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.38,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T20:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 318.93,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.47,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T20:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 321.86,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.48,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T20:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 318.83,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T19:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 314.83,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.45,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T19:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 305.51,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.4,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T19:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 299.43,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.37,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T19:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 329.69,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.5,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T18:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 341.73,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T18:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 342.88,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T18:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 344.65,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T18:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 344.43,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T17:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 341.94,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T17:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 344.17,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T17:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 347.04,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T17:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 350.62,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T16:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 352.99,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T16:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 352.17,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T16:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 350.98,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T16:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 351.8,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T15:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 354.11,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T15:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 358.42,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T15:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 360.21,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T15:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 358.53,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T14:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 364.12,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T14:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 368.07,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T14:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 369.87,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.55,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T14:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 386.69,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.6,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T13:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 387.26,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.6,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T13:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 389.54,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.61,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T13:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 392.34,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.6,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T13:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 407.76,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.67,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T12:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 412.17,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T12:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 408.51,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T12:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 411.29,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T12:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 422.76,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T11:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 444.79,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.86,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T11:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 430.3,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.82,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T11:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 412.15,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T11:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 411.15,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.8,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T10:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 402.13,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T10:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 403.62,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.8,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T10:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 395.38,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T10:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 395.01,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T09:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 393.86,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T09:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 396.44,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T09:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 396.69,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T09:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 398.25,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T08:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 402.76,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T08:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 404.89,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T08:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 406.1,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T08:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 408.78,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T07:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 411.99,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T07:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 403.55,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T07:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 396.66,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T07:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 392.73,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T06:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 394.55,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T06:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 393.22,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T06:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 390.84,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T06:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 398.24,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T05:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 397.64,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T05:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 401.33,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T05:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 408.48,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T05:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 420.77,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.84,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T04:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 407.66,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.82,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T04:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 378.39,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T04:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 374.38,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T04:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 381.09,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T03:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 387.48,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T03:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 400.52,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.82,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T03:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 399.85,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.83,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T03:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 399.83,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.83,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T02:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 380.3,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T02:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 374.41,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T02:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 371.7,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T02:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 375.43,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T01:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 375.45,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T01:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 380.12,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T01:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 385.29,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T01:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 393.8,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T00:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 396.72,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.81,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T00:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 392.04,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.78,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T00:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 389.5,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-18T00:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 389.47,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T23:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 382.89,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T23:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 385.03,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T23:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 385.42,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.77,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T23:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 386.49,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T22:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 399.57,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.84,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T22:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 406.35,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T22:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 408.48,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T22:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 390.51,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.8,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T21:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 388.86,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T21:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 390.78,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T21:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 410.88,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.86,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T21:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 425.03,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.91,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T20:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 445.23,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T20:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 450.06,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.99,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T20:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 446.63,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.99,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T20:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 441.73,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.97,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T19:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 439.93,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.96,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T19:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 443.39,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.96,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T19:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 443.83,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.95,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T19:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 433.56,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.93,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T18:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 425.49,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.87,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T18:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 425.54,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.84,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T18:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 401.01,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T18:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 387.83,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T17:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 387.12,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.7,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T17:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 401.98,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.74,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T17:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 406.05,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T17:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 408.69,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T16:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 410.2,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T16:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 410.96,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T16:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 413.07,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T16:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 417.12,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T15:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 420.81,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T15:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 424.09,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T15:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 433.62,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T15:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 442.26,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.81,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T14:45:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 447.44,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.82,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T14:30:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 438.66,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.79,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T14:15:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 430.71,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.76,
          "type": "sensor",
          "status": "ok"
        }
      }
    },
    {
      "timestamp": "2023-07-17T14:00:00Z",
      "data": {
        "p_pc_burner1_pfister_speed": {
          "value": 424.61,
          "type": "sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput_err_calc": {
          "value": 50,
          "type": "calculated-sensor",
          "status": "ok"
        },
        "f_pc_burner1_tput": {
          "value": 1.75,
          "type": "sensor",
          "status": "ok"
        }
      }
    }
  ],
  "metadata": {
    "p_pc_burner1_pfister_speed": {
      "unit": "rpm"
    },
    "f_pc_burner1_tput_err_calc": {
      "unit": "%"
    },
    "f_pc_burner1_tput": {
      "unit": "t/h"
    }
  }
}
