import { useSearchParams } from 'react-router-dom';

export const KEY = 'legendToggles';

type LegendToggles = string[];
const useToggledLegendItems = (): LegendToggles => {
  const [searchParams] = useSearchParams();
  const value = searchParams?.get(KEY);
  if (value) {
    return value.split(',');
  }
  return [];
};

export default useToggledLegendItems;
