import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { API_NADA_BASE_URL, API_SERVE_BASE_URL, API_INFERENCE_BASE_URL, Api } from '../settings';
import useAuthorisedRequest from './useAuthorisedRequest';

type Options = {
  refetchInterval?: number | false;
  enabled?: boolean;
  refetchOnMount?: boolean;
};

const getBaseUrl = (customer: string | null, api: Api) => {
  if (api === Api.inference) {
    return API_INFERENCE_BASE_URL;
  }
  if (api === Api.serve) {
    return API_SERVE_BASE_URL;
  }
  return API_NADA_BASE_URL;
};

const useAuthorisedQuery = (queryKey: string | string[], path: string, options?: Options, api = Api.nada) => {
  const [searchParams] = useSearchParams();
  const customer = searchParams.get('customer');
  const url = new URL(`${getBaseUrl(customer, api)}/${path}`);

  if (customer) {
    url.searchParams.append('plant_ref', customer);
  }

  const authorisedRequest = useAuthorisedRequest(api);
  const queryOptions: Options = {
    refetchInterval: false,
    enabled: true,
    refetchOnMount: true,
    ...options
  };

  const key = customer ? [customer].concat(queryKey) : queryKey;

  const queryFn = () => authorisedRequest(url.toString()).then((response) => response.json());

  return useQuery(key, queryFn, queryOptions);
};

export default useAuthorisedQuery;
