import { addHours, subHours } from 'date-fns';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { TemplateSchema } from './schema';
import { LoadingMessage, PageTitle } from '../../lib';
import FilterControls from '../../FilterControls/FilterControls';
import { useHistoricalPlantData, useHistoricalSoftSensors } from '../../utils';
import useNow from '../../useNow';
import useStartDate from '../../FilterControls/useStartDate';
import useEndDate from '../../FilterControls/useEndDate';
import useSampleRate from './useSampleRate';
import ChartContainer from './ChartContainer/ChartContainer';
import Table from './Table/Table';

const HistoricalGasAnalyserPage = ({ options, label }: TemplateSchema) => {
  const { leftFeature, rightFeature, performanceFeature } = options;
  const now = useNow();
  const startDate = useStartDate(subHours(now, 24));
  const endDate = useEndDate(addHours(now, 6));
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const defaultSampleRate = useSampleRate(startDate, endDate);
  const sampleRate = Number(query?.get('sampleRate')) || defaultSampleRate;

  const { data: plantData, isLoading: isLoadingPlantData } = useHistoricalPlantData(
    [leftFeature.featureName, rightFeature.featureName],
    startDate,
    endDate,
    sampleRate
  );

  const { data: performanceData, isLoading: isLoadingPerformanceData } = useHistoricalSoftSensors(
    [performanceFeature.featureName],
    startDate,
    endDate,
    false
  );

  const isLoading = isLoadingPlantData || isLoadingPerformanceData;

  return (
    <>
      <PageTitle>{label.default}</PageTitle>
      <FilterControls isLoading={isLoading} showFutureWindow />
      {isLoading && <LoadingMessage />}
      {!isLoading && startDate && endDate && plantData && (
        <>
          <ChartContainer
            startDate={startDate}
            endDate={endDate}
            options={options}
            plantData={plantData}
            performanceData={performanceData}
          />
          <Table leftFeature={leftFeature} rightFeature={rightFeature} data={plantData} />
        </>
      )}
    </>
  );
};
export default HistoricalGasAnalyserPage;
