import { useMessages } from '../../../LocalisationProvider';
import { getLocalDateAndTime, getLocalisedMessage, useTimezone } from '../../../utils';
import { Column } from './useColumnData';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import { Feature } from '../schema';

type TableChartPayload = {
  id: number;
  [Column.LeftValue]: string | number;
  [Column.RightValue]: string | number;
  [Column.Date]?: string;
};

const useTableData = (leftFeature: Feature, rightFeature: Feature, data: PlantData): TableChartPayload[] => {
  const messages = useMessages();
  const naLabel = getLocalisedMessage(messages, 'N/A', 'labels.na');
  const timezone = useTimezone();
  const { items = [] } = data;

  return items.map((item) => {
    const { timestamp } = item;
    return {
      id: new Date(timestamp).getTime(),
      [Column.LeftValue]: item.data[leftFeature.featureName]?.value ?? naLabel,
      [Column.RightValue]: item.data[rightFeature.featureName]?.value ?? naLabel,
      [Column.Date]: timestamp ? getLocalDateAndTime(new Date(timestamp), timezone) : undefined
    };
  });
};

export default useTableData;
