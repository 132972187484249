import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme, useEzerTheme } from '../EzerThemeProvider';

type ItemData = {
  label: string | ReactNode;
  color?: string;
};

type Props = {
  label?: string | ReactNode;
  itemListData: ItemData[];
};

const useStyles = createUseStyles(({ palette, fontWeight, spacing }: EzerTheme) => ({
  root: {
    backgroundColor: palette.rich,
    borderRadius: spacing(0.5),
    padding: [0, spacing(2), spacing(2), spacing(2)],
    overflow: 'hidden'
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  title: {
    color: palette.white,
    fontSize: spacing(2),
    lineHeight: '1em',
    fontWeight: fontWeight.semiBold,
    padding: 0,
    marginTop: spacing(2)
  },
  item: {
    color: palette.white,
    fontSize: spacing(2),
    marginTop: spacing(0.5),
    padding: 0,
    lineHeight: '1em'
  }
}));

const ChartTooltip = ({ label, itemListData }: Props) => {
  const styles = useStyles();
  const theme = useEzerTheme();
  return (
    <div className={styles.root}>
      {label && <h2 className={styles.title}>{label}</h2>}
      <ul className={styles.list}>
        {itemListData.map((itemData, index) => {
          const color = itemData.color || theme.palette.white;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className={styles.item} style={{ color }}>
              {itemData.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChartTooltip;
