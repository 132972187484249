import React from 'react';
import { createUseStyles } from 'react-jss';

type Props = {
  children: string | React.ReactNode;
};

const useStyles = createUseStyles({
  root: {
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: 0,
    margin: 0
  }
});
const PageTitle = ({ children }: Props) => {
  const styles = useStyles();
  return <h1 className={styles.root}>{children}</h1>;
};

export default PageTitle;
