export interface AuthEventPayload {
  user: string;
  customer: string;
}

export const customerAuthorisationEvent = 'customerauthorisation';
declare global {
  interface WindowEventMap {
    [customerAuthorisationEvent]: CustomEvent<AuthEventPayload>;
  }
}

export const authPromise = new Promise<AuthEventPayload>((res) => {
  const listener = (event: CustomEvent<AuthEventPayload>) => {
    res(event.detail);
  };
  window.addEventListener(customerAuthorisationEvent, listener);
});

// waiting for auth can be timed out with the following
// const auth: Promise<AuthEventPayload | undefined> = withTimeout(10_000, auth);
export const asyncSetTimeout = (millis: number) =>
  new Promise<undefined>((res) => {
    setTimeout(() => {
      res(undefined);
    }, millis);
  });
export const withTimeout = <T>(millis: number, promise: T) => Promise.race([promise, asyncSetTimeout(millis)]);
