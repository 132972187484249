import React from 'react';
import useGasAnalyserData from './useGasAnalyserData';
import GasAnalyserChart from './GasAnalyserChart';
import { SoftSensor } from '../../../../utils/useHistoricalSoftSensors';
import { PlantData } from '../../../../utils/useHistoricalPlantData';
import { useUnit } from '../../../../utils';
import { GasAnalyserChartConfig } from '../../schema';

type Props = {
  startDate: Date;
  endDate: Date;
  config: GasAnalyserChartConfig;
  plantData: PlantData;
  performanceData: SoftSensor[];
};

const ChartContainer = ({ startDate, endDate, config, plantData, performanceData }: Props) => {
  const data = useGasAnalyserData(startDate, endDate, plantData, performanceData, config);
  const { data: leftUnit } = useUnit(config.leftFeatureConfig.feature.name);
  const { data: rightUnit } = useUnit(config.rightFeatureConfig.feature.name);

  return (
    <GasAnalyserChart
      data={data}
      startDate={startDate}
      endDate={endDate}
      config={config}
      leftUnit={leftUnit}
      rightUnit={rightUnit}
    />
  );
};

export default ChartContainer;
