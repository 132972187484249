import { FeatureName } from '../types';
import useAuthorisedQuery from './useAuthorisedQuery';
import { Api } from '../settings';

const useMetadataQuery = (featureNames: FeatureName[]) =>
  useAuthorisedQuery(
    ['metadata', featureNames.join('')],
    `metadata/?features=${featureNames.join(',')}`,
    {},
    Api.serve
  );

export default useMetadataQuery;
