import { useMemo } from 'react';
import useNow from '../useNow';
import useTimezone from './useTimezone';
import getTimezoneOffset from './getTimezoneOffset';

const useTicks = (tickCount = 30) => {
  const now = useNow();
  const timezone = useTimezone();
  const offset = getTimezoneOffset(timezone, now);
  return useMemo(() => {
    let counterDate = new Date(now.valueOf());
    counterDate.setDate(counterDate.getDate() - (tickCount - 2));
    counterDate.setHours(0, 0, 0, 0);
    counterDate = new Date(counterDate.getTime() + offset);
    const ticks = [counterDate.getTime()];
    for (let i = 1; i < tickCount; i++) {
      counterDate.setDate(counterDate.getDate() + 1);
      ticks.push(counterDate.getTime());
    }
    return ticks;
  }, [offset, tickCount, now]);
};

export default useTicks;
