import type QualityChartData from '../types/QualityChartData';

export type ReferenceAreaData = {
  start: number;
  end: number;
};

const getActiveArea = (areas: ReferenceAreaData[]) => (areas.length > 0 ? areas[areas.length - 1] : undefined);

const useReferenceAreaData = (chartData: QualityChartData) => {
  const reversedChartData = chartData.reverse();
  const referenceAreas = reversedChartData
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .reduce((areas: ReferenceAreaData[], { predicted: number, predictedInBounds, date }, index) => {
      const outOfBounds = !predictedInBounds;
      const activeArea = getActiveArea(areas);
      if (outOfBounds) {
        // if an area has not been started, start one now
        if (activeArea === undefined || activeArea.end !== -1) {
          // start a new area
          areas.push({
            start: date,
            end: -1
          });
        }
        // if we have started an area, end it here
      } else if (activeArea?.end === -1) {
        activeArea.end = reversedChartData[index - 1].date;
      }
      return areas;
    }, []);
  if (referenceAreas.length > 0 && referenceAreas[referenceAreas.length - 1].end === -1) {
    referenceAreas[referenceAreas.length - 1].end = chartData[chartData.length - 1].date;
  }
  return referenceAreas;
};

export default useReferenceAreaData;
