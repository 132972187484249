import React from 'react';
import { TooltipProps } from 'recharts';
import { FormattedMessage } from 'react-intl';
import { ChartTooltip } from '../../../lib';
import { getLocalDate } from '../../../utils';

type Props = TooltipProps<string, string> & { featureLabel: string; unit?: string };

const BaselineLabel = () => <FormattedMessage defaultMessage="Baseline" id="template.dailykpi.tooltip.baseline" />;

const WeekStartLabel = () => <FormattedMessage defaultMessage="Week Start" id="template.dailykpi.tooltip.week-start" />;

type TooltipLabelProps = {
  children: string;
};
const TooltipLabel = ({ children }: TooltipLabelProps) => (
  <>
    <WeekStartLabel />: {getLocalDate(new Date(children), 'UTC')}
  </>
);

const TooltipContent = ({ label, payload = [], featureLabel, unit }: Props) => {
  const getLabel = (name?: string) => (name === 'baseline' ? <BaselineLabel /> : featureLabel);

  const payloadItems = payload.map(({ name, value, color }) => ({
    label: (
      <>
        {getLabel(name)}: {value} {unit}
      </>
    ),
    color
  }));

  const tooltipLabel = label ? <TooltipLabel>{label}</TooltipLabel> : <></>;
  return <ChartTooltip label={tooltipLabel} itemListData={payloadItems} />;
};

export default TooltipContent;
