import { getMinutes, getSeconds, set, subHours } from 'date-fns';
import bufferTime from '../bufferTime';

const REFRESH_MOMENTS = [0, 15, 30, 45];

const buildMoment = (now: Date, lastRefreshMoment: number) =>
  set(now, {
    minutes: lastRefreshMoment + bufferTime.minutes,
    seconds: bufferTime.seconds,
    milliseconds: 0
  });

const getRefreshMoment = (now: Date): Date => {
  const secondsInThisHour = getMinutes(now) * 60 + getSeconds(now);

  const lastRefreshMoment = [...REFRESH_MOMENTS].reverse().find((moment) => {
    const secondsInThisMoment = (moment + bufferTime.minutes) * 60 + bufferTime.seconds;
    return secondsInThisMoment <= secondsInThisHour;
  });
  return lastRefreshMoment === undefined
    ? buildMoment(subHours(now, 1), REFRESH_MOMENTS[REFRESH_MOMENTS.length - 1])
    : buildMoment(now, lastRefreshMoment);
};

export default getRefreshMoment;
