import { addHours, subHours } from 'date-fns';
import useNow from '../../../../useNow';
import { useHistoricalPlantData, useHistoricalSoftSensors, useUnit } from '../../../../utils';
import ChartTitle from '../ChartTitle';
import LoadingContent from '../LoadingContent';
import ChartContainer from './ChartContainer';
import { QualityChartConfig } from '../../schema';

type Props = {
  config: QualityChartConfig;
};
const QualitySection = ({ config }: Props) => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const endDate = addHours(now, 6);
  const { label, modelled, measured } = config;
  const { data: measuredData, isLoading: isLoadingMesauredData } = useHistoricalPlantData(
    [measured.name],
    startDate,
    endDate,
    15,
    true
  );
  const { data: modelledData, isLoading: isLoadingModelledData } = useHistoricalSoftSensors(
    [modelled.name],
    startDate,
    endDate,
    true
  );
  const isLoading = isLoadingMesauredData || isLoadingModelledData;
  const { data: unit } = useUnit(measured.name);
  return (
    <section>
      <ChartTitle>
        <>
          {label.default} ({unit})
        </>
      </ChartTitle>
      {isLoading && <LoadingContent />}
      {!isLoading && (
        <ChartContainer
          modelledData={modelledData}
          measuredData={measuredData}
          startDate={startDate}
          endDate={endDate}
          config={config}
          unit={unit}
        />
      )}
    </section>
  );
};

export default QualitySection;
