import React, { useRef } from 'react';

type Props = {
  className: string;
  children: React.ReactNode;
  onHover: (position: number, index: number) => void;
  index: number;
};

const TimelineItem = ({ className, children, onHover, index }: Props) => {
  const ref = useRef(null);
  const onMouseOver = () => {
    // @ts-ignore
    const position = ref.current.offsetLeft + ref.current.offsetWidth / 2;
    onHover(position, index);
  };

  return (
    <li className={className} onMouseOver={onMouseOver} onFocus={onMouseOver} ref={ref}>
      {children}
    </li>
  );
};
export default TimelineItem;
