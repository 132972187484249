import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { EzerTheme } from '../EzerThemeProvider';

type Props = {
  children: string | React.ReactNode;
  disabled: boolean;
  duration: number | null;
  onFilter: (months: number | null) => void;
  isActive?: boolean;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    minWidth: spacing(7),
    height: spacing(7),
    border: `1px solid ${palette.white}`,
    borderRadius: spacing(0.5),
    fontSize: spacing(2),
    color: palette.white,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: `${palette.blackShadow} 0 0 0 150px inset`
    }
  },
  active: {
    backgroundColor: palette.leaf,
    borderColor: palette.leaf,
    color: palette.white
  },
  disabled: {
    opacity: 0.5
  }
}));

const FilterButton = ({ children, disabled, duration, onFilter, isActive = false }: Props) => {
  const styles = useStyles();
  return (
    <button
      type="button"
      className={classNames(styles.root, { [styles.active]: isActive }, { [styles.disabled]: disabled })}
      onClick={() => onFilter(duration)}
      disabled={disabled}>
      {children}
    </button>
  );
};

export default FilterButton;
