import React from 'react';
import LocalisationProvider from './LocalisationProvider';

type Props = {
  children: React.ReactNode;
};

const LocalisationProviderSwitcher = ({ children }: Props) => <LocalisationProvider>{children}</LocalisationProvider>;

export default LocalisationProviderSwitcher;
