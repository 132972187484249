enum LegendKey {
  HighPercentageTime = 'highPercentageTime',
  LowPercentageTime = 'lowPercentageTime',
  OkPercentageTime = 'okPercentageTime',
  HighPeriodCount = 'highPeriodCount',
  LowPeriodCount = 'lowPeriodCount',
  AverageHighPeriodDuration = 'averageHighPeriodDuration',
  AverageLowPeriodDuration = 'averageLowPeriodDuration'
}

export default LegendKey;
