import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme, useEzerTheme } from '../../../../EzerThemeProvider';
import { getLocalDateAndTime, useTimezone } from '../../../../utils';
import TimelineData from './TimelineData';

type Props = {
  data: TimelineData;
  itemPosition: number;
  containerWidth: number;
  index: number;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  content: {
    position: 'absolute',
    top: -spacing(5.5),
    backgroundColor: palette.leaf,
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    paddingBottom: spacing(0.5)
  },
  redContent: {
    backgroundColor: palette.lava
  },
  amberContent: {
    backgroundColor: palette.flame
  },
  blackContent: {
    backgroundColor: palette.charcoal
  },
  title: {
    marginTop: spacing(0.5),
    textAlign: 'right',
    fontSize: 10
  },
  description: {
    marginTop: spacing(0),
    textTransform: 'uppercase',
    fontSize: 16,
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  arrow: {
    position: 'absolute',
    top: -spacing(1),
    display: 'inline-block',
    content: '""',
    borderLeft: `${spacing(1.75)}px solid transparent`,
    borderRight: `${spacing(1.75)}px solid transparent`,
    borderTop: `${spacing(1.75)}px solid ${palette.leaf}`
  },
  redArrow: {
    borderTopColor: palette.lava
  },
  amberArrow: {
    borderTopColor: palette.flame
  },
  blackArrow: {
    borderTopColor: palette.charcoal
  }
}));

const getContentPosition = (itemPosition: number, contentWidth: number, space: number, containerWidth: number) => {
  const contentPosition = Math.round(itemPosition - contentWidth / 2);
  if (contentPosition < -space) {
    return -space;
  }
  if (contentPosition + contentWidth > containerWidth + space) {
    return containerWidth - contentWidth + space;
  }
  return contentPosition;
};

const Tooltip = ({ data, itemPosition, containerWidth, index }: Props) => {
  const styles = useStyles();
  const { spacing } = useEzerTheme();
  const contentRef = useRef<HTMLDivElement>(null);

  const [contentWidth, setContentWidth] = useState(0);

  const contentPosition = getContentPosition(itemPosition, contentWidth, spacing(1), containerWidth);

  useEffect(() => {
    setContentWidth(contentRef.current?.getBoundingClientRect().width ?? 0);
  }, [contentRef, data]);

  const timezone = useTimezone();

  return (
    <>
      <div
        ref={contentRef}
        className={classNames(
          styles.content,
          { [styles.redContent]: data.colour === 'red' },
          { [styles.amberContent]: data.colour === 'amber' },
          { [styles.blackContent]: data.colour === 'black' }
        )}
        style={index === 0 ? { right: -spacing(1) } : { left: contentPosition }}>
        <h3 className={styles.title}>{getLocalDateAndTime(data.timestamp, timezone)}</h3>
        <p className={styles.description}>{data.explanation}</p>
      </div>
      <div
        className={classNames(
          styles.arrow,
          { [styles.redArrow]: data.colour === 'red' },
          { [styles.amberArrow]: data.colour === 'amber' },
          { [styles.blackArrow]: data.colour === 'black' }
        )}
        style={{ left: itemPosition - spacing(1.75) }}
      />
    </>
  );
};
export default Tooltip;
