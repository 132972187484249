import React from 'react';

import { Static } from '@sinclair/typebox';
import templates from '.';

/* UTILITY TYPES */

/**
 * Extract all the types from templates into an Union type
 */
type Templates = typeof templates;
export type Template = Templates[number];

/**
 * Maps the types from a template slug to the corresponding Template interface.
 */
export type SlugToTemplate = {
  [key in Template['templateSlug']]: Extract<Template, { templateSlug: key }>;
};

/**
 * Extract all template slugs into an Union type
 */
export type Slugs = keyof SlugToTemplate;

/**
 * Maps the types from a template slug to the corresponding Template properties.
 * We specifically mark {template: K} to allow the compiler to track the discriminated union.
 */
export type SlugToTemplateProperties = {
  [K in Slugs]: { template: K } & Static<SlugToTemplate[K]['templateSchema']>;
};

/**
 * Utility type to signal a particular React component for that particular templateSlug type.
 */
export type TemplateComponent<K extends Slugs> = (props: SlugToTemplateProperties[K]) => React.ReactElement;

/**
 * Maps the types from a template slug to the corresponding React component.
 */
export type TemplateComponents = {
  [K in Slugs]: TemplateComponent<K>;
};

/**
 * Maps from a template slug to the corresponding React component.
 */
export const Slug2Template = templates.reduce<TemplateComponents>(
  <K extends Slugs>(acc: TemplateComponents, template: SlugToTemplate[K]) => {
    acc[template.templateSlug as K] = template.TemplateComponent as TemplateComponents[K];
    return acc;
  },
  {} as TemplateComponents
);
