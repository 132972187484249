import { useMemo } from 'react';
import useDailyAverageQuery from '../serviceQueries/useDailyAverageQuery';
import { FeatureName } from '../types';

type DataItem = {
  date: number;
  value: number;
};

const useDailyAverageData = (featureNames: FeatureName[], startDate: Date, endDate: Date) => {
  const { data = [], isLoading } = useDailyAverageQuery(featureNames, startDate, endDate);
  const dailyAverageData = useMemo(
    () =>
      data.map((dataItem: DataItem) => ({
        date: new Date(dataItem.date).getTime(),
        value: dataItem.value
      })),
    [data]
  );
  return {
    data: dailyAverageData,
    isLoading
  };
};

export default useDailyAverageData;
