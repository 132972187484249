import { FormattedMessage } from 'react-intl';
import { Payload } from '../../types/QualityChartData';

type Props = {
  children: keyof Payload;
};
const QualityChartLabel = ({ children }: Props) => {
  if (children === 'date') {
    return <FormattedMessage defaultMessage="Date" id="chart.quality.date" />;
  }
  if (children === 'measured') {
    return <FormattedMessage defaultMessage="Measured" id="chart.quality.measured" />;
  }
  if (children === 'predicted') {
    return <FormattedMessage defaultMessage="Modelled" id="chart.quality.predicted" />;
  }
  return <></>;
};

export default QualityChartLabel;
