import type { UseQueryResult } from 'react-query/types/react/types';
import { Api } from '../settings';
import { FeatureName, MetricStatus } from '../types';
import { getUtcDate } from '../utils';
import useAuthorisedQuery from './useAuthorisedQuery';

const ONE_MINUTE = 60000;

export type LatestPlantDataItemRecord = {
  timestamp: string;
  type: string;
  value?: number;
  status?: MetricStatus;
  error?: string;
};

export type LatestPlantData = {
  item: Partial<Record<keyof typeof FeatureName, LatestPlantDataItemRecord>>;
};

const useLatestPlantDataQuery = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date
): UseQueryResult<LatestPlantData> => {
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  const features = featureNames.join(',');
  return useAuthorisedQuery(
    ['latest-plant-data', ...featureNames],
    `plant-data/latest/?features=${features}&start_datetime=${startDateValue}&end_datetime=${endDateValue}&limit=1`,
    {
      refetchInterval: ONE_MINUTE,
      enabled: featureNames.length > 0
    },
    Api.serve
  );
};

export default useLatestPlantDataQuery;
