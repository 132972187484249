import { AlarmConfig } from '../../schema';
import { useTemplateOptions } from '../../TemplateOptionsContext';

type AlarmGroup = AlarmConfig[];

type Result = {
  data: AlarmGroup[];
};

const useAlarmGroups = (): Result => {
  const { alarmConfigs, maxAlarmConfigsCount } = useTemplateOptions();

  const data = alarmConfigs.reduce((groups: AlarmGroup[], alarmConfig, index) => {
    const chunkIndex = Math.floor(index / maxAlarmConfigsCount);

    if (!groups[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      groups[chunkIndex] = [];
    }

    groups[chunkIndex].push(alarmConfig);

    return groups;
  }, []);

  return {
    data
  };
};

export default useAlarmGroups;
