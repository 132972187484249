import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';
import { usePath } from '../utils';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const useStyles = createUseStyles(({ spacing, mediaQueries }: EzerTheme) => ({
  link: {
    backgroundImage: 'url(/images/carbon-re.png)',
    backgroundSize: ['auto', '100%'],
    backgroundRepeat: 'no-repeat',
    height: spacing(3.5),
    width: spacing(17.5),
    display: 'block',
    [mediaQueries.desktop]: {
      height: spacing(4),
      width: spacing(20)
    }
  },
  text: {
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: 0,
    display: 'block'
  }
}));

const AppTitle = ({ children, className = '' }: Props) => {
  const { pathname } = useLocation();
  const styles = useStyles();
  const node = pathname === '/' ? 'h1' : 'p';
  const path = usePath('/');
  return React.createElement(
    node,
    { className },
    <NavLink to={path} className={styles.link}>
      <span className={styles.text}>{children}</span>
    </NavLink>
  );
};

export default AppTitle;
