import { Label } from '../types';
import { useLocalisedLabel } from '../utils';

type Props = {
  children: Label;
};

const LocalisedLabel = ({ children }: Props) => {
  const label = useLocalisedLabel(children);
  return <>{label}</>;
};

export default LocalisedLabel;
