import { useEffect } from 'react';

const ONE_MINUTE = 1000 * 60;

const useMinuteInterval = (callback: () => void) =>
  useEffect(() => {
    const intervalId = setInterval(callback, ONE_MINUTE);
    return () => clearInterval(intervalId);
  });

export default useMinuteInterval;
