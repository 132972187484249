import React from 'react';
import { createUseStyles } from 'react-jss';
import { ContentContainer } from '../lib';
import { EzerTheme } from '../../../EzerThemeProvider';

type Props = {
  children: string | React.ReactNode;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  para: {
    marginTop: spacing(2)
  }
}));

const MessageContent = ({ children }: Props) => {
  const styles = useStyles();
  return (
    <ContentContainer>
      <p className={styles.para}>{children}</p>
    </ContentContainer>
  );
};

export default MessageContent;
