import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import {
  MenuItem,
  Select as MuiSelect,
  FormControl,
  InputLabel,
  SelectClasses,
  SelectChangeEvent,
  SelectProps
} from '@mui/material';
import { EzerTheme } from '../EzerThemeProvider';

export type SelectData = { value: string; label: ReactNode }[];
type Props = {
  data: SelectData;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  stylesOverride?: Partial<SelectClasses>;
  onChange?: (event: SelectChangeEvent<string>) => void;
  variant?: SelectProps['variant'];
};

const useStyles = createUseStyles(({ palette }: EzerTheme) => ({
  label: {
    color: palette.white
  },
  select: {
    color: `${palette.white}!important`,
    width: 'auto',
    '&:hover': {
      boxShadow: `${palette.blackShadow} 0 0 0 150px inset`
    }
  },
  disabled: {
    opacity: 0.5
  },
  icon: {
    color: `${palette.white}!important`
  },
  '@global': {
    '.MuiMenuItem-root.Mui-selected': {
      backgroundColor: `${palette.leaf}!important`,
      color: palette.white
    },
    '.MuiMenuItem-root:hover': {
      backgroundColor: `${palette.darkRich}!important`,
      color: palette.white
    }
  }
}));

export const useSelectedParam = (): string | null => {
  const [search] = useSearchParams();
  return search.get('selected');
};

const Select = ({
  data,
  children,
  className,
  disabled = false,
  defaultValue,
  stylesOverride,
  onChange,
  variant
}: Props) => {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedParam = useSelectedParam();
  const selectedValue = defaultValue ?? data[0].value;
  return (
    <FormControl className={classNames({ [styles.disabled]: disabled }, className)}>
      {children && (
        <InputLabel id="select-label">
          <span className={styles.label}>{children}</span>
        </InputLabel>
      )}
      {data.length > 0 && (
        <MuiSelect
          variant={variant}
          disabled={disabled}
          labelId="select-label"
          classes={{
            select: `${stylesOverride?.select ?? ''} ${styles.select} `,
            icon: styles.icon
          }}
          sx={{
            '&:after': {
              opacity: 0
            }
          }}
          label={children}
          value={onChange ? selectedValue : selectedParam ?? selectedValue}
          onChange={
            // eslint-disable-next-line operator-linebreak
            onChange ??
            ((event) => {
              searchParams.set('selected', event.target.value);
              setSearchParams(searchParams);
            })
          }>
          {data.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
      )}
    </FormControl>
  );
};
export default Select;
