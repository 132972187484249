const calculateQuantile = (values: number[], q: number) => {
  if (values.length === 0) {
    return 0;
  }
  values.sort((a, b) => a - b);

  const pos = (values.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (values[base + 1] !== undefined) {
    return values[base] + rest * (values[base + 1] - values[base]);
  }
  return values[base];
};

export default calculateQuantile;
