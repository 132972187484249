import classNames from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Legend,
  Line,
  Area,
  ReferenceArea,
  ReferenceLine
} from 'recharts';
import getInterval from './getInterval';
import getBarSize from './getBarSize';
import { EzerTheme, useEzerTheme } from '../../EzerThemeProvider';
import { CartesianGrid, ChartBottomLabel, LocalisedLabel, LocalisedMessage } from '../../lib';
import TooltipContent from './TooltipContent';
import { getDateAsWeek, useToggles } from '../../utils';
import { KpiData } from './useKpiData';
import useFreeLimeTicks from './useFreeLimeTicks';
import * as msg from './messages';
import { ChartConfig } from './schema';

type Props = {
  data: KpiData;
  chartConfig: ChartConfig;
};

const useStyles = createUseStyles(({ spacing, fontWeight }: EzerTheme) => ({
  root: {
    position: 'relative'
  },
  container: {
    marginTop: spacing(2)
  },
  label: {
    fontSize: spacing(2.5),
    fontWeight: fontWeight.medium
  },
  positionedLabel: {
    textAlign: 'center',
    transform: 'rotate(180deg)',
    position: 'absolute',
    writingMode: 'vertical-lr',
    top: 0,
    bottom: spacing(2.5)
  },
  leftLabel: {
    left: 0
  },
  rightLabel: {
    right: 0
  }
}));

const TimeWithinBounds = () => <LocalisedMessage descriptor={msg.TimeInBounds} />;
const StandardDeviation = () => <LocalisedMessage descriptor={msg.StdDev} />;

const Chart = ({ data, chartConfig }: Props) => {
  const styles = useStyles();
  const toggles = useToggles();
  const { palette, spacing } = useEzerTheme();
  const { xAxisLabel, rangeStart, rangeEnd, numberOfTicks = 5 } = chartConfig;
  const domainStart = rangeStart ?? 'auto';
  const domainEnd = rangeEnd ?? 'auto';
  const freeLimeTicks = useFreeLimeTicks(data, toggles.includes('freeLime'), rangeEnd);
  const showLeftAxis = toggles.includes('timeWithinBounds');
  const showRightAxis = toggles.includes('sensorData') || toggles.includes('standardDeviation');

  return (
    <div className={styles.root}>
      <ResponsiveContainer width="100%" height={spacing(84)} className={styles.container}>
        <ComposedChart margin={{ top: 0, right: spacing(2), bottom: spacing(4), left: spacing(2) }} data={data.periods}>
          <Legend
            verticalAlign="top"
            height={36}
            payload={[
              {
                value: (
                  <>
                    <TimeWithinBounds /> (%)
                  </>
                ),
                type: 'square',
                color: palette.leaf
              },
              {
                value: (
                  <>
                    <LocalisedLabel>{chartConfig.yAxisLabel}</LocalisedLabel> (%)
                  </>
                ),
                type: 'line',
                color: palette.white
              },
              {
                value: (
                  <>
                    <StandardDeviation /> (%)
                  </>
                ),
                type: 'line',
                color: palette.flame
              }
            ]}
          />

          <CartesianGrid />
          {toggles.includes('timeWithinBounds') && (
            <Bar
              dataKey="percentWithinBounds"
              barSize={spacing(getBarSize(data))}
              fill={palette.leaf}
              yAxisId="percentWithinBoundsAxis"
            />
          )}
          {toggles.includes('sensorData') && (
            <Line
              dataKey="sensorData"
              stroke={palette.white}
              yAxisId="sensorDataAxis"
              strokeWidth={2}
              isAnimationActive={false}
            />
          )}
          {toggles.includes('standardDeviation') && (
            <Area
              type="monotone"
              dataKey="standardDeviation"
              strokeWidth={2}
              strokeDasharray="3 3"
              stroke={palette.flame}
              fill={palette.flame}
              yAxisId="sensorDataAxis"
              isAnimationActive={false}
              fillOpacity={0.4}
            />
          )}
          {toggles.includes('sensorData') && (
            <>
              <ReferenceLine
                y={data.upperBound}
                strokeDasharray="3 3"
                strokeWidth={2}
                stroke={palette.white}
                yAxisId="sensorDataAxis"
              />
              <ReferenceArea
                y1={data.upperBound}
                y2={data.lowerBound}
                fill={palette.fresh}
                yAxisId="sensorDataAxis"
                fillOpacity={0.3}
              />
              <ReferenceLine
                y={data.lowerBound}
                strokeDasharray="3 3"
                strokeWidth={2}
                stroke={palette.white}
                yAxisId="sensorDataAxis"
              />
            </>
          )}
          <XAxis
            dataKey="date"
            allowDataOverflow
            tickFormatter={(tick: number) => getDateAsWeek(new Date(tick))}
            angle={-45}
            textAnchor="end"
            interval={getInterval(data)}
          />
          {showLeftAxis && (
            <YAxis
              width={spacing(8)}
              domain={[0, 100]}
              ticks={[0, 25, 50, 75, 100]}
              tickFormatter={(tick: number) => `${tick}.00`}
              dataKey="percentWithinBounds"
              allowDataOverflow
              yAxisId="percentWithinBoundsAxis"
            />
          )}
          {showRightAxis && (
            <YAxis
              width={spacing(8)}
              domain={[domainStart, domainEnd]}
              ticks={freeLimeTicks}
              tickCount={numberOfTicks}
              allowDataOverflow
              orientation="right"
              yAxisId="sensorDataAxis"
            />
          )}
          <Tooltip content={<TooltipContent yAxisLabel={chartConfig.yAxisLabel} />} />
        </ComposedChart>
      </ResponsiveContainer>
      {showLeftAxis && (
        <div className={classNames(styles.label, styles.positionedLabel, styles.leftLabel)}>
          <TimeWithinBounds /> (%)
        </div>
      )}
      {showRightAxis && (
        <div className={classNames(styles.label, styles.positionedLabel, styles.rightLabel)}>
          <LocalisedLabel>{chartConfig.yAxisLabel}</LocalisedLabel> (%)
        </div>
      )}
      <ChartBottomLabel className={styles.label}>
        <LocalisedLabel>{xAxisLabel}</LocalisedLabel>
      </ChartBottomLabel>
    </div>
  );
};

export default Chart;
