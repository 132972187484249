import { theme } from '../EzerThemeProvider';

const globalStyles = {
  '@keyframes glowAnimation': {
    '0%': { boxShadow: '0 0 8px 8px rgba(255,255,255,0.0)' },
    '50%': { boxShadow: '0 0 8px 8px rgba(255,255,255,0.5)' },
    '100%': { boxShadow: '0 0 8px 8px rgba(255,255,255,0.0)' }
  },
  body: {
    margin: 0,
    fontFamily: ['Mada', 'sans-serif'],
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    color: theme.palette.white,
    backgroundColor: theme.palette.rich
  },
  code: {
    fontFamily: ['source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace']
  }
};

export default globalStyles;
