import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../EzerThemeProvider';

type Props = {
  title: string | React.ReactNode;
  children: number | string | React.ReactNode;
  unit?: string;
};

const useStyles = createUseStyles(({ palette, fontWeight, spacing }: EzerTheme) => ({
  root: {
    borderRadius: 4,
    background: palette.carbon,
    marginTop: spacing(2),
    padding: [0, spacing(2), spacing(2), spacing(2)],
    width: 'calc((100% - 32px) / 3)',
    boxSizing: 'border-box'
  },
  title: {
    marginTop: 16,
    color: palette.white,
    fontSize: 16,
    textTransform: 'uppercase'
  },
  para: {
    marginTop: spacing(1),
    fontSize: spacing(4),
    lineHeight: '1em',
    fontWeight: fontWeight.bold
  },
  value: {
    display: 'inline-block'
  },
  unit: {
    display: 'inline-block',
    fontSize: spacing(2),
    lineHeight: '1em',
    opacity: 0.8,
    paddingLeft: spacing(0.5)
  }
}));
const SummaryItem = ({ title, children, unit }: Props) => {
  const styles = useStyles();
  return (
    <li className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.para}>
        <span className={styles.value}>{children}</span>
        {unit && <span className={styles.unit}>{unit}</span>}
      </p>
    </li>
  );
};

export default SummaryItem;
