import { subHours } from 'date-fns';
import useLatestRecommendationsQuery from '../../../serviceQueries/useLatestRecommendationsQuery';
import { FeatureName } from '../../../types';
import type { InferenceRecommendation } from '../../../types/InferenceRecommendation';
import useNow from '../../../useNow';
import useRecommendationDataFeatureConfigs from '../../../utils/useRecommendationDataFeatureConfigs';

type Response = {
  data: InferenceRecommendation[];
  isLoading: boolean;
  isFetching: boolean;
};

// eslint-disable-next-line
const buildRecommendation = (datum: any): InferenceRecommendation => ({
  timestamp: new Date(datum.timestamp),
  feature: datum.feature as FeatureName,
  value: datum.value === null ? 0 : datum.value,
  error: datum.error ?? null
});

const useLatestInferenceRecommendations = (): Response => {
  const recommendationDataFeatureConfigs = useRecommendationDataFeatureConfigs();
  const now = useNow();
  const startDate = subHours(now, 6);
  const {
    data: recommendationsData = [],
    isLoading,
    isFetching
  } = useLatestRecommendationsQuery(startDate, now, { useInferenceApi: true });
  if (isLoading) {
    return {
      data: [],
      isLoading,
      isFetching
    };
  }

  const recommendationFeatureNames = recommendationDataFeatureConfigs.map(
    (featureConfig) => featureConfig.feature.name
  );

  const recommendations = recommendationsData
    // eslint-disable-next-line
    .map((datum: any) => buildRecommendation(datum))
    .filter(({ feature }: InferenceRecommendation) => recommendationFeatureNames.includes(feature))
    .reverse();

  return {
    data: recommendations,
    isLoading,
    isFetching
  };
};

export default useLatestInferenceRecommendations;
