import { subHours } from 'date-fns';
import useLatestDataStatusQuery from '../serviceQueries/useLatestDataStatusQuery';
import useNow from '../useNow';

type Status = 'OK' | 'DEGRADED' | 'ERROR';
export type Response = {
  data: {
    timestamp: Date;
    status: Status;
  } | null;
  isLoading: boolean;
};

const useCurrentDataStatus = (): Response => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const { data: dataStatus = [], isLoading } = useLatestDataStatusQuery(startDate, now);
  let data = null;
  if (dataStatus.length > 0) {
    data = {
      timestamp: new Date(dataStatus[0].timestamp),
      status: dataStatus[0].status as Status
    };
  }
  return { data, isLoading };
};

export default useCurrentDataStatus;
