import React from 'react';
import { ThemeProvider, useTheme } from 'react-jss';

type Props = {
  children: React.ReactNode;
};

export type EzerTheme = {
  palette: {
    black: string;
    white: string;
    whiteShadow: string;
    blackShadow: string;
    carbon: string;
    lightCarbon: string;
    charcoal: string;
    // primary (green) palette
    rich: string;
    darkRich: string;
    leaf: string;
    fresh: string;
    // fire (orange) palette
    lava: string;
    darkLava: string;
    flame: string;
    glow: string;
    // blue (for experimental features)
    blue: string;
    // glass (blue) palette
    power: string;
    electric: string;
    lightning: string;
  };
  fontWeight: {
    light: number;
    regular: number;
    medium: number;
    semiBold: number;
    bold: number;
    black: number;
  };
  spacing: (factor: number) => number;
  mediaQueries: {
    tablet: string;
    desktop: string;
    bigDesktop: string;
  };
  maxContentWidth: number;
};

export const theme: EzerTheme = {
  palette: {
    black: '#000',
    white: '#fff',
    whiteShadow: 'rgba(255, 255, 255, 0.1)',
    blackShadow: 'rgba(13, 41, 27, 0.1)',
    carbon: '#141414',
    lightCarbon: 'rgba(20,20,20,0.2)',
    charcoal: '#4a4f54',
    // primary (green) palette
    rich: '#2b5740',
    darkRich: '#0d3922',
    leaf: '#8aad66',
    fresh: '#cfe5b2',
    // fire (orange) palette
    lava: '#F52600',
    darkLava: '#c91b00',
    flame: '#F58302',
    glow: '#F5E730',
    // blue
    blue: '#0000FF',
    // glass
    power: '#0a255c',
    electric: '#00b9e9',
    lightning: '#86faf2'
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900
  },
  spacing: (factor) => factor * 8,
  mediaQueries: {
    tablet: '@media (min-width: 768px)',
    desktop: '@media (min-width: 1024px)',
    bigDesktop: '@media (min-width: 1440px)'
  },
  maxContentWidth: 1440 - 48
};

export const useEzerTheme = (): EzerTheme => useTheme();

const EzerThemeProvider = ({ children }: Props) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default EzerThemeProvider;
