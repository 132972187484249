import { useAuth0 } from '@auth0/auth0-react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { API_SERVE_BASE_URL, Api } from '../settings';
import useAuthorisedRequest from './useAuthorisedRequest';

type Variables = {
  timestamp: Date;
  feedback: string;
};

const formatToUTCOffset = (date: Date) => {
  const zonedDate = utcToZonedTime(date, 'UTC');
  const day = format(zonedDate, 'yyyy-MM-dd');
  const time = format(zonedDate, 'HH:mm:ss');
  return `${day}T${time}+00:00`;
};
const useAddServeFeedbackMutation = () => {
  const authorisedRequest = useAuthorisedRequest(Api.serve);
  const [searchParams] = useSearchParams();
  const { user } = useAuth0();

  const createFeedback = async ({ timestamp, feedback }: Variables) => {
    const url = new URL(`${API_SERVE_BASE_URL}/feedback/`);
    const customer = searchParams.get('customer');

    return authorisedRequest(url.toString(), {
      method: 'POST',
      body: JSON.stringify({
        plant_ref: customer,
        timestamp: formatToUTCOffset(timestamp),
        content: feedback,
        user: user?.email
      })
    });
  };

  return useMutation({
    mutationFn: createFeedback
  });
};

export default useAddServeFeedbackMutation;
