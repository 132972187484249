import { FormattedMessage } from 'react-intl';
import IconLink from './IconLink';

const UpdatesLinkStatic = () => (
  <IconLink href="https://deltazero.help.carbonre.com/updates/">
    <FormattedMessage defaultMessage="Updates" id="menu.updates" />
  </IconLink>
);

export default UpdatesLinkStatic;
