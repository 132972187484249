import useConfig from '../Config/useConfig';
import { FeatureName } from '../types';

const useNewKpiFeatureConfig = (featureName?: FeatureName) => {
  const { data: config } = useConfig();

  return featureName !== undefined
    ? config?.app.kpiFeatureConfigs.find((kpiFeatureConfig) => kpiFeatureConfig.name === featureName)
    : undefined;
};

export default useNewKpiFeatureConfig;
