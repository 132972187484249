import { defineMessage } from 'react-intl';

export const TableTimestamp = defineMessage({ id: 'labels.timestamp', defaultMessage: 'Time' });
export const TableFeedback = defineMessage({ id: 'labels.feedback', defaultMessage: 'Feedback' });

// I don't think these should exist. There are different usages of the word "Historical"
// it would be better to define those FormattedMessages inline with the component, and use placeholders
// properly
export const Historical = defineMessage({ id: 'labels.historical', defaultMessage: 'Historical' });
export const Recommended = defineMessage({ id: 'labels.recommended', defaultMessage: 'Recommended' });
export const Date = defineMessage({ id: 'labels.date', defaultMessage: 'Date' });
export const DateTime = defineMessage({ id: 'labels.dateTime', defaultMessage: 'Date/Time' });
export const New = defineMessage({ id: 'labels.new', defaultMessage: 'New' });
export const Week = defineMessage({ id: 'labels.week', defaultMessage: 'Week' });
