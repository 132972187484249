import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { formatDistance } from 'date-fns';
import { useActiveInferenceRecommendation } from '../../utils';
import useNow from '../../../../useNow';

const useStyles = createUseStyles({
  root: {
    display: 'inline-block'
  }
});

const RecommendationAge = () => {
  const { data } = useActiveInferenceRecommendation();
  const now = useNow();
  const styles = useStyles();
  const age = data?.timestamp ? formatDistance(new Date(data.timestamp), now) : '';
  return (
    <p className={styles.root}>
      <FormattedMessage id="page.today.recommendationAge" defaultMessage="{age} ago" values={{ age }} />
    </p>
  );
};
export default RecommendationAge;
