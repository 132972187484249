import { FeatureName } from '../types';
import useAuthorisedQuery from './useAuthorisedQuery';
import getInternationalDate from '../utils/getInternationalDate';

const useDailyAverageQuery = (featureNames: FeatureName[], startDate: Date, endDate: Date) => {
  const startDateValue = getInternationalDate(startDate);
  const endDateValue = getInternationalDate(endDate);

  return useAuthorisedQuery(
    ['daily-average', startDateValue, endDateValue, ...featureNames],
    `daily-average/?feature=${featureNames.join(',')}&start_date=${startDateValue}&end_date=${endDateValue}`
  );
};

export default useDailyAverageQuery;
