export interface Customer {
  plant_ref: string;
  name: string;
}

export interface DailyAverageData {
  date: string;
  value: number;
}

export interface RecommendationFeedback {
  id: number;
  text: string;
}

export interface RecommendationParam {
  name: string;
  value: number;
  unit: string;
  error?: string;
}

export interface RecommendationPrediction {
  name: string;
  value: number;
  unit: string;
  error?: 'INFERENCE_ERROR';
}

export interface Feedback {
  created_at: string;
  text: string;
}

export interface Recommendation {
  id: number;
  forDatetime: string;
  params: RecommendationParam[];
  predictions: RecommendationPrediction[];
  status: string;
  status_updated_at?: string;
  rating: number;
  rating_updated_at?: string;
  error?: 'PREDS_OUT_OF_BOUND' | 'INFERENCE_ERROR' | 'NANS_IN_INPUT';
  feedback?: Feedback[];
}

export type PlantStatusName =
  | 'OK'
  | 'SHUTDOWN'
  | 'UNKNOWN'
  | 'WARMING_UP'
  | 'COOLING_DOWN'
  | 'GAPS_IN_FEED'
  | 'REDUCED_THROUGHPUT'
  | 'LIGHT_UP';

export const StablePlantStatus: Array<PlantStatusName> = ['OK', 'REDUCED_THROUGHPUT'];

export interface PlantStatus {
  id: number;
  timestamp: string;
  status: PlantStatusName;
}

export enum FeatureName {
  // mock features
  f_pc_burner1_tput_setpoint = 'f_pc_burner1_tput_setpoint',
  s_c_sol_fcao_pred = 's_c_sol_fcao_pred',
  // clinker xrf Page
  s_c_sol_xrf_lsf = 's_c_sol_xrf_lsf',
  s_c_sol_xrf_sm = 's_c_sol_xrf_sm',
  s_c_sol_xrf_am = 's_c_sol_xrf_am',
  s_c_sol_xrf_fcao = 's_c_sol_xrf_fcao',
  // clinker xrd Page
  s_c_sol_xrd_sm = 's_c_sol_xrd_sm',
  s_c_sol_xrd_lsf = 's_c_sol_xrd_lsf',
  s_c_sol_xrd_c3a = 's_c_sol_xrd_c3a',
  s_c_sol_xrd_fcao = 's_c_sol_xrd_fcao',
  // Fuels page - SCG
  f_k_mass = 'f_k_mass',
  f_pc_burner_coal_mass = 'f_pc_burner_coal_mass',
  f_k_coal_hhv = 'f_k_coal_hhv',
  f_k_coal_moisture = 'f_k_coal_moisture',
  f_k_coal_fineness = 'f_k_coal_fineness',
  // Fuels page - Votorantim
  f_k_tput = 'f_k_tput',
  f_pc_burner1_tput = 'f_pc_burner1_tput',
  f_pc_burner2_tput = 'f_pc_burner2_tput',
  f_pc_burner1_tput_err_calc = 'f_pc_burner1_tput_err_calc',
  // raw meal page
  s_ph_sil_lsf = 's_ph_sil_lsf',
  s_ph_sil_sm = 's_ph_sil_sm',
  s_ph_sil_am = 's_ph_sil_am',
  // gas analyser
  g_k_gol_o2 = 'g_k_gol_o2',
  g_k_gol_o2_rec = 'g_k_gol_o2_rec',
  g_k_gol_o2_es_target = 'g_k_gol_o2_es_target',
  g_k_gol_co = 'g_k_gol_co',
  g_pc_gol_o2 = 'g_pc_gol_o2',
  g_pc_gol_co = 'g_pc_gol_co',
  // ammonia
  p_pc_ammonia_flowrate = 'p_pc_ammonia_flowrate',
  g_phstack_gol_nox = 'g_phstack_gol_nox',
  // today page - new for SCG Song Gianh
  p_k_speed = 'p_k_speed',
  p_ph1_gol_fan_speed_pct = 'p_ph1_gol_fan_speed_pct',
  p_shc = 'p_shc',
  p_shc_soft = 'p_shc_soft',
  s_c_sol_xrd_c3s = 's_c_sol_xrd_c3s',
  s_ph_sil_tput = 's_ph_sil_tput',
  s_c_sol_sa_ratio = 's_c_sol_sa_ratio',
  g_ph_gol_o2 = 'g_ph_gol_o2',
  g_ph_gol_o2_rec = 'g_ph_gol_o2_rec',
  g_k_gol_o2_soft = 'g_k_gol_o2_soft',
  // today page - new for Votorantim Niebla
  p_ph_gol_fan_speed = 'p_ph_gol_fan_speed',
  p_k_torque = 'p_k_torque',
  s_c_sol_fcao = 's_c_sol_fcao',
  s_c_sol_fcao_soft = 's_c_sol_fcao_soft',
  g_pc_gol_temp = 'g_pc_gol_temp',
  // Recommendations & predictions - Votorantim Niebla
  p_k_torque_target = 'p_k_torque_target',
  g_pc_gol_temp_target = 'g_pc_gol_temp_target',
  g_ph_cy5_gol_temp = 'g_ph_cy5_gol_temp',
  p_ph_cy5_pid_gol_temp_setpoint = 'p_ph_cy5_pid_gol_temp_setpoint',
  g_pc_gol_o2_target = 'g_pc_gol_o2_target',
  // new for Voto CU
  f_k_tput_augmented = 'f_k_tput_augmented',
  f_k_tput_augmented_soft = 'f_k_tput_augmented_soft',
  f_k_tput_calc = 'f_k_tput_calc',
  f_k_tput_err_calc = 'f_k_tput_err_calc',
  f_pc_tput = 'f_pc_tput',
  f_pc_af_tput = 'f_pc_af_tput',
  p_pc_gol_temp = 'p_pc_gol_temp',
  p_ph_sil_tput = 'p_ph_sil_tput',
  g_ph_gol_co = 'g_ph_gol_co',
  p_ph_urea_flowrate = 'p_ph_urea_flowrate',
  p_phstack_gol_nox = 'p_phstack_gol_nox',
  s_ph_sol_doc = 's_ph_sol_doc',
  s_c_sol_xrf_fcao_soft = 's_c_sol_xrf_fcao_soft',
  s_ph_sol_doc_soft = 's_ph_sol_doc_soft',
  p_shc_target = 'p_shc_target',
  // Voto Cui Heat balance
  p_shc2 = 'p_shc2',
  p_shc_calc = 'p_shc_calc',
  p_shc_clinker_heat = 'p_shc_clinker_heat',
  p_shc_conv_rad = 'p_shc_conv_rad',
  p_shc_cooler_air = 'p_shc_cooler_air',
  p_shc_main_stack = 'p_shc_main_stack',
  p_shc_moisture = 'p_shc_moisture',
  p_shc_reaction = 'p_shc_reaction',
  // Costs page
  f_sfc_calc = 'f_sfc_calc',
  // everything else
  s_pc_sol_doc = 's_pc_sol_doc',
  s_pc_sol_doc_soft = 's_pc_sol_doc_soft',
  p_k_flame_temp_target = 'p_k_flame_temp_target',
  p_k_eo_torque_target = 'p_k_eo_torque_target',
  g_k_gol_nox_target = 'g_k_gol_nox_target',
  s_c_sol_3cs = 's_c_sol_3cs',
  p_k_pfister_speed = 'p_k_pfister_speed',
  g_k_gol_combustion_state_soft = 'g_k_gol_combustion_state_soft',
  g_ph_str1_gol_combustion_state_soft = 'g_ph_str1_gol_combustion_state_soft',
  p_ph_str1_gol_fan_speed = 'p_ph_str1_gol_fan_speed',
  p_ph_str2_gol_fan_speed = 'p_ph_str2_gol_fan_speed',
  s_c_sol_c3s_soft = 's_c_sol_c3s_soft',
  s_c_sol_c3s_d = 's_c_sol_c3s_d',
  g_ph_gol_o2_es_target = 'g_ph_gol_o2_es_target',
  g_ph_cy2_gol_temp = 'g_ph_cy2_gol_temp',
  g_ph_cy4_gol_temp1 = 'g_ph_cy4_gol_temp1',
  g_pc_gol_pressure = 'g_pc_gol_pressure',
  g_ph_gol_fan_power = 'g_ph_gol_fan_power',
  g_phstack_gol_oc = 'g_phstack_gol_oc',
  p_k_eo_fcao_control_status = 'p_k_eo_fcao_control_status',
  p_k_eo_o2_control_status = 'p_k_eo_o2_control_status',
  p_k_eo_c3s_control_status = 'p_k_eo_c3s_control_status',
  g_o2_control_status = 'g_o2_control_status',
  g_k_gol_pressure = 'g_k_gol_pressure',
  g_ph_str1_cy4_gol_temp = 'g_ph_str1_cy4_gol_temp',
  g_ph_str2_cy4_gol_temp = 'g_ph_str2_cy4_gol_temp',
  g_pc_gil_3air_temp = 'g_pc_gil_3air_temp',
  g_ph_str1_gol_temp = 'g_ph_str1_gol_temp',
  p_ph_str2_gol_fan_power = 'p_ph_str2_gol_fan_power',
  g_ph_str1_gol_o2 = 'g_ph_str1_gol_o2',
  g_ph_str1_gol_co = 'g_ph_str1_gol_co',
  g_ph_str2_gol_co = 'g_ph_str2_gol_co',
  g_ph_str2_gol_o2 = 'g_ph_str2_gol_o2',
  g_ph_str2_gol_combustion_state_soft = 'g_ph_str2_gol_combustion_state_soft',
  p_ph_str1_gol_fan_power = 'p_ph_str1_gol_fan_power',
  g_k_gol_nox = 'g_k_gol_nox',
  g_ph_gol_combustion_state_soft = 'g_ph_gol_combustion_state_soft'
}

export enum MetricStatus {
  Ok = 'ok',
  AboveBounds = 'aboveBounds',
  BelowBounds = 'belowBounds'
}

export type CustomerResponse = {
  plant_ref: string;
  name: string;
};

export type Label = {
  default: string;
  id?: string;
};
