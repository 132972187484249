/* eslint-disable react/button-has-type */
import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { EzerTheme, useEzerTheme } from '../../../../EzerThemeProvider';
import { ProgressIndicator } from '../../../../lib';

type Props = {
  children: string | React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
};

const useStyles = createUseStyles(({ palette, spacing, fontWeight }: EzerTheme) => ({
  root: {
    padding: spacing(0.5),
    minWidth: spacing(10),
    cursor: 'pointer',
    backgroundColor: palette.leaf,
    color: palette.white,
    borderRadius: spacing(0.5),
    justifyContent: 'center',
    fontSize: spacing(1.5),
    fontWeight: fontWeight.semiBold,
    lineHeight: `${spacing(2.5)}px`,
    '&:hover': {
      backgroundColor: palette.leaf,
      boxShadow: `${palette.blackShadow} 0 0 0 150px inset`
    }
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
    '&:hover': {
      backgroundColor: palette.leaf,
      boxShadow: 'none'
    }
  }
}));

const Button = ({ children, type = 'button', disabled = false, isLoading = false }: Props) => {
  const styles = useStyles();
  const theme = useEzerTheme();

  return (
    <button type={type} className={classNames({ [styles.disabled]: disabled }, styles.root)} disabled={disabled}>
      {!isLoading && children}
      <ProgressIndicator isLoading={isLoading} size={theme.spacing(1.5)} />
    </button>
  );
};

export default Button;
