import { CartesianGrid as RechartsCartesianGrid } from 'recharts';
import React from 'react';
import { useEzerTheme } from '../EzerThemeProvider';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CartesianGrid = (props: any) => {
  const { palette } = useEzerTheme();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RechartsCartesianGrid {...props} fill={palette.carbon} stroke={palette.rich} />;
};
CartesianGrid.displayName = RechartsCartesianGrid.displayName;

export default CartesianGrid;
