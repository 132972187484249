import { isEqual } from 'date-fns';
import { SoftSensor } from '../../../../utils/useHistoricalSoftSensors';
import { PlantData } from '../../../../utils/useHistoricalPlantData';
import { GasAnalyserChartConfig } from '../../schema';

export type GasAnalyserDataItem = {
  date: number;
  leftValue?: number;
  rightValue?: number;
  low: boolean;
  high: boolean;
};

export type GasAnalyserData = GasAnalyserDataItem[];

const useGasAnalyserData = (
  startDate: Date,
  endDate: Date,
  plantData: PlantData,
  performanceData: SoftSensor[],
  config: GasAnalyserChartConfig
): GasAnalyserData => {
  const response: GasAnalyserData = [];

  plantData.items.forEach((item) => {
    const date = new Date(item.timestamp);
    const performanceItem = performanceData.find((softSensorItem) => isEqual(softSensorItem.timestamp, date));

    response.push({
      date: date.getTime(),
      leftValue: item.data[config.leftFeatureConfig.feature.name]?.value ?? undefined,
      rightValue: item.data[config.rightFeatureConfig.feature.name]?.value ?? undefined,
      low: performanceItem?.value === undefined ? false : performanceItem.value <= -1,
      high: performanceItem?.value === undefined ? false : performanceItem.value >= 1
    });
  });

  return response;
};

export default useGasAnalyserData;
