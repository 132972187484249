import { createSearchParams, generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import Menu from './Menu/Menu';
import useCustomers from './useCustomers';
import MenuButton from './Menu/MenuButton';

const CustomerMenu = () => {
  const { data: customers = [] } = useCustomers();
  const [searchParams] = useSearchParams();
  if (searchParams.get('locale')) {
    searchParams.delete('locale');
  }
  const customerParam = searchParams.get('customer');
  const customerName = customerParam
    ? customers.find((customer) => customer.id === customerParam)?.name
    : customers[0].name;
  const navigate = useNavigate();
  const onSelectCustomer = (id: string) => {
    searchParams.set('customer', id);
    const path = generatePath(':url?:queryString', {
      url: '/',
      queryString: createSearchParams(searchParams).toString()
    });
    navigate(path);
  };

  const items = customers.map((customer) => (
    <MenuButton onClick={() => onSelectCustomer(customer.id)}>{customer.name}</MenuButton>
  ));

  return <Menu items={items}>{customerName}</Menu>;
};

export default CustomerMenu;
