import getIsoWeek from '../utils/getIsoWeek';
import useAuthorisedQuery from './useAuthorisedQuery';
import { FeatureName } from '../types';
import { Api } from '../settings';

const useKpiByWeekQuery = (featureName: FeatureName, startDate: Date, endDate: Date) => {
  const startPeriod = getIsoWeek(startDate);
  const endPeriod = getIsoWeek(endDate);
  return useAuthorisedQuery(
    ['kpi-query-by-week', startPeriod, endPeriod, featureName, 'api-serve'],
    `kpi/?feature=${featureName}&period_type=week&start_period=${startPeriod}&end_period=${endPeriod}`,
    {},
    Api.serve
  );
};

export default useKpiByWeekQuery;
