import { FormattedMessage } from 'react-intl';
import { LoadingContent, SectionContent, SectionTitle, Timeline } from '../../lib';
import useDataStatus from './useDataStatus';
import useTimelineData from './useTimelineData';

const DataIngestionTimeline = () => {
  const { isLoading, data } = useDataStatus();
  const timelineData = useTimelineData(data);
  return (
    <>
      <SectionTitle>
        <FormattedMessage defaultMessage="Data Ingestion" id="page.today.ingest.header" />
      </SectionTitle>
      <SectionContent>
        {isLoading && <LoadingContent />}
        {!isLoading && <Timeline data={timelineData} dataTestId="data-ingestion-timeline" />}
      </SectionContent>
    </>
  );
};
export default DataIngestionTimeline;
