import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { EzerTheme } from '../../../../EzerThemeProvider';
import { usePath } from '../../../../utils';

const useStyles = createUseStyles(({ spacing, palette, fontWeight }: EzerTheme) => ({
  root: {
    marginTop: spacing(2),
    textAlign: 'center'
  },
  link: {
    padding: spacing(0.5),
    minWidth: spacing(13),
    display: 'inline-block',
    backgroundColor: palette.leaf,
    color: palette.white,
    borderRadius: spacing(0.5),
    justifyContent: 'center',
    fontSize: spacing(2),
    fontWeight: fontWeight.semiBold,
    lineHeight: `${spacing(3.5)}px`,
    '&:hover': {
      backgroundColor: palette.leaf,
      boxShadow: `${palette.blackShadow} 0 0 0 150px inset`
    }
  }
}));
const ReviewPanel = () => {
  const styles = useStyles();
  const to = usePath('/historical-recommendations');
  return (
    <p className={styles.root}>
      <NavLink data-testid="review-button" to={to} className={styles.link}>
        <FormattedMessage defaultMessage="Review" id="page.today.recommendation.link.review" />
      </NavLink>
    </p>
  );
};

export default ReviewPanel;
