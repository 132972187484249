{
  "p_shc2": {
    "currentValue": 123.45,
    "averageValue": 123.45,
    "bestTenPercentValue": 123.45
  },
  "p_shc_cooler_air": {
    "currentValue": 123.45,
    "averageValue": 123.45,
    "bestTenPercentValue": 123.45
  },
  "p_shc_clinker_heat": {
    "currentValue": 23,
    "averageValue": 23,
    "bestTenPercentValue": 23
  },
  "p_shc_reaction": {
    "currentValue": 456,
    "averageValue": 456,
    "bestTenPercentValue": 456
  },
  "p_shc_conv_rad": {
    "currentValue": 78,
    "averageValue": 78,
    "bestTenPercentValue": 78
  },
  "p_shc_moisture": {
    "currentValue": 12,
    "averageValue": 12,
    "bestTenPercentValue": 12
  },
  "p_shc_main_stack": {
    "currentValue": 234,
    "averageValue": 234,
    "bestTenPercentValue": 234
  }
}
