import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  children: ReactNode;
  onError: () => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    hasError: false
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // This render() displays the desired error text to the user, but then
  // the entire website is broken afterwards. Merging anyway as still an
  // improvement.
  public render() {
    const { hasError } = this.state;
    const { children, onError } = this.props;
    if (hasError) {
      onError();
      return (
        <div>
          <h1 style={{ fontSize: 32 }}>
            <FormattedMessage id="labels.somethingWentWrong" defaultMessage="Something went wrong." />
          </h1>
          <p style={{ fontSize: 16 }}>
            <FormattedMessage
              id="labels.contentProblem"
              defaultMessage="Sorry, there seems to be a problem with this content."
            />
          </p>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
