/* eslint-disable max-len */

import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useActiveInferenceRecommendation } from '../../utils';
import { Error } from '../../utils/useLatestRecommendations';
import { EzerTheme } from '../../../../EzerThemeProvider';

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(10),
    marginRight: spacing(6),
    marginLeft: spacing(6),
    fontSize: spacing(2.5),
    minHeight: spacing(21.75)
  }
}));

type MessageProps = {
  error: Error | undefined;
};

const Message = ({ error }: MessageProps) => {
  switch (error) {
    case 'INFERENCE_ERROR':
    case 'BAD_DATA':
      return (
        <FormattedMessage
          defaultMessage="Internal error, recommendations can't be generated at this time. Please contact support@carbonre.com if the problem persists."
          id="page.today.internalError"
        />
      );
    case 'NANS_IN_INPUT':
    case 'NOT_ENOUGH_DATA':
      return (
        <FormattedMessage
          defaultMessage="Recommendations can't be generated at this time, not enough data available."
          id="page.today.notEnoughData"
        />
      );
    case 'PREDS_OUT_OF_BOUND':
      return (
        <FormattedMessage
          defaultMessage="Unable to provide recommendation within target bounds."
          id="page.today.predsOutOfBounds"
        />
      );
    case 'EO_OFF':
      return <FormattedMessage defaultMessage="Expert optimiser is offline." id="page.today.eoOffline" />;
    case undefined:
    default:
      return <></>;
  }
};

const ErrorMessage = () => {
  const styles = useStyles();
  const { data } = useActiveInferenceRecommendation();
  const error = data?.error ? data.error : undefined;
  return (
    <p className={styles.root} data-testid="error-message">
      <Message error={error} />
    </p>
  );
};

export default ErrorMessage;
