import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGA4React } from 'ga-4-react';

type Props = {
  children: React.ReactNode;
};

const AnalyticsPageTracker = ({ children }: Props) => {
  const ga = useGA4React();
  const location = useLocation();

  useEffect(() => {
    if (ga) {
      ga.pageview(location.pathname + location.search);
    }
  }, [location, ga]);

  return <>{children}</>;
};

export default AnalyticsPageTracker;
