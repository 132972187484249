import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { FormEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';
import useConfigMutation from '../serviceQueries/useConfigMutation';
import useConfigQuery from '../serviceQueries/useConfigQuery';
import { configJsonSchema, ConfigType, uiSchema } from './ConfigSchema';
import { displayVersion } from './utils';

const useStyles = createUseStyles(({ palette }: EzerTheme) => ({
  root: {
    background: palette.white,
    color: palette.carbon
  }
}));

const Config = () => {
  const styles = useStyles();
  const { data: configWrapper, isLoading } = useConfigQuery();
  const saveConfig = useConfigMutation();
  if (isLoading) return <div className={styles.root}>Loading...</div>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: IChangeEvent<ConfigType, RJSFSchema, any>, event: FormEvent) => {
    event.preventDefault();

    if (!configWrapper) {
      // eslint-disable-next-line no-alert
      window.alert('No config available');
      return;
    }

    // AFAIU type here is wrong and formData shall be present when onSubmit
    if (data.formData === undefined) throw new Error('formData was undefined in Config.tsx');

    saveConfig.mutate(
      { config: data.formData, version: configWrapper.version },
      {
        onSuccess: () => {
          // eslint-disable-next-line no-alert
          window.alert('Config saved - reloading the browser now');
          window.location.reload();
        },
        onError: (error: unknown) => {
          // eslint-disable-next-line no-alert
          window.alert(error);
        }
      }
    );
  };
  if (!configJsonSchema.description?.includes('Version')) {
    configJsonSchema.description += `. ${displayVersion(configWrapper?.version)}`;
  }
  return (
    <div className={styles.root}>
      <Form
        formData={configWrapper?.config}
        schema={configJsonSchema}
        validator={validator}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default Config;
