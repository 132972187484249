import useCustomersQuery from '../serviceQueries/useCustomersQuery';

type Customer = {
  name: string;
  id: string;
};

type Response = {
  data: Customer[];
  isLoading: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeCustomer = (datum: any): Customer => ({
  name: datum.name,
  id: datum.plant_ref
});

const useCustomers = (): Response => {
  const { isLoading, data: queryData = [] } = useCustomersQuery();

  return {
    data: isLoading ? [] : queryData.map(makeCustomer),
    isLoading
  };
};

export default useCustomers;
