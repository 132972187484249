import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

export type FixedNow = Date | null;

export const FixedNowContext = createContext<FixedNow>(null);

const isValidDate = (date: unknown) => date instanceof Date && !Number.isNaN(date.getTime());

const FixedNowProvider = ({ children }: Props) => {
  const [fixedNow, setFixedNow] = useState<FixedNow>(null);
  const { search } = useLocation();
  const nowFromUrl = useMemo(() => {
    const query = new URLSearchParams(search);
    const value = query?.get('now') || '';
    return new Date(value);
  }, [search]);
  useEffect(() => {
    if (isValidDate(nowFromUrl) && (fixedNow === null || fixedNow.getTime() !== nowFromUrl.getTime())) {
      setFixedNow(nowFromUrl);
    }
  }, [fixedNow, nowFromUrl, setFixedNow]);
  return <FixedNowContext.Provider value={fixedNow}>{children}</FixedNowContext.Provider>;
};

export default FixedNowProvider;
