import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../EzerThemeProvider';

type Props = {
  href: string;
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    display: 'block',
    lineHeight: `${spacing(3)}px`,
    backgroundColor: palette.white,
    padding: [spacing(1), spacing(2)],
    color: palette.carbon,
    '&:hover': {
      boxShadow: `${palette.blackShadow} 0 0 0 150px inset`
    }
  }
}));
const MenuLink = ({ href, children }: Props) => {
  const styles = useStyles();
  return (
    <a href={href} className={styles.root} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default MenuLink;
