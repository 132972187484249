import React from 'react';
import { createUseStyles } from 'react-jss';
import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';
import { EzerTheme } from '../../EzerThemeProvider';
import MenuButton from '../Menu/MenuButton';

const useStyles = createUseStyles(({ palette }: EzerTheme) => ({
  root: {
    borderTop: `1px solid ${palette.carbon}`
  }
}));

const LogoutButton = () => {
  const { logout } = useAuth0();
  const styles = useStyles();
  const onClick = () => logout({ returnTo: window.location.origin });
  return (
    <MenuButton onClick={onClick} className={styles.root}>
      <FormattedMessage id="buttons.logOut" defaultMessage="Log out" />
    </MenuButton>
  );
};

export default LogoutButton;
