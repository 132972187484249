import { subHours } from 'date-fns';
import { FeatureName } from '../../../../../../types';
import useNow from '../../../../../../useNow';
import useLatestPlantDataQuery from '../../../../../../serviceQueries/useLatestPlantDataQuery';
import { useUnit } from '../../../../../../utils';

export type AlarmData = {
  unit: string;
  value: number;
};

type Response = {
  data: AlarmData;
  isLoading: boolean;
};
const useAlarmData = (featureName: FeatureName): Response => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const { data: plantData, isLoading: isLoadingPlantData } = useLatestPlantDataQuery([featureName], startDate, now);
  const { data: unit, isLoading: isLoadingUnit } = useUnit(featureName);
  const isLoading = isLoadingPlantData || isLoadingUnit;

  return {
    data: {
      unit,
      value: plantData?.item[featureName]?.value ?? 0
    },
    isLoading
  };
};

export default useAlarmData;
