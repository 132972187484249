import { Messages } from '../LocalisationProvider';

const getLocalisedMessage = (messages: Messages, defaultMessage: string, messageId: string, value?: string) => {
  const message = messages[messageId] || defaultMessage;
  if (value) {
    return message.replace('{value}', value);
  }
  return message;
};

export default getLocalisedMessage;
