import { UiSchema } from '@rjsf/utils';
import { Static, Type } from '@sinclair/typebox';
import templates from '../templates';
import { appSchema, appUiSchema } from './AppSchema';
import { LabelSchema } from './sharedSchemas';

const templatesJsonSchema = templates.map((template) => template.templateSchema);
const templatesUiSchema = templates.map((template) =>
  'templateUiSchema' in template ? template.templateUiSchema : {}
);

const PLANT_REF = new URLSearchParams(window.location.search).get('customer') ?? 'Ezer';

const categorySchema = Type.Object(
  {
    type: Type.Literal('category', {
      default: 'category',
      readOnly: true,
      title: 'Type',
      description: 'Ignore this field'
    }),
    template: Type.Literal('category', {
      default: 'category',
      readOnly: true,
      title: 'Template',
      description: 'Ignore this field'
    }),
    featureFlag: Type.Optional(
      Type.String({
        title: 'Feature Flag',
        description: 'The feature flag to enable this category, if you want it to be feature flag protected.'
      })
    ),
    label: LabelSchema({ title: 'Label', description: 'The label of the page e.g. for the NavBar' }),
    pages: Type.Array(Type.Union(templatesJsonSchema, { title: 'Page' }), {
      title: 'Pages',
      description: 'The list of pages.'
    })
  },
  {
    title: 'Category',
    description: 'A category'
  }
);

const categoryUiSchema = {
  featureFlag: {
    'ui:options': {
      // placeholder to show how to set a field option
      disabled: false
    }
  }
};

const rootPageSchema = Type.Union([categorySchema, ...templatesJsonSchema], { title: 'Cat or page' });

export const configJsonSchema = Type.Object(
  {
    app: appSchema,
    pages: Type.Array(rootPageSchema, {
      title: 'Pages',
      description: 'The list of pages.'
    })
  },
  { title: 'Ezer config', description: `Ezer config for Kiln: ${PLANT_REF}` }
);

export type ConfigType = Static<typeof configJsonSchema>;
export type ConfigVersionType = {
  number: number;
  user: string;
  timestamp: string;
  alias: string;
};
export type ConfigWrapperType = {
  config: ConfigType;
  version?: ConfigVersionType;
};

export const uiSchema: UiSchema = {
  app: appUiSchema,
  pages: {
    items: {
      anyOf: [categoryUiSchema, ...templatesUiSchema]
    }
  },
  'ui:submitButtonOptions': {
    submitText: 'Save'
  }
};
