import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../../../EzerThemeProvider';
import { getYPosition } from '../utils';
import Tick from './Tick';
import { AlarmConfig } from '../../../../schema';

type Props = {
  config: AlarmConfig;
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: spacing(8),
    marginTop: spacing(1)
  },
  ticks: {
    width: spacing(4),
    height: '100%',
    position: 'relative'
  },
  background: {
    width: spacing(5),
    height: '100%',
    backgroundColor: palette.carbon,
    borderBottom: `1px solid ${palette.white}`,
    flexDirection: 'column-reverse',
    position: 'relative'
  },
  referenceLine: {
    display: 'block',
    position: 'absolute',
    right: 0,
    left: 0,
    borderTop: `1px dotted ${palette.white}`,
    height: 0
  }
}));

const getTicks = (config: AlarmConfig) => {
  const { maxBound, minBound, highBound, lowBound, type, midBound } = config;
  const highLinePosition = getYPosition(maxBound, minBound, highBound);
  const lowLinePosition = getYPosition(maxBound, minBound, lowBound);

  const decimalPlaces = maxBound < 100 ? 1 : 0;

  const result = [{ label: minBound.toFixed(decimalPlaces), bottom: 0 }];

  if (type === 'MAX_HIGH_LOW_MIN') {
    result.push({ label: lowBound.toFixed(decimalPlaces), bottom: Math.max(lowLinePosition, 15) });
    result.push({ label: highBound.toFixed(decimalPlaces), bottom: Math.min(highLinePosition, 85) });
  }

  if (type === 'MAX_MID_MIN') {
    result.push({ label: midBound.toFixed(decimalPlaces), bottom: getYPosition(maxBound, minBound, midBound) });
  }

  result.push({ label: maxBound.toFixed(decimalPlaces), bottom: 100 });

  return result;
};

const Chart = ({ config, children }: Props) => {
  const styles = useStyles();
  const ticks = getTicks(config);
  return (
    <div className={styles.root}>
      <ol className={styles.ticks}>
        {ticks.map(({ label, bottom }) => (
          <Tick bottom={bottom} key={bottom}>
            {label}
          </Tick>
        ))}
      </ol>
      <div className={styles.background}>
        {children}
        {ticks.length > 2 && (
          <ul>
            {ticks.slice(1, ticks.length - 1).map((tick) => (
              <li className={styles.referenceLine} style={{ bottom: `${tick.bottom}%` }} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default Chart;
