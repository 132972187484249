import { FormattedNumber } from 'react-intl';
import { round } from '../utils';

type Props = {
  children?: number;
  className?: string;
};

const Number = ({ children, className }: Props) => (
  <span className={className}>{children ? <FormattedNumber value={round(children)} /> : 'N/A'}</span>
);

export default Number;
