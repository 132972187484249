/* eslint-disable react/jsx-props-no-spreading */
import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { useEzerTheme } from '../../EzerThemeProvider';
import { getLocalDate, useTimezone } from '../../utils';

const CustomTextField = ({ inputProps, ...rest }: TextFieldProps) => {
  const { palette } = useEzerTheme();
  const customTextFieldStyles = {
    input: {
      color: palette.white
    },
    button: {
      color: palette.white,
      '&:hover': {
        backgroundColor: palette.whiteShadow
      }
    }
  };
  const timezone = useTimezone();
  const value = inputProps?.value ? getLocalDate(new Date(inputProps?.value), timezone) : '';

  const newInputProps = { ...inputProps, value };
  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => event.preventDefault();
  return <TextField onKeyDown={onKeyDown} inputProps={newInputProps} {...rest} sx={customTextFieldStyles} />;
};

export default CustomTextField;
