import { subWeeks } from 'date-fns';
import { FilterControls, useEndDate, useStartDate } from '../../FilterControls';
import { DatePickerMode, Mode } from '../../FilterControls/constants';
import { LocalisedLabel, LocalisedMessage, PageTitle } from '../../lib';
import useToday from '../../useToday';
import getStartOfWeek from '../../utils/getStartOfWeek';
import ChartContainer from './ChartContainer';
import useKpiData from './useKpiData';
import * as msg from './messages';
import { TemplateSchema } from './schema';

const FreeLimeTemplatePage = ({ label, options: { chartConfig } }: TemplateSchema) => {
  const today = useToday();
  const startDate = useStartDate(subWeeks(getStartOfWeek(today), 5));
  const endDate = useEndDate(getStartOfWeek(today));
  const { data, isLoading } = useKpiData(chartConfig.featureName, startDate, endDate);
  return (
    <>
      <PageTitle>{label.default}</PageTitle>
      <FilterControls
        isLoading={isLoading}
        mode={Mode.monthAndYear}
        datePickerMode={DatePickerMode.week}
        toggleData={[
          {
            value: 'timeWithinBounds',
            label: <LocalisedMessage descriptor={msg.TimeInBounds} />
          },
          {
            value: 'sensorData',
            label: <LocalisedLabel>{chartConfig.yAxisLabel}</LocalisedLabel>
          },
          {
            value: 'standardDeviation',
            label: <LocalisedMessage descriptor={msg.StdDev} />
          }
        ]}
      />
      <ChartContainer title={label} data={data} chartConfig={chartConfig} isLoading={isLoading} />
    </>
  );
};

export default FreeLimeTemplatePage;
