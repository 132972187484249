import { TooltipProps } from 'recharts';
import { ChartTooltip, LocalisedMessage } from '../../../lib';
import { getDateAsWeek, round } from '../../../utils';
import LegendKey from './ToggleLegend/LegendKey';
import FeatureDescription from '../FeatureDescription';
import * as msg from '../../../constants/Message';

type Props = TooltipProps<string, string> & { title: string };

type WeekProps = {
  children: string;
};
const Week = ({ children }: WeekProps) => (
  <>
    <LocalisedMessage descriptor={msg.Week} /> {children}
  </>
);

const getFeatureValue = (dataKey: LegendKey, value: number): string => {
  if ([LegendKey.AverageHighPeriodDuration, LegendKey.AverageLowPeriodDuration].includes(dataKey)) {
    return `${round(value / 60, 0)} min`;
  }
  if ([LegendKey.OkPercentageTime, LegendKey.HighPercentageTime, LegendKey.LowPercentageTime].includes(dataKey)) {
    return `${round(value, 2)} %`;
  }
  return `${value}`;
};

const TooltipContent = ({ payload = [], title }: Props) => {
  const data = payload?.[0]?.payload;
  const date = data?.date ? getDateAsWeek(new Date(data.date)) : '';

  const itemListData = payload.map((item) => {
    const featureValue = getFeatureValue(item.dataKey as LegendKey, Number(item.value));
    return {
      label: (
        <>
          <FeatureDescription legendKey={item.dataKey as LegendKey} featureTitle={title} /> {title}: {featureValue}
        </>
      ),
      // @ts-ignore
      color: item.stroke ?? item.fill
    };
  });
  return <ChartTooltip label={<Week>{date}</Week>} itemListData={itemListData} />;
};
export default TooltipContent;
