import { GasAnalyserData, GasAnalyserDataItem } from '../templates/TodayPage/lib/GasAnalyserSection/useGasAnalyserData';

type ReferenceAreaDataItem = {
  start: number;
  end: number | undefined;
};

const useLowGasAnalyserData = (data: GasAnalyserData): ReferenceAreaDataItem[] => {
  const reducedData = data.reduce((lowData: ReferenceAreaDataItem[], item: GasAnalyserDataItem, currentIndex) => {
    const activeDataItem = lowData.length > 0 ? lowData.find((area) => area.end === undefined) : undefined;

    if (activeDataItem === undefined) {
      if (item.low) {
        // start new data item
        lowData.push({
          start: item.date,
          end: undefined
        });
      }
    }
    if (activeDataItem !== undefined) {
      if (!item.low) {
        activeDataItem.end = data[currentIndex - 1].date;
      }
    }
    return lowData;
  }, []);
  if (reducedData.length > 0 && reducedData[reducedData.length - 1].end === undefined) {
    reducedData[reducedData.length - 1].end = data[data.length - 1].date;
  }
  return reducedData;
};
export default useLowGasAnalyserData;
