import { useContext } from 'react';
import { FixedNowContext } from './FixedNowProvider';

const useNow = (): Date => {
  const context = useContext(FixedNowContext);
  if (context === undefined) {
    throw new Error('useNow must be used within a FixedNowProvider');
  }
  return context === null ? new Date() : context;
};

export default useNow;
