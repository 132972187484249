[
  {
    "timestamp": "2023-06-04T12:00:00Z",
    "data": {
      "shc": {
        "value": 781.6685593329536,
        "status": "ok",
        "unit": "%"
      },
      "clinker_heat_loss": {
        "value": 23.15999984741211,
        "status": "ok",
        "unit": "t/hr"
      },
      "shell_loss": {
        "value": 72.84666697184245,
        "status": "ok",
        "unit": "t/hr"
      },
      "specific_cooler_exhaust_loss": {
        "value": 123.12000020345052,
        "status": "ok",
        "unit": "%"
      },
      "stack_loss": {
        "value": 215.81666564941406,
        "status": "ok",
        "unit": "%"
      },
      "fuel_evaporation_loss": {
        "value": 11.633333524068197,
        "status": "ok",
        "unit": "C"
      },
      "calcination_energy": {
        "value": 438.3299865722656,
        "status": "ok",
        "unit": "C"
      }
    }
  },
  {
    "timestamp": "2023-06-03T13:00:00Z",
    "data": {
      "shc": {
        "value": 681.6685593329536,
        "status": "ok",
        "unit": "%"
      },
      "clinker_heat_loss": {
        "value": 23.15999984741211,
        "status": "ok",
        "unit": "t/hr"
      },
      "shell_loss": {
        "value": 72.84666697184245,
        "status": "ok",
        "unit": "t/hr"
      },
      "specific_cooler_exhaust_loss": {
        "value": 123.12000020345052,
        "status": "ok",
        "unit": "%"
      },
      "stack_loss": {
        "value": 215.81666564941406,
        "status": "ok",
        "unit": "%"
      },
      "fuel_evaporation_loss": {
        "value": 11.633333524068197,
        "status": "ok",
        "unit": "C"
      },
      "calcination_energy": {
        "value": 438.3299865722656,
        "status": "ok",
        "unit": "C"
      }
    }
  },
  {
    "timestamp": "2023-06-03T12:00:00Z",
    "data": {
      "shc": {
        "value": 881.6685593329536,
        "status": "ok",
        "unit": "%"
      },
      "clinker_heat_loss": {
        "value": 23.15999984741211,
        "status": "ok",
        "unit": "t/hr"
      },
      "shell_loss": {
        "value": 72.84666697184245,
        "status": "ok",
        "unit": "t/hr"
      },
      "specific_cooler_exhaust_loss": {
        "value": 123.12000020345052,
        "status": "ok",
        "unit": "%"
      },
      "stack_loss": {
        "value": 215.81666564941406,
        "status": "ok",
        "unit": "%"
      },
      "fuel_evaporation_loss": {
        "value": 11.633333524068197,
        "status": "ok",
        "unit": "C"
      },
      "calcination_energy": {
        "value": 438.3299865722656,
        "status": "ok",
        "unit": "C"
      }
    }
  }
]
