import { KpiData } from './useKpiData';

const getInterval = (data: KpiData) => {
  if (data.periods.length > 100) {
    return 2;
  }
  if (data.periods.length > 25) {
    return 1;
  }
  return 0;
};

export default getInterval;
