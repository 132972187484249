import { FormattedMessage } from 'react-intl';
import { Payload } from '../../types/QualityChartData';
import { round } from '../../utils';
import QualityChartLabel from '../HistoricalQualityChart/QualityChartLabel';
import { KPIFeatureConfig } from '../../useCustomer';
import { useEzerTheme } from '../../EzerThemeProvider';

export type ItemsListDataPayload = {
  value: string;
  color: string;
  name: keyof Payload;
}[];

const UpperBound = () => <FormattedMessage defaultMessage="Upper Bound" id="labels.upperBound" />;
const LowerBound = () => <FormattedMessage defaultMessage="Lower Bound" id="labels.lowerBound" />;

const useItemsListData = (payload: ItemsListDataPayload, featureConfig: KPIFeatureConfig, unit: string) => {
  const { palette } = useEzerTheme();
  const result = payload.map(({ value, color, name }) => ({
    label: (
      <>
        <QualityChartLabel>{name}</QualityChartLabel>: {round(Number(value))} {unit}
      </>
    ),
    color
  }));
  if (featureConfig.upperBound) {
    result.push({
      label: (
        <>
          <UpperBound />: {featureConfig.upperBound} {unit}
        </>
      ),
      color: palette.white
    });
  }
  if (featureConfig.lowerBound) {
    result.push({
      label: (
        <>
          <LowerBound />: {featureConfig.lowerBound} {unit}
        </>
      ),
      color: palette.white
    });
  }
  return result;
};

export default useItemsListData;
