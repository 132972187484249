import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const useFeatureFlags = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const value = query?.get('features') || '';
  const enabled = new Set(value.split(','));
  return Array.from(enabled);
};

export default useFeatureFlags;
