import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../EzerThemeProvider';
import { getLocalTime, useTimezone } from '../../../../utils';

type Props = {
  timestamp: Date;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    fontSize: spacing(1.5),
    position: 'absolute',
    paddingTop: spacing(0.5),
    top: spacing(4),
    width: spacing(4),
    textAlign: 'center',
    left: -spacing(1.75),
    '&:before': {
      position: 'absolute',
      backgroundColor: palette.white,
      width: 1,
      height: spacing(0.5),
      top: 0,
      left: '50%',
      content: '""',
      display: 'inline-block'
    }
  }
}));

const Tick = ({ timestamp }: Props) => {
  const styles = useStyles();
  const timezone = useTimezone();
  return <span className={styles.root}>{getLocalTime(timestamp, timezone)}</span>;
};

export default Tick;
