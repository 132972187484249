import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../EzerThemeProvider';
import * as msg from '../../../../constants/Message';
import { LocalisedMessage } from '../../../../lib';

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    display: 'inline-block',
    textTransform: 'uppercase',
    backgroundColor: palette.flame,
    marginLeft: spacing(0.5),
    padding: [spacing(0.25), spacing(1)]
  }
}));
const NewNotification = () => {
  const styles = useStyles();
  return (
    <p className={styles.root}>
      <LocalisedMessage descriptor={msg.New} />
    </p>
  );
};

export default NewNotification;
