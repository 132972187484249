import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../../EzerThemeProvider';
import { AlarmData } from './utils/useAlarmData';
import { useBackgroundColour } from './utils';
import { AlarmConfig } from '../../../schema';

type Props = {
  config: AlarmConfig;
  data: AlarmData;
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    backgroundColor: palette.leaf,
    position: 'absolute',
    right: 0,
    bottom: -spacing(4),
    width: `calc(100% - ${spacing(4)}px)`,
    paddingTop: spacing(0.25),
    paddingBottom: spacing(0.25),
    textAlign: 'center',
    minHeight: spacing(3)
  },

  arrow: {
    position: 'absolute',
    right: `calc(50% - ${spacing(2)}px)`,
    top: -spacing(2),
    display: 'inline-block',
    content: '""',
    borderLeft: `${spacing(2)}px solid transparent`,
    borderRight: `${spacing(2)}px solid transparent`,
    borderBottom: `${spacing(2)}px solid transparent`
  }
}));

const Signpost = ({ config, data, children }: Props) => {
  const { value } = data;
  const styles = useStyles();
  const backgroundColour = useBackgroundColour(value, config);
  return (
    <div className={styles.root} style={{ backgroundColor: backgroundColour }}>
      <span
        className={styles.arrow}
        style={{
          borderBottomColor: backgroundColour
        }}
      />
      {children}
    </div>
  );
};

export default Signpost;
