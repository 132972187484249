import { DesktopDatePicker } from '@mui/x-date-pickers';
import classNames from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../EzerThemeProvider';
import getStartOfWeek from '../../utils/getStartOfWeek';
import DatePickerMode from '../constants/DatePickerMode';
import CustomTextField from './CustomTextField';
import type { CurrentValue } from './DatePicker';
import DefaultPickerDay from './DefaultPickerDay';
import WeekPickerDay from './WeekPickerDay';
import useDatePickerState from './useDatePickerState';
import { getTimezoneOffset, useTimezone } from '../../utils';
import { Timezone } from '../../useCustomer';

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    color: palette.white
  },
  input: {
    border: 'none',
    backgroundColor: palette.rich,
    color: palette.rich,
    borderRadius: spacing(0.5)
  },
  label: {
    color: palette.white
  },
  disabled: {
    opacity: 0.5
  },
  '@global': {
    '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.white
    },
    '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${palette.white} !important`
    },
    '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${palette.white} !important`,
      borderWidth: '1px !important'
    },
    '.MuiOutlinedInput-input.Mui-disabled': {
      '-webkit-text-fill-color': `${palette.white} !important`
    },
    '.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${palette.white} !important`
    },
    '.MuiIconButton-root.Mui-disabled': {
      color: `${palette.white} !important`
    }
  }
}));

type Props = {
  value: CurrentValue;
  disabled: boolean;
  onChange: (value: CurrentValue) => void;
  label: string | React.ReactNode;
  mode?: DatePickerMode;
};

const getStartOfTimezonedWeek = (date: Date, timezone: Timezone) => {
  const timezoneOffset = getTimezoneOffset(timezone, date);
  return new Date(getStartOfWeek(date).getTime() + timezoneOffset);
};

const CustomDatePicker = ({ value, disabled, onChange, label, mode = DatePickerMode.day }: Props) => {
  const styles = useStyles();
  const { setCurrentValue } = useDatePickerState();
  const timezone = useTimezone();

  const updateValue = (newValue: CurrentValue) => {
    setCurrentValue(newValue);
    onChange(newValue);
  };

  const onChangeWeek = (newValue: string | null) => {
    if (newValue === null) {
      return newValue;
    }
    const newDate = new Date(newValue);
    const startOfWeek = getStartOfTimezonedWeek(newDate, timezone);
    return updateValue(startOfWeek);
  };

  const onChangeDay = (newValue: string | null) => updateValue(newValue !== null ? new Date(newValue) : newValue);

  return (
    <DesktopDatePicker
      className={classNames(styles.root, { [styles.disabled]: disabled })}
      label={<span className={styles.label}>{label}</span>}
      value={value}
      disabled={disabled}
      onChange={mode === DatePickerMode.week ? onChangeWeek : onChangeDay}
      renderInput={CustomTextField}
      renderDay={mode === DatePickerMode.week ? WeekPickerDay : DefaultPickerDay}
      InputProps={{
        className: styles.input
      }}
      inputFormat="yyyy-MM-dd HH:mm:ss"
    />
  );
};

export default CustomDatePicker;
