import { useEzerTheme } from '../../../EzerThemeProvider';
import LegendKey from '../Chart/ToggleLegend/LegendKey';
import FeatureDescription from '../FeatureDescription';
import * as msg from '../../../constants/Message';
import { LocalisedMessage } from '../../../lib';

type ColumnData = {
  field: string;
  // eslint-disable-next-line
  headerName: any;
  width: number;
};
const useColumns = (featureName: string): ColumnData[] => {
  const { spacing } = useEzerTheme();

  return [
    {
      field: 'week',
      headerName: <LocalisedMessage descriptor={msg.Week} />,
      width: spacing(10)
    },
    {
      field: 'percentage_time_good',
      headerName: <FeatureDescription legendKey={LegendKey.OkPercentageTime} featureTitle={featureName} showUnit />,
      width: spacing(22)
    },
    {
      field: 'percentage_time_high',
      headerName: <FeatureDescription legendKey={LegendKey.HighPercentageTime} featureTitle={featureName} showUnit />,
      width: spacing(22)
    },
    {
      field: 'percentage_time_low',
      headerName: <FeatureDescription legendKey={LegendKey.LowPercentageTime} featureTitle={featureName} showUnit />,
      width: spacing(22)
    },
    {
      field: 'high_period_count',
      headerName: <FeatureDescription legendKey={LegendKey.HighPeriodCount} featureTitle={featureName} showUnit />,
      width: spacing(20)
    },
    {
      field: 'low_period_count',
      headerName: <FeatureDescription legendKey={LegendKey.LowPeriodCount} featureTitle={featureName} showUnit />,
      width: spacing(20)
    },
    {
      field: 'average_duration_high',
      headerName: (
        <FeatureDescription legendKey={LegendKey.AverageHighPeriodDuration} featureTitle={featureName} showUnit />
      ),
      width: spacing(25)
    },
    {
      field: 'average_duration_low',
      headerName: (
        <FeatureDescription legendKey={LegendKey.AverageLowPeriodDuration} featureTitle={featureName} showUnit />
      ),
      width: spacing(25)
    }
  ];
};

export default useColumns;
