import { useContext } from 'react';
import { GetBearerTokenContext } from './GetBearerTokenProvider/GetBearerTokenProvider';

const useGetBearerToken = () => {
  const context = useContext(GetBearerTokenContext);
  if (context === undefined) {
    throw new Error('useGetBearerToken must be used within a GetBearerTokenProvider');
  }
  return context;
};

export default useGetBearerToken;
