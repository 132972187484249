import { FormattedMessage } from 'react-intl';
import { useLanguages } from '../../utils';
import Menu from '../Menu/Menu';
import ContactLink from './ContactLink';
import LanguageMenu from './LanguageMenu';
import LogoutButton from './LogoutButton';

const useItems = () => {
  const languages = useLanguages();

  if (languages.length > 1) {
    return [<ContactLink />, <LanguageMenu />, <LogoutButton />];
  }
  return [<ContactLink />, <LogoutButton />];
};

const MoreMenu = () => {
  const items = useItems();
  return (
    <Menu items={items}>
      <FormattedMessage defaultMessage="More" id="menu.more" />
    </Menu>
  );
};
export default MoreMenu;
