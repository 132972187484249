import { subDays } from 'date-fns';
import { useCurrentTime, useShiftData } from '../../utils';
import useNow from '../../useNow';

const MS_IN_AN_HOUR = 60 * 60 * 1000;

const useCurrentShift = () => {
  // shift length in ms

  const { data } = useShiftData();
  const shiftLength = data.shiftLength * MS_IN_AN_HOUR;

  // now and currentTime

  const currentTime = useCurrentTime();
  const now = useNow();

  // shift start time

  const [hours, minutes, seconds] = data.shiftStartTime.split(':');
  let shiftStartTime = new Date(now.getTime());
  shiftStartTime.setHours(Number(hours), Number(minutes), Number(seconds), 0);
  if (shiftStartTime.getTime() > currentTime.getTime()) {
    shiftStartTime = subDays(shiftStartTime, 1);
  }

  const difference = currentTime.getTime() - shiftStartTime.getTime();

  return Math.floor(difference / shiftLength) + 1;
};
export default useCurrentShift;
