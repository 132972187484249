import React from 'react';
import { DatePickerMode } from '../constants';
import CustomDatePicker from './CustomDatePicker';
import DatePickerStateProvider from './DatePickerStateProvider';

export type CurrentValue = Date | null;

type Props = {
  value: Date | null;
  disabled: boolean;
  onChange: (value: CurrentValue) => void;
  label: string | React.ReactNode;
  mode?: DatePickerMode;
};

const DatePicker = ({ value, disabled, onChange, label, mode }: Props) => (
  <DatePickerStateProvider defaultValue={value}>
    <CustomDatePicker value={value} disabled={disabled} onChange={onChange} label={label} mode={mode} />
  </DatePickerStateProvider>
);

export default DatePicker;
