import { HistoricalQualityChart } from '../../lib';
import { FeatureName, Label } from '../../types';
import { useNewCombinedQualityChartData, useUnit } from '../../utils';
import { PlantData } from '../../utils/useHistoricalPlantData';
import { SoftSensor } from '../../utils/useHistoricalSoftSensors';

type Props = {
  modelledData: SoftSensor[];
  measuredData: PlantData;
  modelled: FeatureName;
  measured: FeatureName;
  startDate: Date;
  endDate: Date;
  xAxisLabel: Label;
  yAxisLabel: Label;
  rangeStart?: number;
  rangeEnd?: number;
};
const ChartContainer = ({
  modelledData,
  measuredData,
  modelled,
  measured,
  startDate,
  endDate,
  xAxisLabel,
  yAxisLabel,
  rangeStart,
  rangeEnd
}: Props) => {
  const chartData = useNewCombinedQualityChartData(modelledData, measuredData, modelled, measured);
  const { data: unit } = useUnit(measured);

  return (
    <HistoricalQualityChart
      data={chartData}
      startDate={startDate}
      endDate={endDate}
      modelled={modelled}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisLabel}
      unit={unit}
      rangeStart={rangeStart}
      rangeEnd={rangeEnd}
    />
  );
};

export default ChartContainer;
