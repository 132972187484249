import useAuthorisedQuery from './useAuthorisedQuery';
import { FeatureName } from '../types';
import { getUtcDate } from '../utils';
import { Api } from '../settings';

const useKpiByHourQuery = (featureName: FeatureName, startDate: Date, endDate: Date) => {
  const startPeriod = getUtcDate(startDate);
  const endPeriod = getUtcDate(endDate);
  return useAuthorisedQuery(
    ['kpi-query-by-hour', startPeriod, endPeriod, featureName, 'api-serve'],
    `kpi/?feature=${featureName}&period_type=hour&start_period=${startPeriod}&end_period=${endPeriod}`,
    {},
    Api.serve
  );
};

export default useKpiByHourQuery;
