import Chart from './Chart/Chart';
import useGasAnalyserData from './useGasAnalyserData';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import { useUnit } from '../../../utils';
import { Options } from '../schema';

type Props = {
  startDate: Date;
  endDate: Date;
  options: Options;
  plantData: PlantData;
  performanceData: SoftSensor[];
};

const ChartContainer = ({ startDate, endDate, options, plantData, performanceData }: Props) => {
  const { leftFeature, rightFeature } = options;
  const data = useGasAnalyserData(startDate, endDate, plantData, performanceData, leftFeature, rightFeature);
  const { data: leftUnit } = useUnit(leftFeature.featureName);
  const { data: rightUnit } = useUnit(rightFeature.featureName);

  return (
    <Chart
      data={data}
      startDate={startDate}
      endDate={endDate}
      options={options}
      leftUnit={leftUnit}
      rightUnit={rightUnit}
    />
  );
};

export default ChartContainer;
