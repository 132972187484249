import * as msg from '../../../constants/Message';
import { useEzerTheme } from '../../../EzerThemeProvider';
import type { Feature } from '../schema';
import { LocalisedMessage } from '../../../lib';

const TimeLabel = () => <LocalisedMessage descriptor={msg.TableTimestamp} />;
const HistoricalLabel = () => <LocalisedMessage descriptor={msg.Historical} />;
const RecommendedLabel = () => <LocalisedMessage descriptor={msg.Recommended} />;
const FeedbackLabel = () => <LocalisedMessage descriptor={msg.TableFeedback} />;

type ColumnData = {
  field: string;
  // eslint-disable-next-line
  headerName: any;
  width: number;
};
const useColumns = (features: Feature[]): ColumnData[] => {
  const { spacing } = useEzerTheme();
  const columns = [
    {
      field: 'timestamp',
      headerName: <TimeLabel />,
      width: spacing(20)
    }
  ];

  features.forEach(({ title, recommendedFeatureName, historicalFeatureName }) => {
    columns.push({
      field: `${historicalFeatureName}-historical`,
      headerName: (
        <>
          <HistoricalLabel /> {title.default}
        </>
      ),
      width: spacing(30)
    });
    columns.push({
      field: `${recommendedFeatureName}-recommended`,
      headerName: (
        <>
          <RecommendedLabel /> {title.default}
        </>
      ),
      width: spacing(30)
    });
  });

  columns.push({ field: 'feedback', headerName: <FeedbackLabel />, width: spacing(60) });
  return columns;
};

export default useColumns;
