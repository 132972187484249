import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

interface LoadingProp {
  isLoading: boolean;
  delay?: boolean;
  className?: string;
  size?: number;
}

/**
 * Progress indicator with an option to have 1.5 secs of delay after
 * the API call has finished. This is useful for API calls which finish
 * so quickly that the loading indicator would only appear for a very
 * short amount of time otherwise.
 */
const ProgressIndicator = ({ isLoading, delay = false, className, size = 32 }: LoadingProp) => {
  const [isIconVisible, setIsIconVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) {
      setIsIconVisible(true);
    } else if (isIconVisible && delay) {
      setTimeout(() => {
        setIsIconVisible(false);
      }, 1500);
    } else {
      setIsIconVisible(false);
    }
  }, [isLoading, delay, isIconVisible]);

  return (
    <span className={className}>{isIconVisible && <CircularProgress size={size} style={{ color: 'white' }} />}</span>
  );
};

export default ProgressIndicator;
