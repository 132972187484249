import { subHours } from 'date-fns';
import useLatestIngestJobQuery from '../serviceQueries/useLatestIngestJobQuery';
import useNow from '../useNow';

export type IngestJob = {
  id: number;
  createdAt: string;
  status: 'OK' | 'API_DOWN' | 'NO_DATA';
};
export type Response = {
  data: IngestJob | null;
  isLoading: boolean;
};

const useCurrentIngestJob = (): Response => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const { data: ingestJob = [], isLoading } = useLatestIngestJobQuery(startDate, now);
  const data = ingestJob.length > 0 ? ingestJob[0] : null;

  return { data, isLoading };
};

export default useCurrentIngestJob;
