import { enCA, enGB, enUS, vi } from 'date-fns/locale';

export const AUTH0_AUDIENCE = 'https://dz/api';

// eslint-disable-next-line no-undef
export const LocalesMap = new Map<string, Locale>([
  ['en-GB', enGB],
  ['en-US', enUS],
  ['en-CA', enCA],
  ['vi', vi]
]);

export const API_BASE_PATH = '/api/v1';
export const API_NADA_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}${API_BASE_PATH}`;
// Base URL for new API using API Gateway.
export const API_SERVE_BASE_URL = `${process.env.REACT_APP_API_SERVE_BASE_URL}`;
export const API_INFERENCE_BASE_URL = `${process.env.REACT_APP_API_INFERENCE_BASE_URL}`;

export const LOCALE_FILE_BASE_URL = `${process.env.REACT_APP_LOCALE_FILE_BASE_URL}`;

export enum Api {
  nada,
  serve,
  inference
}
