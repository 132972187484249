import { createUseStyles } from 'react-jss';
import { LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { AxisFrame, CartesianGrid, Line } from '../../../lib';
import { useSelectedParam } from '../../../lib/Select';
import { getLocalDate, useTicksByDate, useTimezone } from '../../../utils';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import TooltipContent from './TooltipContent';
import useChartData from './useChartData';
import { Feature } from '../schema';

type Props = {
  data: PlantData;
  features: Feature[];
  startDate: Date;
  endDate: Date;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  }
}));

const Chart = ({ data, features, startDate, endDate }: Props) => {
  const styles = useStyles();
  const featureNames = features.map(({ featureName }) => featureName);
  const chartData = useChartData(data, featureNames);
  const ticks = useTicksByDate(startDate, endDate);
  const { palette, spacing } = useEzerTheme();
  const selectedParam = useSelectedParam();
  const chartParam = selectedParam ?? featureNames[0];
  const selectedFeature = features.find(({ featureName }) => featureName === chartParam) || features[0];
  const timezone = useTimezone();

  const { title, rangeStart = 'auto', rangeEnd = 'auto' } = selectedFeature;
  const renderTicksAtAngle = ticks.length > 15;

  return (
    <AxisFrame yAxisLabel={title}>
      <ResponsiveContainer width="100%" height={spacing(58)} className={styles.root}>
        <LineChart data={chartData.get(chartParam)}>
          <CartesianGrid />
          <Line dataKey="y" stroke={palette.leaf} />
          <XAxis
            dataKey="x"
            type="number"
            ticks={ticks}
            angle={renderTicksAtAngle ? -45 : 0}
            height={renderTicksAtAngle ? spacing(8) : spacing(3)}
            textAnchor={renderTicksAtAngle ? 'end' : 'middle'}
            tickFormatter={(tick: string) => getLocalDate(new Date(tick), timezone)}
            domain={[startDate.getTime(), endDate.getTime()]}
            allowDataOverflow
            interval={0}
          />
          <YAxis domain={[rangeStart, rangeEnd]} tickCount={5} allowDataOverflow />
          <Tooltip content={<TooltipContent descriptor={title} />} />
        </LineChart>
      </ResponsiveContainer>
    </AxisFrame>
  );
};
export default Chart;
