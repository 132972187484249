import { FeatureName } from '../types';
import useRecommendationsQuery from '../serviceQueries/useRecommendationsQuery';
import { InferenceRecommendation } from '../types/InferenceRecommendation';

type Response = {
  data: InferenceRecommendation[];
  isLoading: boolean;
};

// eslint-disable-next-line
const buildRecommendation = (datum: any): InferenceRecommendation => ({
  timestamp: new Date(datum.timestamp),
  feature: datum.feature as FeatureName,
  value: datum.value === null ? 0 : datum.value,
  error: datum.error ?? null
});

const useHistoricalRecommendationsData = (featureNames: FeatureName[], startDate: Date, endDate: Date): Response => {
  const { data = [], isLoading } = useRecommendationsQuery(startDate, endDate, {
    useInferenceApi: true
  });

  const recommendations = data
    // eslint-disable-next-line
    .map((datum: any) => buildRecommendation(datum))
    .filter(
      (recommendation: InferenceRecommendation) =>
        recommendation.error === null && featureNames.includes(recommendation.feature)
    );

  return {
    data: recommendations,
    isLoading
  };
};

export default useHistoricalRecommendationsData;
