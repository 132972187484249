/* eslint-disable max-len */

import { Route, Routes } from 'react-router-dom';
import Config from './Config/Config';
import ConfigJson from './Config/ConfigJson';
import { ConfigType } from './Config/ConfigSchema';
import useConfig from './Config/useConfig';
import NotFoundPage from './NotFoundPage';
import { Slug2Template, Slugs, SlugToTemplateProperties, TemplateComponent } from './templates/routingUtils';
import { useUserPermissions } from './utils';

/**
 * Utility function to help the compiler track a particular React component for that particular templateSlug type.
 */
const renderTemplate = <K extends Slugs>(page: SlugToTemplateProperties[K]) => {
  const Template = Slug2Template[page.template] as TemplateComponent<K>;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Template {...{ key: page.path, ...page }} />;
};

const flattenPages = (config?: ConfigType) =>
  (config?.pages || []).flatMap((page) => (page.type === 'category' ? page.pages : page));

const AppRoutes = () => {
  const { data: config, isLoading } = useConfig();
  const { data: permissions } = useUserPermissions();

  if (isLoading) {
    return null;
  }

  return (
    <Routes>
      {flattenPages(config).map((page) => (
        <Route key={page.path} path={page.path} element={renderTemplate(page)} />
      ))}
      {permissions?.canEditConfig && (
        <>
          <Route path="/config" element={<Config />} />
          <Route path="/config-json" element={<ConfigJson />} />
        </>
      )}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
