import useFeedbacksQuery from '../serviceQueries/useFeedbacksQuery';

export type Feedback = {
  timestamp: Date;
  content: string;
};

type Response = {
  data: Feedback[];
  isLoading: boolean;
};

// eslint-disable-next-line
const buildFeedback = (datum: any): Feedback => ({
  timestamp: new Date(datum.timestamp),
  content: datum?.content ?? ''
});

const useHistoricalFeedbacks = (startDate: Date, endDate: Date): Response => {
  const { data = [], isLoading } = useFeedbacksQuery(startDate, endDate);

  const feedbacks = data
    // eslint-disable-next-line
    .map((datum: any) => buildFeedback(datum));

  return {
    data: feedbacks,
    isLoading
  };
};

export default useHistoricalFeedbacks;
