import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addHours, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { EzerTheme } from '../EzerThemeProvider';
import { Select, Toggles } from '../lib';
import type { SelectData } from '../lib/Select';
import type { TogglesData } from '../lib/Toggles/Toggles';
import { LocalesMap } from '../settings';
import useNow from '../useNow';
import { getStartOfWeek } from '../utils';
import EndDatePicker from './EndDatePicker';
import FilterButton from './FilterButton';
import StartDatePicker from './StartDatePicker';
import { DatePickerMode, Mode, Unit } from './constants';
import useButtonSettings from './useButtonSettings';

type Props = {
  isLoading: boolean;
  mode?: Mode;
  datePickerMode?: DatePickerMode;
  selectData?: SelectData;
  toggleExclusive?: boolean;
  toggleData?: TogglesData;
  showFutureWindow?: boolean;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(2),
    marginTop: spacing(4)
  },
  select: {
    flexGrow: 1
  }
}));

const calculateDate = (date: Date, datePickerMode: DatePickerMode, showFutureWindow: boolean) => {
  if (datePickerMode === DatePickerMode.week) {
    return getStartOfWeek(date);
  }
  if (showFutureWindow) {
    return addHours(date, 8);
  }
  return date;
};

/**
 * This component does not contain any of the necessary state variables or setters by itself; these need to be passed
 * to it in each case by the parent component that is actually rendering the data. These props are refreshed every time
 * their values change in the parent component, making this approach just like accessing startDate, endDate and the
 * setters from it directly.
 */
const FilterControls = ({
  isLoading,
  mode = Mode.day,
  datePickerMode = DatePickerMode.day,
  selectData = [],
  toggleExclusive = false,
  toggleData = [],
  showFutureWindow = false
}: Props) => {
  const styles = useStyles();
  const today = useNow();
  const buttonSettings = useButtonSettings(mode);
  const [searchParams, setSearchParams] = useSearchParams();
  const setDateParams = useCallback(
    (
      startDate: Date,
      endDate: Date,
      options?: {
        replace: boolean;
      }
    ) => {
      searchParams.set('startDate', calculateDate(startDate, datePickerMode, showFutureWindow).toISOString());
      searchParams.set('endDate', calculateDate(endDate, datePickerMode, showFutureWindow).toISOString());
      setSearchParams(searchParams, options);
    },
    [searchParams, setSearchParams, datePickerMode, showFutureWindow]
  );
  const calculateEndDate = useCallback(
    (value: number, unit: Unit) => {
      if (unit === Unit.year) {
        return subYears(today, value);
      }
      if (unit === Unit.month) {
        return subMonths(today, value);
      }
      if (unit === Unit.week) {
        return subWeeks(today, value);
      }
      return subDays(today, value);
    },
    [today]
  );

  const [activeButtonIndex, setActiveButtonIndex] = useState(1);

  const filter = (index: number | null) => {
    if (index !== null) {
      const { value, unit } = buttonSettings[index];
      setDateParams(calculateEndDate(value, unit), today);
      setActiveButtonIndex(index);
    }
  };

  // set the startDate and endDate params of the url at initial page load

  useEffect(() => {
    if (!searchParams.get('startDate') && !searchParams.get('endDate')) {
      const { value, unit } = buttonSettings[activeButtonIndex];
      setDateParams(calculateEndDate(value, unit), today, { replace: true });
    }
  }, [searchParams, buttonSettings, activeButtonIndex, setDateParams, calculateEndDate, today]);

  const { value: maxValue, unit: maxUnit } = buttonSettings[buttonSettings.length - 1];

  return (
    <div className={styles.root} data-testid="filter-controls">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={LocalesMap.get(navigator.language)}>
        {buttonSettings.map(({ value, unit, label }, index) => (
          <FilterButton
            key={`${value}-${unit}`}
            isActive={index === activeButtonIndex}
            disabled={isLoading}
            duration={index}
            onFilter={filter}>
            {label}
          </FilterButton>
        ))}
        <StartDatePicker mode={datePickerMode} disabled={isLoading} range={maxValue} unit={maxUnit} />
        <EndDatePicker mode={datePickerMode} disabled={isLoading} range={maxValue} unit={maxUnit} />
        {selectData.length > 0 && (
          <Select className={styles.select} data={selectData} disabled={isLoading}>
            <FormattedMessage id="labels.showChart" defaultMessage="Show Chart" />
          </Select>
        )}
        {toggleData.length > 0 && <Toggles data={toggleData} disabled={isLoading} exclusiveMode={toggleExclusive} />}
      </LocalizationProvider>
    </div>
  );
};

export default FilterControls;
