import { ReactNode } from 'react';
import Checkbox from '@mui/icons-material/CheckBox';
import CheckboxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { createUseStyles } from 'react-jss';
import useLegendToggledItems from './useLegendToggledItems';
import LegendKey from './LegendKey';

type Props = {
  value: LegendKey;
  children: ReactNode;
};

const useStyles = createUseStyles({
  root: {
    display: 'inline-block',
    marginTop: -14,
    cursor: 'pointer'
  },
  icon: {
    position: 'relative',
    top: 6
  }
});
const LegendItem = ({ value, children }: Props) => {
  const styles = useStyles();
  const legendToggledItems = useLegendToggledItems();
  const selected = legendToggledItems.length === 0 || legendToggledItems.includes(value);

  return (
    <span className={styles.root}>
      {children} {selected ? <Checkbox className={styles.icon} /> : <CheckboxOutlineBlank className={styles.icon} />}
    </span>
  );
};

export default LegendItem;
