import React from 'react';
import useNavBarElements, { NavBarCategory, RootNavBarElement } from './useNavBarElements';
import { SubNavBar } from './MainNav';

function isCurrentCategory(elem: RootNavBarElement): elem is NavBarCategory {
  return elem.isCurrentPage && elem.isCategory;
}

const StickySubNav = () => {
  const { data: elements } = useNavBarElements();
  const subNavElements = elements.find(isCurrentCategory)?.subnavElements;
  return <SubNavBar subNavBarElements={subNavElements} sticky />;
};

export default StickySubNav;
