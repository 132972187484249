import * as msg from '../../../constants/Message';
import { Feature } from '../schema';
import { LocalisedMessage } from '../../../lib';

export enum Column {
  Date = 'date',
  LeftValue = 'leftValue',
  RightValue = 'rightValue'
}

interface ColumnData {
  field: Column;
  // eslint-disable-next-line
  headerName: any;
  width: number;
}

const DateTime = () => <LocalisedMessage descriptor={msg.DateTime} />;

const useColumnData = (leftFeature: Feature, rightFeature: Feature): ColumnData[] => [
  { field: Column.Date, headerName: <DateTime />, width: 200 },
  { field: Column.LeftValue, headerName: leftFeature.title, width: 180 },
  { field: Column.RightValue, headerName: rightFeature.title, width: 180 }
];

export default useColumnData;
