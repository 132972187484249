/* eslint-disable @typescript-eslint/no-explicit-any */
import { getMinutes, set, subHours } from 'date-fns';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useDataStatusQuery from '../../../../serviceQueries/useDataStatusQuery';
import useNow from '../../../../useNow';
import { useMinuteInterval } from '../../../../utils';

export type DataStatus = {
  timestamp: Date;
  status: 'OK' | 'DEGRADED' | 'ERROR';
};

type Response = {
  data: DataStatus[];
  isLoading: boolean;
};

const useDataStatus = (): Response => {
  const now = useNow();
  const [endDate, setEndDate] = useState(set(now, { seconds: 0, milliseconds: 0 }));
  const startDate = subHours(endDate, 24);
  const { data: ingestJobsData = [], isLoading } = useDataStatusQuery(startDate, endDate);
  const [searchParams] = useSearchParams();
  const isTimeTravelActive = searchParams.has('now');

  useMinuteInterval(() => {
    const time = new Date();
    const minutes = getMinutes(time);
    // re request data every quarter hour + 3 mins
    if (!isTimeTravelActive && [3, 18, 33, 48].includes(minutes)) {
      setEndDate(set(time, { seconds: 0, milliseconds: 0 }));
    }
  });

  const data = ingestJobsData.map((datum: any) => ({
    timestamp: new Date(datum.timestamp),
    status: datum.status as DataStatus['status']
  }));
  return { data, isLoading };
};

export default useDataStatus;
