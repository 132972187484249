import { addMinutes, differenceInMinutes } from 'date-fns';
import { Api } from '../settings';
import { FeatureName } from '../types';
import getCacheKeyDate from '../utils/getCacheKeyDate';
import getUtcDate from '../utils/getUtcDate';
import useAuthorisedQuery from './useAuthorisedQuery';

const ONE_MINUTE = 60000;

export const buildQueryKey = (
  startDate: Date,
  endDate: Date,
  sampleRate: number,
  featureNames: FeatureName[],
  refetchMode = false
) => {
  const result = ['historical-plant-data', `sample-rate-${sampleRate}`, ...featureNames];
  if (!refetchMode) {
    result.push(getCacheKeyDate(startDate));
    result.push(getCacheKeyDate(endDate));
  }
  return result;
};
type Options = {
  refetch?: boolean;
  disable?: boolean;
};

// set the refetch interval to 15 mins when the sampleRate is in use.
// doing this for now as the sampled responses are quite slow
const calculateRefetchInterval = (sampleRate: number) => (sampleRate === 15 ? ONE_MINUTE : 15 * ONE_MINUTE);

const useHistoricalPlantDataQuery = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date,
  sampleRate: number,
  options?: Options
) => {
  // never made a request shorter than the sample rate, as this will crash the endpoint
  const queryEndDate =
    differenceInMinutes(endDate, startDate) <= sampleRate ? addMinutes(startDate, sampleRate) : endDate;
  const features = featureNames.join(',');
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(queryEndDate);
  const refetchMode = !!options?.refetch;
  const enabled = !options?.disable;

  return useAuthorisedQuery(
    buildQueryKey(startDate, queryEndDate, sampleRate, featureNames, refetchMode),
    `plant-data/?features=${features}&start_datetime=${startDateValue}&end_datetime=${endDateValue}&sample_rate=${sampleRate}`,
    {
      refetchInterval: refetchMode ? calculateRefetchInterval(sampleRate) : false,
      enabled
    },
    Api.serve
  );
};
export default useHistoricalPlantDataQuery;
