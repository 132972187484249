import { LoadingMessage } from '../../../../lib';
import { ContentContainer } from '../../lib';

type Props = {
  dataTestId?: string;
};
const LoadingContent = ({ dataTestId = 'loading-content' }: Props) => (
  <ContentContainer dataTestId={dataTestId}>
    <LoadingMessage />
  </ContentContainer>
);

export default LoadingContent;
