import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../../EzerThemeProvider';
import { AlarmData } from './utils/useAlarmData';
import { getYPosition, useBackgroundColour } from './utils';
import { AlarmConfig } from '../../../schema';

type Props = {
  config: AlarmConfig;
  data: AlarmData;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
    position: 'absolute',
    bottom: 0,
    left: spacing(0.25),
    right: spacing(0.25)
  }
}));

const Chart = ({ config, data }: Props) => {
  const styles = useStyles();
  const { maxBound, minBound } = config;
  const { value } = data;
  const barHeight = getYPosition(maxBound, minBound, value);
  const backgroundColour = useBackgroundColour(value, config);
  return <div className={styles.root} style={{ height: `${barHeight}%`, backgroundColor: backgroundColour }} />;
};

export default Chart;
