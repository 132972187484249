import { theme } from '../EzerThemeProvider';

const rechartsStyles = {
  '.recharts-xAxis text, .recharts-yAxis text': {
    fill: theme.palette.white
  },
  '.recharts-xAxis line, .recharts-yAxis line': {
    stroke: theme.palette.white
  }
};

export default rechartsStyles;
