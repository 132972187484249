import * as DailyAverageKpiUnrankedPage from './DailyAverageKpiUnrankedPage';
import * as FreeLimeTemplatePage from './FreeLimeTemplatePage';
import * as HistoricalGasAnalyserPage from './HistoricalGasAnalyserPage';
import * as HistoricalQualityGatewayPage from './HistoricalQualityGatewayPage';
import * as HistoricalRecommendationsMultiAxisPage from './HistoricalRecommendationsMultiAxisPage';
import * as HistoricalRecommendationsNewConfigPage from './HistoricalRecommendationsNewConfigPage';
import * as HistoricalWeeklyPerformancePage from './HistoricalWeeklyPerformancePage';
import * as TimelineTemplatePage from './TimelineTemplatePage';
import * as TodayPage from './TodayPage';

const templates = [
  DailyAverageKpiUnrankedPage,
  FreeLimeTemplatePage,
  HistoricalGasAnalyserPage,
  HistoricalQualityGatewayPage,
  HistoricalRecommendationsMultiAxisPage,
  HistoricalRecommendationsNewConfigPage,
  HistoricalWeeklyPerformancePage,
  TimelineTemplatePage,
  TodayPage
] as const;

export default templates;
