// TM: https://developer.chrome.com/blog/page-lifecycle-api/

import opentelemetry from '@opentelemetry/api';
import { flatten } from 'flat';

// TODO remove this cycle dependency
// https://linear.app/carbonre/issue/ENG-1262/improve-the-ezer-page-lifecycle-observability-code
// eslint-disable-next-line import/no-cycle
import { provider } from './instrumentation';

export const getState = () => {
  if (document.visibilityState === 'hidden') {
    return 'hidden';
  }
  if (document.hasFocus()) {
    return 'active';
  }
  return 'passive';
};

type State = 'init' | 'hidden' | 'active' | 'passive' | 'frozen' | 'terminated';

let state: State = 'init';
let index = 1;
export const logStateChange = (nextState: State) => {
  const prevState = state;
  if (nextState === prevState) return;

  const attributes = flatten({
    pageState: {
      prev: prevState,
      next: nextState,
      index
    }
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  }) as any;
  opentelemetry.trace.getTracer('ezer').startSpan('stateChange', { attributes }).end();
  index++;
  state = nextState;
};

setTimeout(() => {
  logStateChange(getState());
});

const eventListenersOptions = { capture: true };

['pageshow', 'focus', 'blur', 'visibilitychange', 'resume', 'pagehide'].forEach((type) => {
  window.addEventListener(type, () => logStateChange(getState()), eventListenersOptions);
});

window.addEventListener(
  'freeze',
  () => {
    logStateChange('frozen');
  },
  eventListenersOptions
);

window.addEventListener(
  'pagehide',
  (event) => {
    // If the event's persisted property is `true` the page is about
    // to enter the back/forward cache, which is also in the frozen state.
    // If the event's persisted property is not `true` the page is
    // about to be unloaded.
    const status = event.persisted ? 'frozen' : 'terminated';
    logStateChange(status);
    if (status === 'terminated') provider.forceFlush();
  },
  eventListenersOptions
);

window.addEventListener(
  'beforeunload',
  () => {
    logStateChange('terminated');
    provider.forceFlush();
  },
  eventListenersOptions
);
