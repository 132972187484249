import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';

type Props = {
  children: React.ReactNode;
  href: string;
  icon?: React.ReactNode;
};

export const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    color: palette.white,
    display: 'flex',
    marginLeft: spacing(2),
    marginRight: spacing(2),
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    fontSize: spacing(2),
    lineHeight: `${spacing(3)}px`,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  icon: {
    position: 'relative',
    top: -spacing(0.25)
  },
  message: {
    paddingLeft: spacing(0.5),
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));
const IconLink = ({ children, href, icon }: Props) => {
  const styles = useStyles();
  return (
    <a href={href} target="_blank" className={styles.root} rel="noreferrer">
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.message}>{children}</span>
    </a>
  );
};

export default IconLink;
