import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { ConfigLabel } from '../lib';
import { usePath } from '../utils';
import type { NavBarCategory as NavBarCategoryProps, NavBarPage, RootNavBarElement } from './useNavBarElements';
import useNavBarElements from './useNavBarElements';
import { EzerTheme } from '../EzerThemeProvider';

const useStyles = createUseStyles(({ palette, spacing, mediaQueries, maxContentWidth }: EzerTheme) => ({
  bodySpacing: {
    // This is the same as NewHeader
    padding: [0, spacing(2)],
    [mediaQueries.desktop]: {
      padding: [0, spacing(4)]
    }
  },
  mainNavList: {
    display: 'flex',
    flexDirection: 'row'
  },
  linkItem: {
    marginRight: spacing(4),
    borderBottom: `${spacing(0.5)}px solid transparent`,
    transition: 'border-bottom-color 0.2s ease 0.1s',
    '&:hover': {
      borderBottomColor: palette.white,
      transition: 'border-bottom-color 0.2s'
    }
  },
  selectedLinkItem: {
    borderBottom: `${spacing(0.5)}px solid ${palette.white}`
  },
  link: {
    display: 'block',
    color: palette.white,
    textDecoration: 'none',
    fontSize: spacing(2),
    lineHeight: `${spacing(3)}px`,
    paddingTop: spacing(1),
    paddingBottom: spacing(0.5)
  },
  category: {
    color: palette.white,
    cursor: 'pointer',
    display: 'block',
    fontSize: spacing(2),
    lineHeight: `${spacing(3)}px`,
    paddingTop: spacing(1),
    paddingBottom: spacing(0.5)
  },
  subNav: {
    backgroundColor: palette.white,
    color: palette.carbon,
    width: '100%',
    zIndex: 2
  },
  subNavList: {
    margin: [0, 'auto'],
    maxWidth: maxContentWidth
  },
  subNavHoverable: {
    top: '100%',
    left: 0,
    position: 'absolute',
    opacity: 0,
    visibility: 'collapse',
    transition: 'opacity 0.4s ease 0.15s, visibility 0s ease 0.4s',
    '$linkItem:hover &': {
      opacity: 1,
      visibility: 'visible',
      transition: 'opacity 0.4s ease 0.1s, visibility 0s ease'
    }
  },
  subLinkItem: {
    '&:hover': {
      borderBottomColor: palette.carbon
    }
  },
  subSelectedLinkItem: {
    borderBottom: `${spacing(0.5)}px solid ${palette.carbon}`
  },
  subLink: {
    color: palette.carbon
  }
}));

const NavBarCategory = ({ elem: { label } }: { elem: NavBarCategoryProps }) => {
  const styles = useStyles();
  return (
    <span className={styles.category}>
      <ConfigLabel label={label} />
    </span>
  );
};

const NavBarLink = ({ elem: { label, path }, subNav }: { elem: NavBarPage; subNav?: boolean }) => {
  const styles = useStyles();
  const to = usePath(path);

  return (
    <NavLink className={classNames(styles.link, subNav && styles.subLink)} to={to}>
      <ConfigLabel label={label} />
    </NavLink>
  );
};

const NavBarEl = ({ elem, children }: React.PropsWithChildren<{ elem: RootNavBarElement }>) => {
  const styles = useStyles();
  const { isCurrentPage } = elem;
  const isSubNav = !elem.isCategory && elem.isSubNav;

  return (
    <li
      className={classNames(
        styles.linkItem,
        isCurrentPage && styles.selectedLinkItem,
        isSubNav && styles.subLinkItem,
        isCurrentPage && isSubNav && styles.subSelectedLinkItem
      )}>
      {children}
    </li>
  );
};

interface SubNavBarProps {
  subNavBarElements?: NavBarPage[];
  sticky?: boolean;
}

export const SubNavBar = ({ subNavBarElements, sticky }: SubNavBarProps) => {
  const styles = useStyles();
  return !subNavBarElements ? null : (
    <nav className={classNames(styles.subNav, !sticky && styles.subNavHoverable, styles.bodySpacing)}>
      <ol className={classNames(styles.mainNavList, styles.subNavList)}>
        {subNavBarElements?.map((elem) => (
          <NavBarEl elem={elem} key={elem.path}>
            <NavBarLink elem={elem} subNav />
          </NavBarEl>
        ))}
      </ol>
    </nav>
  );
};

const MainNav = () => {
  const { data: elements } = useNavBarElements();
  const styles = useStyles();

  return (
    <nav>
      <ol className={styles.mainNavList}>
        {elements?.map((elem) => (
          <NavBarEl elem={elem} key={elem.isCategory ? elem.label.default : elem.path}>
            {elem.isCategory ? (
              <>
                <NavBarCategory elem={elem} />
                {!elem.isCurrentPage && <SubNavBar subNavBarElements={elem.subnavElements} />}
              </>
            ) : (
              <NavBarLink elem={elem} />
            )}
          </NavBarEl>
        ))}
      </ol>
    </nav>
  );
};

export default MainNav;
