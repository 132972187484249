import { Type } from '@sinclair/typebox';
import type { ConfigVersionType } from './ConfigSchema';

// eslint-disable-next-line import/prefer-default-export
export const selectableEnum: typeof Type.Enum = (...args) => {
  const schema = Type.Enum(...args);
  // We need to add `{ type: string }` for RSJF to treat Enums as a Select UI element
  schema.type = 'string';
  return schema;
};

export const displayVersion = (version?: ConfigVersionType) => {
  if (!version) {
    return 'Version: New';
  }
  const timestamp = `${new Date(version.timestamp).toISOString().split('.')[0]}Z`;
  return `Alias: ${version.alias}. Version: ${version.number}. Updated by ${version.user} at ${timestamp}`;
};
