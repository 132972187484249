import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FormattedMessage } from 'react-intl';
import IconLink from './IconLink';

const HelpLink = () => (
  <IconLink href="https://deltazero.help.carbonre.com/" icon={<HelpOutlineIcon />}>
    <FormattedMessage id="buttons.help" defaultMessage="Help" />
  </IconLink>
);
export default HelpLink;
