import useAuthorisedQuery from './useAuthorisedQuery';
import getUtcDate from '../utils/getUtcDate';

const ONE_MINUTE = 60000;

const useLatestIngestJobQuery = (startDate: Date, endDate: Date) =>
  useAuthorisedQuery(
    ['ingest-job', 'limit-1'],
    `ingest-job/?start_datetime=${getUtcDate(startDate)}&end_datetime=${getUtcDate(endDate)}&limit=1`,
    {
      refetchInterval: ONE_MINUTE
    }
  );

export default useLatestIngestJobQuery;
