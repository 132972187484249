import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { LocalisedLabel, LoadingMessage } from '../../lib';
import { KpiData } from './useKpiData';
import Chart from './Chart';
import { EzerTheme } from '../../EzerThemeProvider';
import { ChartConfig, TemplateSchema } from './schema';

type Props = {
  data: KpiData;
  chartConfig: ChartConfig;
  isLoading: boolean;
  title: TemplateSchema['label'];
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  title: {
    textAlign: 'center',
    fontSize: spacing(4),
    marginTop: spacing(2)
  }
}));

const ChartContainer = ({ title, data, chartConfig, isLoading }: Props) => {
  const styles = useStyles();
  return (
    <>
      <h2 className={styles.title}>
        <LocalisedLabel>{title}</LocalisedLabel>
      </h2>
      {isLoading && <LoadingMessage />}
      {data.periods.length === 0 && !isLoading && (
        <p>
          <FormattedMessage defaultMessage="No data available" id="labels.noData" />
        </p>
      )}
      {data.periods.length > 0 && !isLoading && <Chart data={data} chartConfig={chartConfig} />}
    </>
  );
};

export default ChartContainer;
