/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { addDays, getDayOfYear, subDays, startOfDay } from 'date-fns';
import { PickersDayProps } from '@mui/x-date-pickers';
import useDatePickerState from './useDatePickerState';
import CustomPickersDay from './CustomPickersDay';

const DAYS_IN_WEEK = 7;

const WeekPickerDay = (day: string, selectedDates: string[], pickersDayProps: PickersDayProps<string>) => {
  const { currentValue } = useDatePickerState();
  if (!currentValue) {
    return <CustomPickersDay {...pickersDayProps} />;
  }
  const currentDay = startOfDay(currentValue);
  const targetDay = new Date(day);
  const startDay = subDays(currentDay, currentDay.getDay() - 1);
  const endDay = addDays(currentDay, DAYS_IN_WEEK - currentDay.getDay());
  const selected = targetDay.getTime() >= startDay.getTime() && targetDay.getTime() <= endDay.getTime();
  const isFirstDay = selected && getDayOfYear(targetDay) === getDayOfYear(startDay);
  const isLastDay = selected && getDayOfYear(targetDay) === getDayOfYear(endDay);

  return (
    <CustomPickersDay
      {...pickersDayProps}
      selected={selected}
      isFirstDay={isFirstDay}
      isInBetween={selected && !isFirstDay && !isLastDay}
      isLastDay={isLastDay}
    />
  );
};

export default WeekPickerDay;
