import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../../../EzerThemeProvider';
import Chart from './Chart/Chart';
import Signpost from './Signpost';
import AlarmValue from './AlarmValue';
import Bar from './Bar';
import useAlarmData from './utils/useAlarmData';
import { useUnit } from '../../../../../utils';
import { AlarmConfig } from '../../../schema';

type Props = {
  config: AlarmConfig;
  className: string;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    backgroundColor: palette.rich,
    '&:first-child': {
      borderTopLeftRadius: spacing(0.5)
    },
    '&:last-child': {
      borderTopRightRadius: spacing(0.5),
      borderBottomRightRadius: spacing(0.5)
    },
    paddingBottom: spacing(2),
    position: 'relative'
  },
  title: {
    marginTop: spacing(0.5),
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
    fontSize: spacing(1.75),
    lineHeight: `${spacing(2.4)}px`,
    height: spacing(5),
    textAlign: 'center'
  }
}));

const Alarm = ({ config, className }: Props) => {
  const styles = useStyles();
  const { data: alarmData, isLoading: isLoadingAlarmData } = useAlarmData(config.feature.name);
  const { data: unit, isLoading: isLoadingUnit } = useUnit(config.feature.name);
  const isLoading = isLoadingAlarmData || isLoadingUnit;

  return (
    <li className={classNames(styles.root, className)}>
      <h3 className={styles.title}>{config.feature.label.default}</h3>
      <Chart config={config}>{!isLoading && <Bar config={config} data={alarmData} />}</Chart>
      <Signpost config={config} data={alarmData}>
        {!isLoading && <AlarmValue data={alarmData} unit={unit} />}
      </Signpost>
    </li>
  );
};

export default Alarm;
