import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { ArrowDropDown } from '@mui/icons-material';
import { EzerTheme } from '../../EzerThemeProvider';
import MenuButton from './MenuButton';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClick: () => void;
  className?: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    paddingRight: spacing(4.25),
    position: 'relative',
    backgroundColor: palette.carbon,
    color: palette.white,
    lineHeight: `${spacing(3)}px`,
    '&:hover': {
      backgroundColor: palette.white,
      color: palette.carbon,
      boxShadow: 'none'
    }
  },
  open: {
    backgroundColor: palette.white,
    color: palette.carbon
  },
  icon: {
    position: 'absolute',
    top: spacing(0.5),
    right: spacing(1.25)
  },
  openIcon: {
    transform: 'rotate(180deg)'
  }
}));
const ToggleButton = ({ children, isOpen, onClick, className }: Props) => {
  const styles = useStyles();
  return (
    <MenuButton onClick={onClick} className={classNames(styles.root, { [styles.open]: isOpen }, className)}>
      {children} <ArrowDropDown className={classNames(styles.icon, { [styles.openIcon]: isOpen })} />
    </MenuButton>
  );
};

export default ToggleButton;
