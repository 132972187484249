import { addHours, addMinutes, isAfter } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import useNow from '../../../useNow';
import { useFeatureFlags, useFulfillmentRecorder } from '../../../utils';
import useRecommendationDataFeatureConfigs from '../../../utils/useRecommendationDataFeatureConfigs';
import { useTemplateOptions } from '../TemplateOptionsContext';
import { useActiveInferenceRecommendation } from '../utils';
import CurrentRecommendationSection from './CurrentRecommendationSection/CurrentRecommendationSection';
import LoadingContent from './lib/LoadingContent';
import MessageContent from './MessageContent';
import useHealthcheck from './useHealthcheck';
import useHeartbeat from './useHeartbeat';
import useClientStatus, { ClientStatus } from './utils/useClientStatus';

const RecommendationContent = () => {
  const { data, isLoading } = useActiveInferenceRecommendation();
  const now = useNow();
  const { data: clientStatus } = useClientStatus();
  const featureFlags = useFeatureFlags();
  const ignorePlantStability = featureFlags.includes('ignorePlantStability');
  useHeartbeat();
  useHealthcheck();
  const recommendationDataFeatureConfigs = useRecommendationDataFeatureConfigs();
  const { recommendationLifespan } = useTemplateOptions();

  const { setRecommendationMetric } = useFulfillmentRecorder();
  const setFulfillment = (isFulfilled: boolean) => {
    recommendationDataFeatureConfigs.forEach((featureConfig) => {
      setRecommendationMetric({
        feature: featureConfig.feature.name,
        label: featureConfig.feature.label.default,
        isFulfilled,
        value: 0
      });
    });
  };

  if (isLoading) {
    setFulfillment(true);
    return <LoadingContent dataTestId="recommendation-loading-content" />;
  }
  if (!ignorePlantStability && clientStatus === ClientStatus.apiDown) {
    setFulfillment(true);
    return (
      <MessageContent>
        <FormattedMessage
          id="page.today.recommendations.badPlantState"
          defaultMessage="Sorry, recommended plant values are not available at this time."
        />
      </MessageContent>
    );
  }
  if (!ignorePlantStability && clientStatus === ClientStatus.shutdown) {
    setFulfillment(true);
    return (
      <MessageContent>
        <FormattedMessage id="page.today.recommendations.shutdown" defaultMessage="Plant is in shutdown" />
      </MessageContent>
    );
  }
  if (!ignorePlantStability && clientStatus === ClientStatus.lightUp) {
    setFulfillment(true);
    return (
      <MessageContent>
        <FormattedMessage id="page.today.recommendations.lightUp" defaultMessage="Kiln is lighting up" />
      </MessageContent>
    );
  }

  if (!ignorePlantStability && clientStatus === ClientStatus.unknown) {
    setFulfillment(true);
    return (
      <MessageContent>
        <FormattedMessage
          id="page.today.recommendations.plantStateUnknown"
          defaultMessage="Plant state cannot be determined"
        />
      </MessageContent>
    );
  }

  if (!ignorePlantStability && clientStatus === ClientStatus.warmingUp) {
    setFulfillment(true);
    return (
      <MessageContent>
        <FormattedMessage id="page.today.recommendations.warmingUp" defaultMessage="Kiln is warming up" />
      </MessageContent>
    );
  }

  if (!ignorePlantStability && clientStatus === ClientStatus.coolingDown) {
    setFulfillment(true);
    return (
      <MessageContent>
        <FormattedMessage id="page.today.recommendations.coolingDown" defaultMessage="Kiln is cooling down" />
      </MessageContent>
    );
  }

  if (!ignorePlantStability && clientStatus === ClientStatus.gapsInFeed) {
    setFulfillment(true);
    return (
      <MessageContent>
        <FormattedMessage
          id="page.today.recommendations.gapsInFeed"
          defaultMessage="Kiln feed is experiencing stoppage"
        />
      </MessageContent>
    );
  }

  // No recommendation available
  if (data === null) {
    setFulfillment(false);
    return (
      <MessageContent>
        <FormattedMessage id="page.today.recommendations.unavailable" defaultMessage="No recommendation available" />
      </MessageContent>
    );
  }

  // No recent recommendation available

  const recommendationCreatedAt = data?.timestamp ?? false;

  const getRecommendationExpiration = (createdAt: Date) => {
    if (recommendationLifespan === undefined) {
      return addHours(createdAt, 5);
    }
    return addMinutes(createdAt, recommendationLifespan);
  };

  if (recommendationCreatedAt && isAfter(now, getRecommendationExpiration(recommendationCreatedAt))) {
    setFulfillment(false);
    return (
      <MessageContent>
        <FormattedMessage id="page.today.recommendations.stale" defaultMessage="No recent recommendations available." />
      </MessageContent>
    );
  }

  // Everything is fine, display the current recommendation

  return <CurrentRecommendationSection recommendation={data} />;
};

export default RecommendationContent;
