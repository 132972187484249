import getLocalisedMessage from './getLocalisedMessage';
import { Messages } from '../LocalisationProvider';

const LOCALE_MESSAGE_KEYS: Record<string, string> = {
  UNREVIEWED: 'buttons.unreviewed',
  ACCEPTED: 'buttons.accepted',
  REJECTED_PRODUCTION: 'buttons.rejectedProductionConcern',
  REJECTED_QUALITY: 'buttons.rejectedQualityConcern',
  REJECTED_SAFETY: 'buttons.rejectedSafetyConcern',
  REJECTED_TRIED: 'buttons.rejectedTriedAndReversed',
  REJECTED_OTHER: 'buttons.rejectedOther'
};
const slugToStatus: Record<string, string> = {
  UNREVIEWED: 'Unreviewed',
  ACCEPTED: 'Accepted',
  REJECTED_PRODUCTION: 'Rejected - Production Concern',
  REJECTED_QUALITY: 'Rejected - Quality Concern',
  REJECTED_SAFETY: 'Rejected - Safety Concern',
  REJECTED_TRIED: 'Rejected - Tried and Reversed',
  REJECTED_OTHER: 'Rejected - Other'
};

const getLocalisedRecommendationStatus = (messages: Messages, statusSlug: string) => {
  const messageKey = LOCALE_MESSAGE_KEYS[statusSlug];
  const defaultValue = slugToStatus[statusSlug];
  return getLocalisedMessage(messages, defaultValue, messageKey);
};

export default getLocalisedRecommendationStatus;
