import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import type { EzerTheme } from '../../EzerThemeProvider';

type Props = {
  children: ReactNode;
  value: string;
  exclusiveMode: boolean;
  disabled?: boolean;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    minWidth: spacing(7),
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    height: spacing(7),
    border: `1px solid ${palette.white}`,
    borderRadius: spacing(0.5),
    fontSize: spacing(2),
    color: palette.white,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: `${palette.blackShadow} 0 0 0 150px inset`
    }
  },
  active: {
    backgroundColor: palette.leaf,
    borderColor: palette.leaf
  },
  disabled: {
    opacity: 0.5
  }
}));

const ToggleButton = ({ children, value, disabled, exclusiveMode }: Props) => {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const toggleSearchParams = searchParams.get('toggles')?.split(',') || [];
  const isActive = !!toggleSearchParams.find((paramValue) => paramValue === value);

  const onClick = () => {
    if (isActive) {
      const index = toggleSearchParams.indexOf(value);
      toggleSearchParams.splice(index, 1);
    } else {
      toggleSearchParams.push(value);
    }
    if (toggleSearchParams.length !== 0) {
      searchParams.set('toggles', toggleSearchParams.join(','));
      setSearchParams(searchParams);
    }
  };
  const onClickExclusive = () => {
    if (!isActive) {
      searchParams.set('toggles', value);
      setSearchParams(searchParams);
    }
  };
  return (
    <button
      onClick={exclusiveMode ? onClickExclusive : onClick}
      className={classNames(styles.root, { [styles.active]: isActive }, { [styles.disabled]: disabled })}
      type="button"
      disabled={disabled}>
      {children}
    </button>
  );
};

export default ToggleButton;
