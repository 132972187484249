import { DataGrid, GridColumns, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import React from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';
import Number from './Number';
import getLocalisedMessage from '../utils/getLocalisedMessage';
import { useMessages } from '../LocalisationProvider';

type Props = {
  // need to specify any prop, as this is the one required by DataGrid
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  columns: GridColumns;
  columnBuffer?: number | undefined;
  rowBuffer?: number | undefined;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  },
  dataGrid: {
    backgroundColor: palette.white
  }
}));

const FromToOfCount = defineMessage({
  id: 'labels.fromToOfCount',
  defaultMessage: '{from} - {to} of {count}'
});

const DataTable = ({ data, columns, columnBuffer, rowBuffer }: Props) => {
  const styles = useStyles();
  const intl = useIntl();
  const messages = useMessages();

  const formattedColumns = columns.map((column) => ({
    ...column,
    renderCell: (params: GridRenderCellParams) =>
      typeof params.value === 'number' ? <Number>{params.value}</Number> : params.value
  }));
  return (
    <Box sx={{ height: 400, width: '100%' }} className={styles.root}>
      <DataGrid
        className={styles.dataGrid}
        rows={data}
        getRowId={(row) => row.timestamp || row.id}
        columns={formattedColumns}
        columnBuffer={columnBuffer || 3}
        rowBuffer={rowBuffer || 3}
        density="compact"
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        localeText={{
          toolbarColumns: getLocalisedMessage(messages, 'Columns', 'labels.columns'),
          toolbarFilters: getLocalisedMessage(messages, 'Filters', 'labels.filters'),
          toolbarDensity: getLocalisedMessage(messages, 'Density', 'labels.density'),
          toolbarExport: getLocalisedMessage(messages, 'Export', 'labels.export'),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => intl.formatMessage(FromToOfCount, { from, to, count })
          }
        }}
      />
    </Box>
  );
};

export default DataTable;
