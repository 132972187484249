import { subHours } from 'date-fns';
import useCurrentRecommendationQuery from '../../../serviceQueries/useCurrentRecommendationQuery';
import { FeatureName, Recommendation } from '../../../types';
import useNow from '../../../useNow';
import useRecommendationDataFeatureConfigs from '../../../utils/useRecommendationDataFeatureConfigs';
import { useTemplateOptions } from '../TemplateOptionsContext';

type Response = {
  data: Recommendation | null;
  isLoading: boolean;
  refetch: () => unknown;
};

const useCurrentRecommendation = (): Response => {
  const { predictionDataFeatureConfigs } = useTemplateOptions();
  const recommendationDataFeatureConfigs = useRecommendationDataFeatureConfigs();

  const now = useNow();
  const startDate = subHours(now, 3);

  const {
    data: recommendations = [],
    refetch,
    isLoading
  } = useCurrentRecommendationQuery(startDate, now, { refetch: true });
  if (isLoading) {
    return {
      data: null,
      isLoading,
      refetch
    };
  }

  const currentRecommendation: Recommendation | undefined = recommendations.length > 0 ? recommendations[0] : undefined;
  if (!currentRecommendation) {
    return {
      data: null,
      isLoading,
      refetch
    };
  }

  const recommendationFeatureNames = recommendationDataFeatureConfigs.map(
    (featureConfig) => featureConfig.feature.name
  );
  const predictionFeatureNames = predictionDataFeatureConfigs.map((featureConfig) => featureConfig.feature.name);
  const validatedRecommendation: Recommendation = {
    ...currentRecommendation,
    params: currentRecommendation.params.filter((param) =>
      recommendationFeatureNames.includes(param.name as FeatureName)
    ),
    predictions: currentRecommendation.predictions.filter((prediction) =>
      predictionFeatureNames.includes(prediction.name as FeatureName)
    )
  };

  const hasValidPredictions = validatedRecommendation.predictions.length > 0;
  const hasValidParams = validatedRecommendation.params.length > 0;
  if (!hasValidParams && !hasValidPredictions) {
    return {
      data: null,
      isLoading,
      refetch
    };
  }

  return {
    data: validatedRecommendation,
    isLoading,
    refetch
  };
};

export default useCurrentRecommendation;
