{
  "item": {
    "g_pc_gol_o2": {
      "value": 3.945878,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "g_pc_gol_co": {
      "value": 1818.0058115909098,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "g_k_gol_co": {
      "value": 29.253728962209305,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "p_k_torque": {
      "value": 342.5564613529412,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "s_c_sol_fcao": {
      "value": 2.328125,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T10:30:00Z"
    },
    "s_pc_sol_doc": {
      "value": 94.91,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T09:00:00Z"
    },
    "f_pc_burner2_tput": {
      "value": 7.465540151000001,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "f_pc_burner1_tput": {
      "value": 0.6331597360555554,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "s_ph_sil_tput": {
      "value": 107.06029430000001,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "p_ph_gol_fan_speed": {
      "value": 789.38567,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "f_k_tput": {
      "value": 2.5842955890000003,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "p_shc": {
      "value": 790.1734244285717,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "g_pc_gol_temp": {
      "value": 867.7052466000002,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "g_k_gol_o2": {
      "value": 5.47716,
      "type": "sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "s_c_sol_fcao_soft": {
      "value": 2.1864696300940474,
      "type": "soft-sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    },
    "s_pc_sol_doc_soft": {
      "value": 85.16720975412603,
      "type": "soft-sensor",
      "status": "ok",
      "timestamp": "2024-01-16T11:15:00Z"
    }
  },
  "metadata": {
    "g_pc_gol_o2": {
      "unit": "%"
    },
    "g_pc_gol_co": {
      "unit": "ppm"
    },
    "g_k_gol_co": {
      "unit": "mg/Nm3"
    },
    "p_k_torque": {
      "unit": "A"
    },
    "s_c_sol_fcao": {
      "unit": "%"
    },
    "s_pc_sol_doc": {
      "unit": "%"
    },
    "f_pc_burner2_tput": {
      "unit": "t/h"
    },
    "f_pc_burner1_tput": {
      "unit": "t/h"
    },
    "s_ph_sil_tput": {
      "unit": "t/h"
    },
    "p_ph_gol_fan_speed": {
      "unit": "rpm"
    },
    "f_k_tput": {
      "unit": "t/h"
    },
    "p_shc": {
      "unit": "kcal/kg"
    },
    "g_pc_gol_temp": {
      "unit": "C"
    },
    "g_k_gol_o2": {
      "unit": "%"
    },
    "s_c_sol_fcao_soft": {
      "unit": "%"
    },
    "s_pc_sol_doc_soft": {
      "unit": "%"
    }
  }
}
