import { useIntl } from 'react-intl';
import { Label } from '../types';

const useLocalisedLabel = (label: Label) => {
  const { formatMessage } = useIntl();
  if (label.id) {
    return formatMessage({ id: label.id, defaultMessage: label.default });
  }
  return label.default;
};

export default useLocalisedLabel;
