const fontStyles = {
  '@import': [
    // eslint-disable-next-line max-len
    "url('https://fonts.googleapis.com/css2?family=Mada:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')",
    // eslint-disable-next-line max-len
    "url('https://fonts.googleapis.com/css2?family=Teko:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')"
  ]
};

export default fontStyles;
