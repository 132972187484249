import useUserQuery from '../serviceQueries/useUserQuery';

type Plant = {
  plantRef: string;
  name: string;
};

type UserPermissions = {
  canEditConfig: boolean;
  plants: Plant[];
};

type Response = {
  data: UserPermissions | null;
  isLoading: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeUserPermissions = (data: any): UserPermissions => ({
  canEditConfig: data.permissions.can_edit_config,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plants: data.permissions.plants.map((plant: any) => ({
    plantRef: plant.plant_ref,
    name: plant.name
  }))
});

const useUserPermissions = (): Response => {
  const { isLoading, data: queryData = null } = useUserQuery();
  return {
    data: isLoading ? null : makeUserPermissions(queryData),
    isLoading
  };
};

export default useUserPermissions;
