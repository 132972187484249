import useAuthorisedQuery from './useAuthorisedQuery';
import { getCacheKeyDate, getUtcDate } from '../utils';

const ONE_MINUTE = 60000;

type Options = {
  refetch?: boolean;
};

const useCurrentRecommendationQuery = (startDate: Date, endDate: Date, options?: Options) => {
  const refetchInterval = options?.refetch ? ONE_MINUTE : false;
  const queryKey = refetchInterval
    ? ['recommendations', 'limit-1']
    : ['recommendations', getCacheKeyDate(startDate), getCacheKeyDate(endDate), 'limit-1'];
  return useAuthorisedQuery(
    queryKey,
    `recommendation/?start_datetime=${getUtcDate(startDate)}&end_datetime=${getUtcDate(endDate)}&limit=1`,
    {
      refetchInterval
    }
  );
};

export default useCurrentRecommendationQuery;
