import React from 'react';
import { PlantStatus } from './usePlantStatus';
import TimelineData from '../../lib/Timeline/TimelineData';
import * as msg from './Message';
import { LocalisedMessage } from '../../../../lib';

const useTimelineData = (data: PlantStatus[]): TimelineData[] => {
  const getColour = (status: string) => {
    if (['SHUTDOWN', 'WARMING_UP', 'COOLING_DOWN', 'LIGHT_UP', 'GAPS_IN_FEED'].includes(status)) {
      return 'red';
    }
    if (status === 'UNKNOWN') {
      return 'black';
    }
    if (status === 'REDUCED_THROUGHPUT') {
      return 'amber';
    }
    return 'green';
  };
  const getExplanation = (status: string) => {
    if (status === 'SHUTDOWN') {
      return <LocalisedMessage descriptor={msg.PlantStatusShutdown} />;
    }
    if (status === 'WARMING_UP') {
      return <LocalisedMessage descriptor={msg.PlantStatusWarmingUp} />;
    }
    if (status === 'COOLING_DOWN') {
      return <LocalisedMessage descriptor={msg.PlantStatusCoolingDown} />;
    }
    if (status === 'LIGHT_UP') {
      return <LocalisedMessage descriptor={msg.PlantStatusLightingUp} />;
    }
    if (status === 'GAPS_IN_FEED') {
      return <LocalisedMessage descriptor={msg.PlantStatusGapsInFeed} />;
    }
    if (status === 'REDUCED_THROUGHPUT') {
      return <LocalisedMessage descriptor={msg.PlantStatusReducedThroughput} />;
    }
    if (status === 'UNKNOWN') {
      return <LocalisedMessage descriptor={msg.PlantStatusUnknown} />;
    }
    return <LocalisedMessage descriptor={msg.PlantStatusOk} />;
  };
  return data.map(({ timestamp, status }) => ({
    timestamp,
    colour: getColour(status),
    explanation: getExplanation(status)
  }));
};

export default useTimelineData;
