import React from 'react';
import { PlantData } from '../../../../utils/useHistoricalPlantData';
import { SoftSensor } from '../../../../utils/useHistoricalSoftSensors';
import { useFulfillmentRecorder } from '../../../../utils';
import QualityChart from '../QualityChart';
import useNow from '../../../../useNow';
import { QualityChartConfig } from '../../schema';
import useNewCombinedQualityChartData from '../../../../utils/useNewCombinedQualityChartData';

type Props = {
  modelledData: SoftSensor[];
  measuredData: PlantData;
  startDate: Date;
  endDate: Date;
  config: QualityChartConfig;
  unit: string;
};

const FIFTEEN_MINUTES = 1000 * 60 * 15;
const ChartContainer = ({ modelledData, measuredData, startDate, endDate, config, unit }: Props) => {
  const chartData = useNewCombinedQualityChartData(
    modelledData,
    measuredData,
    config.modelled.name,
    config.measured.name
  );
  const { setPredictionMetric } = useFulfillmentRecorder();
  const now = useNow();
  setPredictionMetric({
    feature: config.modelled.name,
    label: config.label.default,
    isFulfilled: modelledData.length > 0 && now.getTime() - chartData[0].date <= FIFTEEN_MINUTES,
    value: chartData[0]?.predicted ?? 0
  });
  return <QualityChart data={chartData} startDate={startDate} endDate={endDate} config={config} unit={unit} />;
};

export default ChartContainer;
