[
  {
    "plant_ref": "can",
    "name": "\u00c7anakkale"
  },
  {
    "plant_ref": "cui",
    "name": "Cuiab\u00e1"
  },
  {
    "plant_ref": "demo",
    "name": "Demo Customer"
  },
  {
    "plant_ref": "mok",
    "name": "Mokr\u00e1"
  },
  {
    "plant_ref": "nie",
    "name": "Niebla"
  }
]
