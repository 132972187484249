import { subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import DatePicker from './DatePicker/DatePicker';
import { DatePickerMode, Unit } from './constants';

type Props = {
  mode: DatePickerMode;
  unit: Unit;
  range: number;
  disabled?: boolean;
};

const getStartOfRange = (date: Date, unit: Unit, range: number): Date => {
  if (unit === Unit.month) {
    return subMonths(date, range);
  }
  if (unit === Unit.year) {
    return subYears(date, range);
  }
  if (unit === Unit.week) {
    return subWeeks(date, range);
  }
  return subDays(date, range);
};

const EndDatePicker = ({ mode, unit, range, disabled = false }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDateParam = searchParams.get('startDate');
  const startDate = startDateParam ? new Date(startDateParam) : null;
  const endDateParam = searchParams.get('endDate');
  const endDate = endDateParam ? new Date(endDateParam) : null;
  const setEndDate = (date: Date) => {
    searchParams.set('endDate', date.toISOString());
    if (startDate) {
      const startOfRange = getStartOfRange(date, unit, range);
      if (startDate.getTime() < startOfRange.getTime()) searchParams.set('startDate', startOfRange.toISOString());
    }
    setSearchParams(searchParams);
  };
  return (
    <DatePicker
      key={endDate?.toISOString()}
      value={endDate}
      disabled={disabled || endDate === null}
      onChange={(date) => {
        if (date === null) return;
        setEndDate(date);
      }}
      label={<FormattedMessage id="labels.endDate" defaultMessage="End Date" />}
      mode={mode}
    />
  );
};

export default EndDatePicker;
