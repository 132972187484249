import { rest } from 'msw';
import { API_NADA_BASE_URL } from '../settings';
import {
  customer,
  customers,
  dailyAverage,
  heatBalance,
  heatBalanceHistorical,
  heatBalanceOverview,
  ingestJob,
  kpi,
  latestPlantData,
  plantData,
  plantStatus,
  recommendation,
  recommendationStatus,
  updateNotifications
} from './mockApi';

const handlers = [
  rest.get(`${API_NADA_BASE_URL}/customer/`, (req, res, ctx) => res(ctx.json(customer))),
  rest.get(`${API_NADA_BASE_URL}/customers/`, (req, res, ctx) => res(ctx.json(customers))),
  rest.get(`${API_NADA_BASE_URL}/plant-data/latest/`, (req, res, ctx) => res(ctx.json(latestPlantData))),
  rest.get(`${API_NADA_BASE_URL}/plant-data/`, (req, res, ctx) => res(ctx.json(plantData))),
  rest.get(`${API_NADA_BASE_URL}/plant-status/`, (req, res, ctx) => res(ctx.json(plantStatus))),
  rest.get(`${API_NADA_BASE_URL}/recommendation/`, (req, res, ctx) => res(ctx.json(recommendation))),
  rest.patch(`${API_NADA_BASE_URL}/recommendation/:id/`, (req, res, ctx) => res(ctx.json({}))),
  rest.get(`${API_NADA_BASE_URL}/recommendation-status/`, (req, res, ctx) => res(ctx.json(recommendationStatus))),
  rest.post(`${API_NADA_BASE_URL}/recommendation-feedback/`, (req, res, ctx) => res(ctx.json({}))),
  rest.get(`${API_NADA_BASE_URL}/daily-average/`, (req, res, ctx) => res(ctx.json(dailyAverage))),
  rest.get(`${API_NADA_BASE_URL}/heat-balance/`, (req, res, ctx) => res(ctx.json(heatBalance))),
  rest.get(`${API_NADA_BASE_URL}/heat-balance/overview/`, (req, res, ctx) => res(ctx.json(heatBalanceOverview))),
  rest.get(`${API_NADA_BASE_URL}/heat-balance/historical/`, (req, res, ctx) => res(ctx.json(heatBalanceHistorical))),
  rest.get(`${API_NADA_BASE_URL}/kpi/`, (req, res, ctx) => res(ctx.json(kpi))),
  rest.get(`${API_NADA_BASE_URL}/ingest-job/`, (req, res, ctx) => res(ctx.json(ingestJob))),
  rest.get(`${API_NADA_BASE_URL}/update-notifications/`, (req, res, ctx) => res(ctx.json(updateNotifications)))
];

export default handlers;
