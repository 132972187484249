[
  {
    "id": 15673155,
    "forDatetime": "2024-01-16T10:45:00Z",
    "params": [
      {
        "name": "g_pc_gol_temp_target",
        "value": 870.0,
        "unit": "degC"
      }
    ],
    "predictions": [
      {
        "name": "g_pc_gol_temp",
        "value": 869.01,
        "unit": "C"
      },
      {
        "name": "s_pc_sol_doc",
        "value": 86.42,
        "unit": "%"
      }
    ],
    "feedback": [],
    "status": "UNREVIEWED",
    "rating": 0,
    "is_enacted": false,
    "enacted_at": "2024-01-15T18:46:18.651Z"
  },
  {
    "id": 15673154,
    "forDatetime": "2024-01-16T08:45:00Z",
    "params": [
      {
        "name": "g_pc_gol_temp_target",
        "unit": "degC",
        "error": "PREDS_OUT_OF_BOUND"
      }
    ],
    "predictions": [],
    "feedback": [],
    "status": "UNREVIEWED",
    "rating": 0,
    "error": "PREDS_OUT_OF_BOUND"
  }
]
