import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from './EzerThemeProvider';

type Props = {
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ spacing, mediaQueries, maxContentWidth }: EzerTheme) => ({
  root: {
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    [mediaQueries.desktop]: {
      paddingRight: spacing(3),
      paddingLeft: spacing(3)
    }
  },
  container: {
    [mediaQueries.bigDesktop]: {
      maxWidth: maxContentWidth,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
}));

const PageContainer = ({ children }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default PageContainer;
