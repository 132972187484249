import useGetBearerToken from '../useGetBearerToken';
import request from './request';
import { Api } from '../settings';

type Options = {
  method?: 'POST' | 'PATCH';
  body?: string;
};

const useAuthorisedRequest = (api: Api) => {
  const getBearerToken = useGetBearerToken();
  return async (input: string, options?: Options) => {
    const token = await getBearerToken();
    return request(input, {
      ...options,
      headers: {
        authorization: [Api.serve, Api.inference].includes(api) ? token : `Bearer ${token}`
      }
    });
  };
};

export default useAuthorisedRequest;
