import useConfigQuery from '../serviceQueries/useConfigQuery';
import useFeatureFlags from '../utils/useFeatureFlags';
import type { ConfigType } from './ConfigSchema';

interface LoadingConfig {
  data: undefined;
  isLoading: true;
}

interface NoConfig {
  data: undefined;
  isLoading: false;
}

interface HasConfig {
  data: ConfigType;
  isLoading: false;
}

export const process = (config: ConfigType, featureFlags: string[]): ConfigType => ({
  ...config,
  pages: config.pages
    .filter((page) => {
      if (page.featureFlag) {
        return featureFlags.includes(page.featureFlag);
      }
      return true;
    })
    .map((page) => {
      if (page.type === 'category') {
        return {
          ...page,
          pages: page.pages.filter((subPage) => {
            if (subPage.featureFlag) {
              return featureFlags.includes(subPage.featureFlag);
            }
            return true;
          })
        };
      }
      return page;
    })
});

const useConfig = (): LoadingConfig | NoConfig | HasConfig => {
  const { data, isLoading } = useConfigQuery();
  const featureFlags = useFeatureFlags();

  if (isLoading) {
    return {
      data,
      isLoading
    };
  }

  if (!data) {
    return {
      data,
      isLoading: false
    };
  }

  if (!data.config.pages) {
    return {
      data: undefined,
      isLoading: false
    };
  }

  return {
    data: process(data.config, featureFlags),
    isLoading
  };
};

export default useConfig;
