import { useContext, createContext } from 'react';
import { TemplateSchema } from './schema';

export const TemplateOptionsContext = createContext({} as TemplateSchema['options']);

export const useTemplateOptions = (): TemplateSchema['options'] => {
  const context = useContext(TemplateOptionsContext);
  if (context === undefined) {
    throw new Error('useTemplateOptions must be used within a TemplateOptionsContext');
  }
  return context;
};
