import { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Mode, Unit } from './constants';

export type ButtonSetting = {
  label: string | ReactNode;
  value: number;
  unit: Unit;
};

const oneDay = <FormattedMessage id="buttons.filter.date.1day" defaultMessage="1d" />;
const twoDays = <FormattedMessage id="buttons.filter.date.2days" defaultMessage="2d" />;
const sevenDays = <FormattedMessage id="buttons.filter.date.30days" defaultMessage="7d" />;
const thirtyDays = <FormattedMessage id="buttons.filter.date.7days" defaultMessage="30d" />;

const oneWeek = <FormattedMessage id="buttons.filter.date.1week" defaultMessage="1w" />;

const oneMonth = <FormattedMessage id="buttons.filter.date.1month" defaultMessage="1m" />;
const threeMonths = <FormattedMessage id="buttons.filter.date.3months" defaultMessage="3m" />;
const sixMonths = <FormattedMessage id="buttons.filter.date.6months" defaultMessage="6m" />;
const nineMonths = <FormattedMessage id="buttons.filter.date.9months" defaultMessage="9m" />;
const oneYear = <FormattedMessage id="buttons.filter.date.12months" defaultMessage="1y" />;
const twoYears = <FormattedMessage id="buttons.filter.date.24months" defaultMessage="2y" />;

const BY_MONTH_BUTTON_SETTINGS: ButtonSetting[] = [
  {
    label: threeMonths,
    value: 3,
    unit: Unit.month
  },
  {
    label: sixMonths,
    value: 6,
    unit: Unit.month
  },
  {
    label: nineMonths,
    value: 9,
    unit: Unit.month
  },
  {
    label: oneYear,
    value: 1,
    unit: Unit.year
  }
];

const BY_DAY_BUTTON_SETTINGS: ButtonSetting[] = [
  {
    label: oneDay,
    value: 1,
    unit: Unit.day
  },
  {
    label: twoDays,
    value: 2,
    unit: Unit.day
  },
  {
    label: sevenDays,
    value: 7,
    unit: Unit.day
  },
  {
    label: thirtyDays,
    value: 30,
    unit: Unit.day
  }
];

const BY_WEEK_AND_MONTH_BUTTON_SETTINGS: ButtonSetting[] = [
  {
    label: oneWeek,
    value: 1,
    unit: Unit.week
  },
  {
    label: oneMonth,
    value: 1,
    unit: Unit.month
  },
  {
    label: threeMonths,
    value: 3,
    unit: Unit.month
  },
  {
    label: sixMonths,
    value: 6,
    unit: Unit.month
  }
];

const BY_MONTH_AND_YEAR_BUTTON_SETTINGS: ButtonSetting[] = [
  {
    label: threeMonths,
    value: 3,
    unit: Unit.month
  },
  {
    label: sixMonths,
    value: 6,
    unit: Unit.month
  },
  {
    label: oneYear,
    value: 1,
    unit: Unit.year
  },
  {
    label: twoYears,
    value: 2,
    unit: Unit.year
  }
];

const useButtonSettings = (mode: Mode): ButtonSetting[] =>
  useMemo(() => {
    if (mode === Mode.day) {
      return BY_DAY_BUTTON_SETTINGS;
    }
    if (mode === Mode.weekAndMonth) {
      return BY_WEEK_AND_MONTH_BUTTON_SETTINGS;
    }
    if (mode === Mode.monthAndYear) {
      return BY_MONTH_AND_YEAR_BUTTON_SETTINGS;
    }
    return BY_MONTH_BUTTON_SETTINGS;
  }, [mode]);

export default useButtonSettings;
