import { FormattedMessage } from 'react-intl';
import LegendKey from './Chart/ToggleLegend/LegendKey';

type Props = {
  legendKey: LegendKey;
  featureTitle: string;
  showUnit?: boolean;
};

const TimeInLow = () => <FormattedMessage defaultMessage="Time in Low" id="page.HistoricalPerformance.timeInLow" />;
const TimeInHigh = () => <FormattedMessage defaultMessage="Time in High" id="page.HistoricalPerformance.timeInHigh" />;

const TimeInOk = () => <FormattedMessage defaultMessage="Time in Ok" id="page.HistoricalPerformance.timeInOk" />;

const NumberLow = () => <FormattedMessage defaultMessage="No Low" id="page.HistoricalPerformance.lowCount" />;

const NumberHigh = () => <FormattedMessage defaultMessage="No High" id="page.HistoricalPerformance.highCount" />;

const AverageDurationHigh = () => (
  <FormattedMessage defaultMessage="Av. Duration High" id="page.HistoricalPerformance.highAverageDuration" />
);

const AverageDurationLow = () => (
  <FormattedMessage defaultMessage="Av. Duration Low" id="page.HistoricalPerformance.lowAverageDuration" />
);

const State = () => <FormattedMessage defaultMessage="state" id="page.HistoricalPerformance.state" />;

const FeatureDescription = ({ legendKey, featureTitle, showUnit = false }: Props) => {
  // TODO This isn't how languages work
  const percentageDescription = showUnit ? ' (%)' : '';
  const minDescription = showUnit ? ' (min)' : '';

  if (legendKey === LegendKey.LowPercentageTime) {
    return (
      <>
        <TimeInLow /> {featureTitle} <State />
        {percentageDescription}
      </>
    );
  }
  if (legendKey === LegendKey.HighPercentageTime) {
    return (
      <>
        <TimeInHigh /> {featureTitle} <State />
        {percentageDescription}
      </>
    );
  }
  if (legendKey === LegendKey.OkPercentageTime) {
    return (
      <>
        <TimeInOk /> {featureTitle} <State />
        {percentageDescription}
      </>
    );
  }
  if (legendKey === LegendKey.LowPeriodCount) {
    return (
      <>
        <NumberLow /> {featureTitle} Periods
      </>
    );
  }
  if (legendKey === LegendKey.HighPeriodCount) {
    return (
      <>
        <NumberHigh /> {featureTitle} Periods
      </>
    );
  }
  if (legendKey === LegendKey.AverageHighPeriodDuration) {
    return (
      <>
        <AverageDurationHigh /> {featureTitle}
        {minDescription}
      </>
    );
  }
  if (legendKey === LegendKey.AverageLowPeriodDuration) {
    return (
      <>
        <AverageDurationLow /> {featureTitle}
        {minDescription}
      </>
    );
  }
  return <>{featureTitle}</>;
};

export default FeatureDescription;
