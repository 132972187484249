import { useSearchParams } from 'react-router-dom';
import LegendKey from './LegendKey';

export const KEY = 'toggledLegendItems';

type LegendItems = LegendKey[];
const useToggledLegendItems = (): LegendItems => {
  const [searchParams] = useSearchParams();
  const value = searchParams?.get(KEY);
  if (value) {
    return value.split(',') as LegendItems;
  }
  return [LegendKey.OkPercentageTime];
};

export default useToggledLegendItems;
