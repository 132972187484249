import { round } from '../../../../../../utils';

const getYPosition = (max: number, min: number, value: number) => {
  if (!value || value <= min) {
    return 0;
  }
  if (value >= max) {
    return 100;
  }
  const totalHeight = max - min;
  const barHeight = value - min;
  return round((barHeight / totalHeight) * 100, 0);
};

export default getYPosition;
