{
  "name": "Niebla",
  "plant_ref": "nie",
  "show_current_soft_sensors": false,
  "timezone": "Europe/Madrid",
  "languages": ["es", "en"],
  "shift_length": 8,
  "shift_start_time": "00:00:00",
  "lab_data_feature_configs": [
    {
      "name": "s_c_sol_fcao_soft",
      "label_default": "Modelled Free lime",
      "label_es": "Cal libre estimada",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "s_pc_sol_doc_soft",
      "label_default": "Modelled Degree of Calcination",
      "label_es": "Grado de descarbonataci\u00f3n estimado",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "s_c_sol_fcao",
      "label_default": "Measured Free lime",
      "label_es": "Medici\u00f3n Cal libre",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "s_pc_sol_doc",
      "label_default": "Measured Degree of Calcination",
      "label_es": "Medici\u00f3n Grado de descarbonataci\u00f3n",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    }
  ],
  "sensor_data_feature_configs": [
    {
      "name": "p_shc",
      "label_default": "Specific heat consumption",
      "label_es": "Consumo calorifico",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "p_k_torque",
      "label_default": "Kiln torque",
      "label_es": "Intensidad horno",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "g_pc_gol_temp",
      "label_default": "Precalciner temperature",
      "label_es": "Precalcinador temperatura",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "g_k_gol_o2",
      "label_default": "Kiln inlet O2",
      "label_es": "O2 Culata",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "g_pc_gol_o2",
      "label_default": "Precalciner outlet O2",
      "label_es": "O2 Precalcinador",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "s_ph_sil_tput",
      "label_default": "Kiln feed",
      "label_es": "Alimentaci\u00f3n",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "p_ph_gol_fan_speed",
      "label_default": "ID Fan Speed",
      "label_es": "Velocidad vent de tiro",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "f_k_tput",
      "label_default": "MB fuel",
      "label_es": "Combustible quemador principal",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "f_pc_burner1_tput",
      "label_default": "PC fuel",
      "label_es": "Carbon precalcinador",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "f_pc_burner2_tput",
      "label_default": "PC biomass",
      "label_es": "Biomasa precalcinador",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "g_k_gol_co",
      "label_default": "Kiln inlet CO",
      "label_es": "CO Culata",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    },
    {
      "name": "g_pc_gol_co",
      "label_default": "Precalciner CO",
      "label_es": "CO Precalcinator",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    }
  ],
  "recommendation_data_feature_configs": [
    {
      "name": "g_pc_gol_temp_target",
      "label_default": "Precalciner temperature target",
      "label_es": "Precalcinador temperatura",
      "label_pt_br": "",
      "to": "",
      "feature_flag": "",
      "default_colour": "leaf"
    }
  ],
  "prediction_data_feature_configs": [],
  "recommendation_feature_configs": [
    {
      "name": "s_c_sol_fcao_soft",
      "send_to_customer": true
    }
  ],
  "pages": [
    {
      "path": "/",
      "label_default": "Today",
      "is_category": false,
      "label_es": "Hoy",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Today",
      "category": null,
      "feature_configs": [],
      "custom_values": [],
      "chart_configs": []
    },
    {
      "path": "/new-today-page",
      "label_default": "New Today Page",
      "is_category": false,
      "label_es": "",
      "label_pt_br": "",
      "feature_flag": "showNewTodayPage",
      "template": "NewToday",
      "category": null,
      "feature_configs": [],
      "custom_values": [],
      "chart_configs": []
    },
    {
      "path": "",
      "label_default": "Recommendations",
      "is_category": true,
      "label_es": "",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "",
      "category": null,
      "feature_configs": [],
      "custom_values": [],
      "chart_configs": []
    },
    {
      "path": "/historical-recommendations",
      "label_default": "Historical Recommendations",
      "is_category": false,
      "label_es": "Recomendaciones hist\u00f3ricas",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "HistoricalRecommendations",
      "category": "Recommendations",
      "feature_configs": [
        {
          "name": "g_pc_gol_temp_target",
          "label_default": "Precalciner temperature target",
          "label_es": "Precalcinador temperatura",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "Precalciner temperature target Chart - CUI",
          "feature_name": "g_pc_gol_temp_target",
          "chart_title_default": "Precalciner temperature target",
          "chart_title_es": "Precalcinador temperatura",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Precalciner temperature target (\u00b0C)",
          "y_axis_label_es": "Precalcinador temperatura (\u00b0C)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/adherence",
      "label_default": "Adherence",
      "is_category": false,
      "label_es": "Adherencia",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Adherence",
      "category": null,
      "feature_configs": [],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "Adherence Chart - NIE",
          "feature_name": "myFeatureName",
          "chart_title_default": "Adherence rate of recommendation reviews",
          "chart_title_es": "Porcentaje de adherencia de recomendaciones revisadas",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Adherence %",
          "y_axis_label_es": "Adherencia %",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "",
      "label_default": "Sensors",
      "is_category": true,
      "label_es": "Sensores",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "",
      "category": null,
      "feature_configs": [],
      "custom_values": [],
      "chart_configs": []
    },
    {
      "path": "/quality",
      "label_default": "Quality",
      "is_category": false,
      "label_es": "Calidad",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Quality",
      "category": "Sensors",
      "feature_configs": [
        {
          "name": "s_c_sol_fcao_soft",
          "label_default": "Modelled Free lime",
          "label_es": "Cal libre Estimada",
          "label_pt_br": ""
        },
        {
          "name": "s_c_sol_fcao",
          "label_default": "Measured Free lime",
          "label_es": "Medici\u00f3n Cal libre",
          "label_pt_br": ""
        },
        {
          "name": "s_pc_sol_doc_soft",
          "label_default": "Modelled Degree of Calcination",
          "label_es": "Grado de Descarbonataci\u00f3n Estimado",
          "label_pt_br": ""
        },
        {
          "name": "s_pc_sol_doc",
          "label_default": "Measured Degree of Calcination",
          "label_es": "Medici\u00f3n Grado de Descarbonataci\u00f3n",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "Measured Free lime Chart - NIE",
          "feature_name": "s_c_sol_fcao",
          "chart_title_default": "Measured Free lime",
          "chart_title_es": "Medici\u00f3n Cal libre",
          "chart_title_pt_br": "",
          "y_axis_label_default": "%",
          "y_axis_label_es": "%",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "Modelled Free lime Chart - NIE",
          "feature_name": "s_c_sol_fcao_soft",
          "chart_title_default": "Modelled Free lime",
          "chart_title_es": "Cal libre Estimada",
          "chart_title_pt_br": "",
          "y_axis_label_default": "%",
          "y_axis_label_es": "%",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "Measured Degree of Calcination Chart - NIE",
          "feature_name": "s_pc_sol_doc",
          "chart_title_default": "Measured Degree of Calcination",
          "chart_title_es": "Medici\u00f3n Grado de Descarbonataci\u00f3n",
          "chart_title_pt_br": "",
          "y_axis_label_default": "%",
          "y_axis_label_es": "%",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "Modelled Degree of Calcination Chart - NIE",
          "feature_name": "s_pc_sol_doc_soft",
          "chart_title_default": "Modelled Degree of Calcination",
          "chart_title_es": "Grado de Descarbonataci\u00f3n Estimado",
          "chart_title_pt_br": "",
          "y_axis_label_default": "%",
          "y_axis_label_es": "%",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/biomass-weighfeeder",
      "label_default": "Biomass Weighfeeder",
      "is_category": false,
      "label_es": "Pfister Combustibles",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Weighfeeder",
      "category": "Sensors",
      "feature_configs": [
        {
          "name": "f_pc_burner2_tput",
          "label_default": "PC Biomass",
          "label_es": "Caudal Pfister Combustibles",
          "label_pt_br": ""
        },
        {
          "name": "p_pc_burner2_pfister_speed",
          "label_default": "Pfister Speed",
          "label_es": "Velocidad Pfister Combustibles",
          "label_pt_br": ""
        },
        {
          "name": "f_pc_burner2_tput_err_calc",
          "label_default": "PC Biomass Calibration Error",
          "label_es": "Caudal Pfister Combustibles Error",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "Pfister Speed Chart - NIE",
          "feature_name": "p_pc_burner2_pfister_speed",
          "chart_title_default": "Pfister Speed",
          "chart_title_es": "Velocidad Pfister Combustibles",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Pfister Speed (rpm)",
          "y_axis_label_es": "Velocidad Pfister Combustibles (rpm)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "PC Biomass Chart WF - NIE",
          "feature_name": "f_pc_burner2_tput",
          "chart_title_default": "PC Biomass",
          "chart_title_es": "Caudal Pfister Combustibles",
          "chart_title_pt_br": "",
          "y_axis_label_default": "PC Biomass (t/h)",
          "y_axis_label_es": "Caudal Pfister Combustibles (t/h)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "PC Biomass Calibration Error Chart WF - NIE",
          "feature_name": "f_pc_burner2_tput_err_calc",
          "chart_title_default": "PC Biomass Calibration Error",
          "chart_title_es": "Caudal Pfister Combustibles Error",
          "chart_title_pt_br": "",
          "y_axis_label_default": "PC Biomass Calibration Error",
          "y_axis_label_es": "Caudal Pfister Combustibles Error",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/main-burner-weighfeeder",
      "label_default": "Weighfeeder",
      "is_category": false,
      "label_es": "Pfister Quemador",
      "label_pt_br": "",
      "feature_flag": "showWF",
      "template": "Weighfeeder",
      "category": "Sensors",
      "feature_configs": [
        {
          "name": "f_k_tput",
          "label_default": "MB fuel",
          "label_es": "Caudal Pfister Quemador",
          "label_pt_br": ""
        },
        {
          "name": "p_k_pfister_speed",
          "label_default": "MB fuel pfister speed",
          "label_es": "Velocidad Pfister Quemador",
          "label_pt_br": ""
        },
        {
          "name": "f_k_tput_err_calc",
          "label_default": "MB fuel calibration error",
          "label_es": "Error de Caudal Pfister Quemador",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "MB fuel Chart WF - CUI",
          "feature_name": "f_k_tput",
          "chart_title_default": "MB fuel",
          "chart_title_es": "Caudal Pfister Quemador",
          "chart_title_pt_br": "",
          "y_axis_label_default": "MB fuel (t/h)",
          "y_axis_label_es": "Caudal Pfister Quemador (t/h)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "MB fuel pfister speed Chart EF - CUI",
          "feature_name": "p_k_pfister_speed",
          "chart_title_default": "MB fuel pfister speed",
          "chart_title_es": "Velocidad Pfister Quemador",
          "chart_title_pt_br": "",
          "y_axis_label_default": "MB fuel pfister speed (rpm)",
          "y_axis_label_es": "Velocidad Pfister Quemador (rpm)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "MB fuel calibration error Chart WF - CUI",
          "feature_name": "f_k_tput_err_calc",
          "chart_title_default": "MB fuel calibration error",
          "chart_title_es": "Error de Caudal Pfister Quemador",
          "chart_title_pt_br": "",
          "y_axis_label_default": "MB fuel calibration error",
          "y_axis_label_es": "Error de Caudal Pfister Quemador",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/pc-fuel-weighfeeder",
      "label_default": "PC Fuel Weighfeeder",
      "is_category": false,
      "label_es": "",
      "label_pt_br": "",
      "feature_flag": "showWeighfeederPages",
      "template": "Weighfeeder",
      "category": "Sensors",
      "feature_configs": [
        {
          "name": "f_pc_burner1_tput",
          "label_default": "PC Fuel",
          "label_es": "",
          "label_pt_br": ""
        },
        {
          "name": "p_pc_burner1_pfister_speed",
          "label_default": "PC Fuel Pfister Speed",
          "label_es": "",
          "label_pt_br": ""
        },
        {
          "name": "f_pc_burner1_tput_err_calc",
          "label_default": "MB fuel calibration error",
          "label_es": "",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "PC Fuel Chart WF - NIE",
          "feature_name": "f_pc_burner1_tput",
          "chart_title_default": "PC Fuel",
          "chart_title_es": "",
          "chart_title_pt_br": "",
          "y_axis_label_default": "PC Fuel (t/h)",
          "y_axis_label_es": "",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "PC Fuel Pfister Speed Chart WF - NIE",
          "feature_name": "p_pc_burner1_pfister_speed",
          "chart_title_default": "PC Fuel Pfister Speed",
          "chart_title_es": "",
          "chart_title_pt_br": "",
          "y_axis_label_default": "PC Fuel Pfister Speed (rpm)",
          "y_axis_label_es": "",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "MB fuel calibration error Chart WF - NIE",
          "feature_name": "f_pc_burner1_tput_err_calc",
          "chart_title_default": "MB fuel calibration error",
          "chart_title_es": "",
          "chart_title_pt_br": "",
          "y_axis_label_default": "MB fuel calibration error",
          "y_axis_label_es": "",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/ammonia",
      "label_default": "Ammonia",
      "is_category": false,
      "label_es": "Amon\u00edaco",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Timeline",
      "category": "Sensors",
      "feature_configs": [
        {
          "name": "g_phstack_gol_nox",
          "label_default": "Main stack nitrous oxide (NOx)",
          "label_es": "NOx Chimenea",
          "label_pt_br": ""
        },
        {
          "name": "p_pc_ammonia_flowrate",
          "label_default": "Precalciner Ammonia Flow",
          "label_es": "Flujo Amon\u00edaco Precalcinador",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "Precalciner Ammonia Flow Chart - NIE",
          "feature_name": "p_pc_ammonia_flowrate",
          "chart_title_default": "Precalciner Ammonia Flow",
          "chart_title_es": "Flujo Amon\u00edaco Precalcinador",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Precalciner Ammonia Flow (l/h)",
          "y_axis_label_es": "Flujo Amon\u00edaco Precalcinador (l/h)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "Main stack nitric oxide (NOx) Chart - NIE",
          "feature_name": "g_phstack_gol_nox",
          "chart_title_default": "NOx - Main stack nitrous oxide",
          "chart_title_es": "NOx Chimenea",
          "chart_title_pt_br": "",
          "y_axis_label_default": "NOx - Main stack nitrous oxide (ppm)",
          "y_axis_label_es": "NOx Chimenea (ppm)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/gas-analyser",
      "label_default": "Gas Analyser",
      "is_category": false,
      "label_es": "Analizador de gases",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Timeline",
      "category": "Sensors",
      "feature_configs": [
        {
          "name": "g_k_gol_o2",
          "label_default": "Kiln inlet O2",
          "label_es": "O2 Culata",
          "label_pt_br": ""
        },
        {
          "name": "g_k_gol_co",
          "label_default": "Kiln inlet CO",
          "label_es": "CO Culata",
          "label_pt_br": ""
        },
        {
          "name": "g_pc_gol_o2",
          "label_default": "Precalciner O2",
          "label_es": "O2 Precalcinador",
          "label_pt_br": ""
        },
        {
          "name": "g_pc_gol_co",
          "label_default": "Precalciner CO",
          "label_es": "CO Precalcinador",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "Precalciner O2 Chart - NIE",
          "feature_name": "g_pc_gol_o2",
          "chart_title_default": "Precalciner O2",
          "chart_title_es": "O2 Precalcinador",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Precalciner O2 (%)",
          "y_axis_label_es": "O2 Precalcinador (%)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": 21.0,
          "number_of_ticks": null
        },
        {
          "name": "Precalciner CO Chart - NIE",
          "feature_name": "g_pc_gol_co",
          "chart_title_default": "Precalciner CO",
          "chart_title_es": "CO Precalcinador",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Precalciner CO (ppm)",
          "y_axis_label_es": "CO Precalcinador (ppm)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "Kiln inlet O2 Chart - NIE",
          "feature_name": "g_k_gol_o2",
          "chart_title_default": "Kiln inlet O2",
          "chart_title_es": "O2 Culata",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Kiln inlet O2 (%)",
          "y_axis_label_es": "O2 Culata (%)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": 21.0,
          "number_of_ticks": null
        },
        {
          "name": "Kiln inlet CO Chart - NIE",
          "feature_name": "g_k_gol_co",
          "chart_title_default": "Kiln inlet CO",
          "chart_title_es": "CO Culata",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Kiln inlet CO (ppm)",
          "y_axis_label_es": "CO Culata (ppm)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/raw-meal-quality",
      "label_default": "Raw Meal Quality",
      "is_category": false,
      "label_es": "Calidad harina",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Timeline",
      "category": "Sensors",
      "feature_configs": [
        {
          "name": "s_ph_sil_lsf",
          "label_default": "Raw Meal LSF",
          "label_es": "FSC Harina",
          "label_pt_br": ""
        },
        {
          "name": "s_ph_sil_sm",
          "label_default": "Raw Meal MS",
          "label_es": "MS Harina",
          "label_pt_br": ""
        },
        {
          "name": "s_ph_sil_am",
          "label_default": "Raw Meal MA",
          "label_es": "MA Harina",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "Raw Meal LSF Chart - NIE",
          "feature_name": "s_ph_sil_lsf",
          "chart_title_default": "Raw Meal LSF",
          "chart_title_es": "FSC Harina",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Raw Meal LSF (%)",
          "y_axis_label_es": "FSC Harina (%)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "Raw Meal MS Chart - NIE",
          "feature_name": "s_ph_sil_sm",
          "chart_title_default": "Raw Meal MS",
          "chart_title_es": "MS Harina",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Raw Meal MS",
          "y_axis_label_es": "MS Harina",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "Raw Meal MA Chart - NIE",
          "feature_name": "s_ph_sil_am",
          "chart_title_default": "Raw Meal MA",
          "chart_title_es": "MA Harina",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Raw Meal MA",
          "y_axis_label_es": "MA Harina",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/fuels",
      "label_default": "Fuels",
      "is_category": false,
      "label_es": "Combustibles",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Timeline",
      "category": "Sensors",
      "feature_configs": [
        {
          "name": "f_k_tput",
          "label_default": "MB Fuel",
          "label_es": "MB Combustible",
          "label_pt_br": ""
        },
        {
          "name": "f_pc_burner1_tput",
          "label_default": "PC Fuel",
          "label_es": "PC Combustible",
          "label_pt_br": ""
        },
        {
          "name": "f_pc_burner2_tput",
          "label_default": "PC Biomass",
          "label_es": "",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "MB Fuel Chart - NIE",
          "feature_name": "f_k_tput",
          "chart_title_default": "MB Fuel",
          "chart_title_es": "Combustible quemador principal",
          "chart_title_pt_br": "",
          "y_axis_label_default": "MB Fuel (t/h)",
          "y_axis_label_es": "Combustible quemador principal (t/h)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "PC Fuel Chart - NIE",
          "feature_name": "f_pc_burner1_tput",
          "chart_title_default": "PC Fuel",
          "chart_title_es": "Combustible precalcinador",
          "chart_title_pt_br": "",
          "y_axis_label_default": "PC Fuel (t/h)",
          "y_axis_label_es": "Combustible precalcinador (t/h)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": null,
          "number_of_ticks": null
        },
        {
          "name": "PC Biomass Chart - NIE",
          "feature_name": "f_pc_burner2_tput",
          "chart_title_default": "PC Biomass",
          "chart_title_es": "Precalcinador Biomas",
          "chart_title_pt_br": "",
          "y_axis_label_default": "PC Biomass (t/h)",
          "y_axis_label_es": "Precalcinador Biomas (t/h)",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": 0.0,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "",
      "label_default": "KPIs",
      "is_category": true,
      "label_es": "",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "",
      "category": null,
      "feature_configs": [],
      "custom_values": [],
      "chart_configs": []
    },
    {
      "path": "/specific-heat-consumption",
      "label_default": "Specific Heat Consumption",
      "is_category": false,
      "label_es": "",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "DailyAverageKpi",
      "category": "KPIs",
      "feature_configs": [
        {
          "name": "p_shc",
          "label_default": "SHC",
          "label_es": "",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "SHC Chart - NIE",
          "feature_name": "p_shc",
          "chart_title_default": "SHC",
          "chart_title_es": "",
          "chart_title_pt_br": "",
          "y_axis_label_default": "kcal/kg",
          "y_axis_label_es": "",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Date",
          "x_axis_label_es": "Fecha",
          "x_axis_label_pt_br": "Data",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    },
    {
      "path": "/free-lime",
      "label_default": "Free Lime",
      "is_category": false,
      "label_es": "Cal libre",
      "label_pt_br": "",
      "feature_flag": "",
      "template": "Freelime",
      "category": "KPIs",
      "feature_configs": [
        {
          "name": "s_c_sol_fcao",
          "label_default": "Free Lime",
          "label_es": "Cal libre",
          "label_pt_br": ""
        },
        {
          "name": "f_k_tput",
          "label_default": "MB Fuel",
          "label_es": "MB Combustible",
          "label_pt_br": ""
        }
      ],
      "custom_values": [],
      "chart_configs": [
        {
          "name": "Free Lime Chart - NIE",
          "feature_name": "s_c_sol_fcao",
          "chart_title_default": "Weekly Average Free Lime within bounds",
          "chart_title_es": "Promedio semanal de cal libre dentro de l\u00edmites",
          "chart_title_pt_br": "",
          "y_axis_label_default": "Free Lime (%)",
          "y_axis_label_es": "",
          "y_axis_label_pt_br": "",
          "x_axis_label_default": "Year - Week",
          "x_axis_label_es": "A\u00f1o - Semana",
          "x_axis_label_pt_br": "Ano - Semana",
          "range_start": null,
          "range_end": null,
          "number_of_ticks": null
        }
      ]
    }
  ]
}
