import { useContext } from 'react';
import { DatePickerState, DatePickerStateContext } from './DatePickerStateProvider';

const useDatePickerState = (): DatePickerState => {
  const context = useContext(DatePickerStateContext);
  if (context === undefined) {
    throw new Error('useDatePickerState must be used within a DatePickerStateProvider');
  }
  return context;
};

export default useDatePickerState;
