import React from 'react';
import { TooltipProps } from 'recharts';
import { FormattedMessage } from 'react-intl';
import { ChartTooltip } from '../../../../lib';
import { getLocalDateAndTime, useTimezone } from '../../../../utils';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import { GasAnalyserChartConfig } from '../../schema';

interface Props extends TooltipProps<string, string> {
  config: GasAnalyserChartConfig;
  leftUnit: string;
  rightUnit: string;
}

type DateLabelProps = {
  label: number;
};
const DateLabel = ({ label }: DateLabelProps) => {
  const timezone = useTimezone();
  const convertToTime = (timestamp: number) => (timestamp ? getLocalDateAndTime(new Date(timestamp), timezone) : label);
  return (
    <>
      <FormattedMessage
        defaultMessage="Date: {date}"
        id="page.Today.GasAnalyser.tooltip"
        values={{ date: convertToTime(label) }}
      />
    </>
  );
};

const TooltipContent = ({ label, payload = [], config, leftUnit, rightUnit }: Props) => {
  const { palette } = useEzerTheme();

  const getLabel = (color?: string) => {
    if (color === palette.white) {
      return config.rightFeatureConfig.feature.label.default;
    }
    if (color === palette.leaf) {
      return config.leftFeatureConfig.feature.label.default;
    }
    return color;
  };

  const getUnit = (color?: string) => {
    if (color === palette.white) {
      return rightUnit;
    }
    if (color === palette.leaf) {
      return leftUnit;
    }
    return '';
  };

  const itemListData = payload.map(({ value, color }) => ({
    label: `${getLabel(color)}: ${value} ${getUnit(color)}`,
    color
  }));

  return <ChartTooltip label={<DateLabel label={label} />} itemListData={itemListData} />;
};

export default TooltipContent;
