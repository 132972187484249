/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../EzerThemeProvider';

interface Props extends PickersDayProps<string> {
  selected?: boolean;
  isFirstDay?: boolean;
  isInBetween?: boolean;
  isLastDay?: boolean;
}

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    margin: '0 !important',
    width: `${spacing(5)}px !important`,
    borderRadius: `${spacing(0.5)}px !important`,
    '&:hover': {
      backgroundColor: `${palette.darkRich} !important`,
      color: palette.white
    }
  },
  today: {
    borderColor: '#eee !important',
    backgroundColor: '#eee !important'
  },
  selected: {
    borderColor: `${palette.leaf} !important`,
    backgroundColor: `${palette.leaf} !important`,
    color: `${palette.white} !important`
  },
  inBetweenDay: {
    borderRadius: '0 !important'
  },
  firstDay: {
    borderTopLeftRadius: `${spacing(0.5)}px !important`,
    borderBottomLeftRadius: `${spacing(0.5)}px !important`,
    borderTopRightRadius: '0 !important',
    borderBottomRightRadius: '0 !important'
  },
  lastDay: {
    borderTopRightRadius: `${spacing(0.5)}px !important`,
    borderBottomRightRadius: `${spacing(0.5)}px !important`,
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important'
  }
}));

const CustomPickersDay = (props: Props) => {
  const styles = useStyles();
  const { selected = false, isFirstDay = false, isInBetween = false, isLastDay = false, today, ...rest } = props;
  const className = classNames(
    styles.root,
    { [styles.today]: today },
    { [styles.selected]: selected },
    { [styles.firstDay]: isFirstDay },
    { [styles.inBetweenDay]: isInBetween },
    { [styles.lastDay]: isLastDay }
  );
  return <PickersDay {...rest} today={today} className={className} />;
};

export default CustomPickersDay;
