import { useMemo } from 'react';
import { useMessages } from '../../../LocalisationProvider';
import { getLocalDateAndTime, getLocalisedMessage, useTimezone } from '../../../utils';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import { FeatureName } from '../../../types';
import { Column } from '../../../utils/useHistoricalQualityColumnData';
import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';

type TableChartPayload = {
  id: number;
  [Column.Measured]: string | number;
  [Column.Predicted]: string | number;
  [Column.Date]?: string;
};

const useTableData = (modelledData: SoftSensor[], measuredData: PlantData, measured: FeatureName) => {
  const messages = useMessages();
  const naLabel = getLocalisedMessage(messages, 'N/A', 'labels.na');
  const timezone = useTimezone();
  const { items = [] } = measuredData;
  return useMemo(
    () =>
      items.map(
        ({ timestamp, data }): TableChartPayload => ({
          id: new Date(timestamp).getTime(),
          [Column.Predicted]:
            modelledData.find((datum) => datum.timestamp.getTime() === new Date(timestamp).getTime())?.value ?? naLabel,
          [Column.Measured]: data[measured]?.value ?? naLabel,
          [Column.Date]: timestamp ? getLocalDateAndTime(new Date(timestamp), timezone) : undefined
        })
      ),
    [modelledData, timezone, naLabel, items, measured]
  );
};

export default useTableData;
