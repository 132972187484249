import { subMonths } from 'date-fns';
import { LoadingMessage, PageTitle } from '../../lib';
import { DatePickerMode, Mode } from '../../FilterControls/constants';
import { FilterControls, useEndDate, useStartDate } from '../../FilterControls';
import { useKpiByWeekData } from '../../utils';
import useToday from '../../useToday';
import KpiChart from './KpiChart/KpiChart';
import { TemplateSchema } from './schema';

const DailyAverageKpiUnrankedPage = ({ label, options: { chartConfigs } }: TemplateSchema) => {
  const featureName = chartConfigs[0]?.featureName ?? '';
  const today = useToday();
  const startDate = useStartDate(subMonths(today, 3));
  const endDate = useEndDate(today);
  const { data, isLoading } = useKpiByWeekData(featureName, startDate, endDate);

  return (
    <>
      <PageTitle>{label.default}</PageTitle>
      <FilterControls isLoading={isLoading} mode={Mode.monthAndYear} datePickerMode={DatePickerMode.week} />
      {isLoading && <LoadingMessage />}
      {!isLoading && data && <KpiChart chartConfig={chartConfigs[0]} data={data} />}
    </>
  );
};

export default DailyAverageKpiUnrankedPage;
