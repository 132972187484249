import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

type Props = {
  children: ReactNode | string;
};

const useStyles = createUseStyles({
  root: {
    textIndent: -9999,
    whiteSpace: 'nowrap',
    display: 'inline-block'
  }
});

const HiddenText = ({ children }: Props) => {
  const styles = useStyles();
  return <span className={styles.root}>{children}</span>;
};

export default HiddenText;
