import React from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';

type Props = {
  children: React.ReactNode;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    textAlign: 'center',
    fontSize: spacing(3),
    marginTop: spacing(2)
  }
}));

const ChartTitle = ({ children }: Props) => {
  const styles = useStyles();
  return <h2 className={styles.root}>{children}</h2>;
};

export default ChartTitle;
