import { createUseStyles } from 'react-jss';
import { ReactNode } from 'react';
import { EzerTheme } from '../../../EzerThemeProvider';

type Props = {
  children: ReactNode;
};

const useStyles = createUseStyles(({ spacing, palette, fontWeight }: EzerTheme) => ({
  root: {
    fontSize: spacing(2),
    fontWeight: fontWeight.medium,
    textTransform: 'uppercase',
    color: palette.white,
    backgroundColor: palette.rich,
    padding: [spacing(1), spacing(2), spacing(0.5), spacing(2)],
    borderTopLeftRadius: spacing(0.5),
    borderTopRightRadius: spacing(0.5),
    display: 'inline-block',
    marginTop: spacing(2)
  }
}));

const SectionTitle = ({ children }: Props) => {
  const styles = useStyles();
  return <h2 className={styles.root}>{children}</h2>;
};

export default SectionTitle;
