import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { EzerTheme } from '../EzerThemeProvider';

type Props = {
  title: string | React.ReactNode;
  className?: string;
  unit?: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  label: {
    fontSize: spacing(2.5),
    color: palette.white,
    textAlign: 'center'
  }
}));

const AxisTitle = ({ title, className, unit = '' }: Props) => {
  const styles = useStyles();
  const unitDescription = unit === '' ? unit : `(${unit})`;
  return (
    <div className={classNames(styles.label, className)}>
      {title} {unitDescription}
    </div>
  );
};

export default AxisTitle;
