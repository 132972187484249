import type { Timezone } from '../Config/sharedSchemas';

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
const createLie = (timezone: Timezone, moment: Date) => {
  let iso = moment.toLocaleString('en-CA', { timeZone: timezone, hour12: false }).replace(', ', 'T');
  iso += `.${moment.getMilliseconds().toString().padStart(3, '0')}`;
  return new Date(`${iso}Z`);
};
const getUTCOffset = (timezone: Timezone, moment: Date) => {
  const lie = createLie(timezone, moment);
  return moment.getTime() - lie.getTime();
};

// returns the difference in ms between UTC and the timezone at the moment of date
const getTimezoneOffset = (timezone: Timezone, moment: Date) => {
  const offset = getUTCOffset(timezone, moment);
  const browserOffset = getUTCOffset(Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone, new Date());
  const totalOffset = offset - browserOffset;
  return totalOffset === -ONE_DAY_IN_MS ? 0 : totalOffset;
};

export default getTimezoneOffset;
