import { useEffect } from 'react';
import opentelemetry from '@opentelemetry/api';
import { isRealUser, useUserEmail } from '../utils';

const useHeartbeat = () => {
  const userEmail = useUserEmail();
  const trackUser = isRealUser(userEmail);
  const notify = () => opentelemetry.trace.getTracer('ezer').startSpan('todayPageHeartbeat').end();

  return useEffect(() => {
    const ONE_MINUTE = 60000;
    let interval = 0;
    if (trackUser) {
      notify();
      interval = window.setInterval(() => {
        notify();
      }, ONE_MINUTE);
    }
    return () => {
      clearInterval(interval);
    };
  }, [trackUser]);
};

export default useHeartbeat;
