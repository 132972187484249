import { createUseStyles } from 'react-jss';
import useConfig from '../Config/useConfig';
import { EzerTheme } from '../EzerThemeProvider';

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    display: 'inline-block',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    lineHeight: `${spacing(3)}px`
  }
}));

const CustomerName = () => {
  const styles = useStyles();
  const { data: config } = useConfig();
  return <span className={styles.root}>{config?.app.plantName || ''}</span>;
};

export default CustomerName;
