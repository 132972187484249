import { subHours } from 'date-fns';
import useLatestRecommendationsQuery from '../../../serviceQueries/useLatestRecommendationsQuery';
import { FeatureName } from '../../../types';
import useNow from '../../../useNow';
import useRecommendationDataFeatureConfigs from '../../../utils/useRecommendationDataFeatureConfigs';

type Parameter = {
  name: FeatureName;
  value: number | undefined;
  unit: string;
};

type Feedback = {
  createdAt: Date;
  text: string;
};

type Status =
  | 'UNREVIEWED'
  | 'ACCEPTED'
  | 'REJECTED_PRODUCTION'
  | 'REJECTED_QUALITY'
  | 'REJECTED_SAFETY'
  | 'REJECTED_TRIED'
  | 'REJECTED_OTHER';

export type Error =
  | 'INFERENCE_ERROR'
  | 'PREDS_OUT_OF_BOUND'
  | 'NANS_IN_INPUT'
  | 'NOT_ENOUGH_DATA'
  | 'BAD_DATA'
  | 'EO_OFF';

export type Recommendation = {
  id: number;
  forDatetime: Date;
  parameters: Parameter[];
  feedback: Feedback[];
  status: Status;
  statusUpdatedAt: undefined | Date;
  rating: number;
  error: undefined | Error;
};

type Response = {
  data: Recommendation[];
  isLoading: boolean;
  isFetching: boolean;
};

const buildRecommendation = (
  // eslint-disable-next-line
  datum: any,
  recommendationFeatureNames: FeatureName[]
): Recommendation => {
  const { params = [], feedback = [] } = datum;
  // eslint-disable-next-line
  const filteredParams = params.filter((param: any) => recommendationFeatureNames.includes(param.name));
  const getError = () => {
    if (datum.error) {
      return datum.error;
    }
    // eslint-disable-next-line
    const paramError = filteredParams.find((param: any) => !!param.error);
    if (paramError) {
      return paramError.error;
    }
    return undefined;
  };
  return {
    id: datum.id,
    forDatetime: new Date(datum.forDatetime),
    // eslint-disable-next-line
    parameters: filteredParams.map((param: any) => ({
      name: param.name,
      value: param.value,
      unit: param?.unit || ''
    })),
    // eslint-disable-next-line
    feedback: feedback.map((feedbackItem: any) => ({
      createdAt: new Date(feedbackItem.created_at),
      text: feedbackItem.text ?? ''
    })),
    status: datum.status as Status,
    statusUpdatedAt: datum.status_updated_at ? new Date(datum.status_updated_at) : undefined,
    rating: datum.rating ?? 0,
    error: getError()
  };
};

const useLatestRecommendations = (): Response => {
  const recommendationDataFeatureConfigs = useRecommendationDataFeatureConfigs();
  const now = useNow();
  const startDate = subHours(now, 6);
  const { data: recommendationsData = [], isLoading, isFetching } = useLatestRecommendationsQuery(startDate, now);
  if (isLoading) {
    return {
      data: [],
      isLoading,
      isFetching
    };
  }
  const recommendationFeatureNames = recommendationDataFeatureConfigs.map(
    (featureConfig) => featureConfig.feature.name
  );

  const recommendations = recommendationsData
    // eslint-disable-next-line
    .map((datum: any) => buildRecommendation(datum, recommendationFeatureNames))
    .filter(({ parameters }: Recommendation) => parameters.length !== 0);

  return {
    data: recommendations,
    isLoading,
    isFetching
  };
};

export default useLatestRecommendations;
