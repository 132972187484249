import { addHours, differenceInHours } from 'date-fns';

const TICK_COUNT = 7;

const useSevenTicks = (startDate: Date, endDate: Date): number[] => {
  const hoursDifference = differenceInHours(endDate, startDate);
  startDate.setMinutes(0, 0, 0);
  const result = [];
  const period = hoursDifference / (TICK_COUNT - 1);
  for (let i = 0; i < TICK_COUNT; i++) {
    const countingDate = addHours(startDate, period * i);
    result.push(countingDate.getTime());
  }
  return result;
};

export default useSevenTicks;
