import React from 'react';
import { FormattedMessage } from 'react-intl';
import TimelineData from '../../lib/Timeline/TimelineData';
import { DataStatus } from './useDataStatus';

const useTimelineData = (data: DataStatus[]): TimelineData[] => {
  const getColour = (status: DataStatus['status']) => {
    if (status === 'ERROR') {
      return 'red';
    }
    if (status === 'DEGRADED') {
      return 'amber';
    }
    return 'green';
  };
  const getExplanation = (status: DataStatus['status']) => {
    if (status === 'ERROR') {
      return <FormattedMessage id="page.today.ingest.offline" defaultMessage="Ingestion offline" />;
    }
    if (status === 'DEGRADED') {
      return <FormattedMessage id="page.today.ingest.problem" defaultMessage="Ingestion issues" />;
    }
    return <FormattedMessage id="page.today.ingest.ok" defaultMessage="Ingestion live" />;
  };
  return data.reverse().map(({ timestamp, status }) => ({
    timestamp,
    colour: getColour(status),
    explanation: getExplanation(status)
  }));
};

export default useTimelineData;
