import { useLocation } from 'react-router-dom';
import { ConfigType } from '../Config/ConfigSchema';
import useConfig from '../Config/useConfig';

export interface NavBarPage {
  isCurrentPage: boolean;
  isCategory: false;
  isSubNav: boolean;
  path: string;
  label: { default: string; id?: string };
}

export interface NavBarCategory {
  isCategory: true;
  isCurrentPage: boolean;
  label: { default: string; id?: string };
  subnavElements: NavBarPage[];
}

export type RootNavBarElement = NavBarPage | NavBarCategory;

type Response = {
  data: RootNavBarElement[];
  isLoading: boolean;
};

type Pages = ConfigType['pages'][number];
type Category = Extract<Pages, { type: 'category' }>;
type Page = Extract<Pages, { type: 'page' }>;

const toNavBarPage = (page: Page, pathname: string, isSubNav = false): NavBarPage => ({
  isCurrentPage: page.path === pathname,
  isCategory: false,
  isSubNav,
  label: page.label,
  path: page.path
});

const toNavBarCategory = (category: Category, pathname: string): NavBarCategory => {
  const subnavElements = category.pages.map((page) => toNavBarPage(page, pathname, true));
  return {
    isCategory: true,
    isCurrentPage: subnavElements.some((subPage) => subPage.isCurrentPage),
    label: category.label,
    subnavElements
  };
};

export const getNavBarElements = (pages: ConfigType['pages'], pathname: string): RootNavBarElement[] =>
  pages.map((page) => (page.type === 'page' ? toNavBarPage(page, pathname) : toNavBarCategory(page, pathname)));

const useNavBarElements = (): Response => {
  const { data: config, isLoading } = useConfig();
  const { pathname } = useLocation();

  if (isLoading) {
    return {
      data: [],
      isLoading
    };
  }

  if (!config) {
    return {
      data: [],
      isLoading: false
    };
  }
  const data = getNavBarElements(config.pages, pathname);

  return {
    data,
    isLoading
  };
};

export default useNavBarElements;
