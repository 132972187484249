import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../EzerThemeProvider';
import ToggleButton from './ToggleButton';

type Props = {
  children: React.ReactNode;
  items: React.ReactNode[];
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    position: 'relative'
  },
  menuList: {
    position: 'absolute',
    zIndex: 100,
    right: 0,
    top: spacing(5),
    display: 'none',
    minWidth: spacing(14),
    boxShadow: `0 ${spacing(0.5)}px ${spacing(0.25)}px ${spacing(0.25)}px ${palette.lightCarbon}`
  },
  open: {
    display: 'block'
  }
}));

const Menu = ({ children, items }: Props) => {
  const styles = useStyles();
  const [showMenu, setShowMenu] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = ({ target }: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [contentRef]);

  return (
    <div ref={contentRef} className={styles.root}>
      <ToggleButton isOpen={showMenu} onClick={() => setShowMenu(!showMenu)}>
        {children}
      </ToggleButton>
      <ul className={classNames(styles.menuList, { [styles.open]: showMenu })}>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
