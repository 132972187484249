/* eslint-disable @typescript-eslint/no-explicit-any */
import opentelemetry from '@opentelemetry/api';
import { useSearchParams } from 'react-router-dom';
import { flatten } from 'flat';
import React, { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import { FeatureName } from './types';

type Props = {
  children: React.ReactNode;
};

type ObservableMetric = {
  feature: FeatureName;
  label: string;
  isFulfilled: boolean;
  value: number;
};

type Recorder = {
  setRecommendationMetric: (newValue: ObservableMetric) => void;
  setPredictionMetric: (newValue: ObservableMetric) => void;
  setRecommendationTimestamp: (newValue: Date) => void;
};

const DEFAULT_RECORDER: Recorder = {
  setRecommendationMetric: () => undefined,
  setPredictionMetric: () => undefined,
  setRecommendationTimestamp: () => undefined
};

const ONE_MINUTE = 1000 * 60;
export const FulfillmentRecorderContext = createContext<Recorder>(DEFAULT_RECORDER);
const FulfillmentRecorderProvider = ({ children }: Props) => {
  const recommendationMetrics = useRef<ObservableMetric[]>([]);
  const predictionMetrics = useRef<ObservableMetric[]>([]);
  const recommendationsFulfilled = useRef(true);
  const predictionsFulfilled = useRef(true);
  const recommendationTimestamp = useRef<undefined | Date>(undefined);
  const [searchParams] = useSearchParams();
  const customer = searchParams.get('customer');

  const notify = useCallback(() => {
    const attributes = flatten({
      customer,
      recommendations: recommendationMetrics.current,
      predictions: predictionMetrics.current,
      recommendationsFulfilled: recommendationsFulfilled.current,
      predictionsFulfilled: predictionsFulfilled.current,
      recommendationAge:
        recommendationTimestamp.current === undefined
          ? 0
          : new Date().getTime() - recommendationTimestamp.current.getTime()
    }) as any;

    opentelemetry.trace.getTracer('ezer').startSpan('fulfillment', { attributes }).end();
  }, [customer]);

  useEffect(() => {
    notify();
    const interval = window.setInterval(() => {
      notify();
    }, ONE_MINUTE);
    return () => {
      clearInterval(interval);
    };
  }, [notify]);

  const recorder: Recorder = useMemo(
    () => ({
      setRecommendationMetric: (newValue) => {
        const targetMetric = recommendationMetrics.current.find((metric) => metric.feature === newValue.feature);
        if (targetMetric) {
          targetMetric.isFulfilled = newValue.isFulfilled;
        } else {
          recommendationMetrics.current = [...recommendationMetrics.current, newValue];
        }
        recommendationsFulfilled.current = !recommendationMetrics.current.find((metric) => !metric.isFulfilled);
      },
      setPredictionMetric: (newValue) => {
        const targetMetric = predictionMetrics.current.find((metric) => metric.feature === newValue.feature);
        if (targetMetric) {
          targetMetric.isFulfilled = newValue.isFulfilled;
        } else {
          predictionMetrics.current = [...predictionMetrics.current, newValue];
        }
        predictionsFulfilled.current = !predictionMetrics.current.find((metric) => !metric.isFulfilled);
      },
      setRecommendationTimestamp: (newValue) => {
        recommendationTimestamp.current = newValue;
      }
    }),
    []
  );

  return <FulfillmentRecorderContext.Provider value={recorder}>{children}</FulfillmentRecorderContext.Provider>;
};

export default FulfillmentRecorderProvider;
