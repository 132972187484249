import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

type Props = {
  descriptor: MessageDescriptor;
};

const LocalisedMessage = ({ descriptor }: Props) => (
  <FormattedMessage id={descriptor.id} defaultMessage={descriptor.defaultMessage} />
);

export default LocalisedMessage;
