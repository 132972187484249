import { createUseStyles } from 'react-jss';
import { AlarmData } from './utils/useAlarmData';
import { EzerTheme } from '../../../../../EzerThemeProvider';

type Props = {
  data: AlarmData;
  unit: string;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    lineHeight: `${spacing(2.5)}px`
  },
  value: {
    fontSize: spacing(2)
  },
  unit: {
    fontSize: spacing(1.5)
  }
}));
const AlarmValue = ({ data, unit }: Props) => {
  const styles = useStyles();
  const { value } = data;
  const decimalPlaces = value > 100 ? 0 : 2;

  return (
    <p className={styles.root}>
      <span className={styles.value}>{value.toFixed(decimalPlaces)} </span>
      <span className={styles.unit}>{unit}</span>
    </p>
  );
};

export default AlarmValue;
