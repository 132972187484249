import { addDays, addMonths, addWeeks, addYears } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { DatePickerMode, Unit } from './constants';
import DatePicker from './DatePicker/DatePicker';

type Props = {
  mode: DatePickerMode;
  range: number;
  unit: Unit;
  disabled?: boolean;
};

const getEndOfRange = (date: Date, unit: Unit, range: number): Date => {
  if (unit === Unit.month) {
    return addMonths(date, range);
  }
  if (unit === Unit.year) {
    return addYears(date, range);
  }
  if (unit === Unit.week) {
    return addWeeks(date, range);
  }
  return addDays(date, range);
};

const StartDatePicker = ({ mode, range, unit, disabled = false }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDateParam = searchParams.get('startDate');
  const startDate = startDateParam ? new Date(startDateParam) : null;
  const endDateParam = searchParams.get('endDate');
  const endDate = endDateParam ? new Date(endDateParam) : null;
  const setStartDate = (date: Date) => {
    searchParams.set('startDate', date.toISOString());
    if (endDate) {
      const endOfRange = getEndOfRange(date, unit, range);
      if (endDate.getTime() > endOfRange.getTime()) {
        searchParams.set('endDate', endOfRange.toISOString());
      }
    }
    setSearchParams(searchParams);
  };
  return (
    <DatePicker
      key={endDate?.toISOString()}
      value={startDate}
      disabled={disabled}
      onChange={(date) => {
        if (date === null) return;
        setStartDate(date);
      }}
      label={<FormattedMessage id="labels.startDate" defaultMessage="Start Date" />}
      mode={mode}
    />
  );
};

export default StartDatePicker;
