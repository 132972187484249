import { useMemo } from 'react';
import { AlarmConfig } from '../../../../schema';

const useBackgroundColour = (value: number, config: AlarmConfig): string =>
  useMemo(() => {
    if (config.type === 'MAX_MID_MIN') {
      if (value >= config.midBound) {
        return config.aboveMidColour;
      }
      return config.belowMidColour;
    }
    if (config.type === 'MAX_HIGH_LOW_MIN') {
      if (value >= config.highBound) {
        return config.aboveHighColour;
      }
      if (value <= config.lowBound) {
        return config.belowLowColour;
      }
      return config.inRangeColour;
    }
    return 'transparent';
  }, [value, config]);

export default useBackgroundColour;
