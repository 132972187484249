import PlantStatusTimeline from './PlantStatusTimeline/PlantStatusTimeline';
import Section from '../lib/Section';
import DataIngestionTimeline from './DataIngestionTimeline/DataIngestionTimeline';
import AlarmPanel from './AlarmPanel/AlarmPanel';

const PlantSection = () => (
  <Section>
    <PlantStatusTimeline />
    <DataIngestionTimeline />
    <AlarmPanel />
  </Section>
);
export default PlantSection;
