import { createContext, ReactNode, useState, useMemo } from 'react';

export type Value = Date | null;

type Props = {
  children: ReactNode;
  defaultValue: Value;
};

export type DatePickerState = {
  currentValue: Value;
  setCurrentValue: (newValue: Value) => void;
};

const DEFAULT_STATE = {
  currentValue: null,
  setCurrentValue: () => undefined
};

export const DatePickerStateContext = createContext<DatePickerState>(DEFAULT_STATE);

const DatePickerStateProvider = ({ children, defaultValue }: Props) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const value = useMemo(() => ({ currentValue, setCurrentValue }), [currentValue, setCurrentValue]);
  return <DatePickerStateContext.Provider value={value}>{children}</DatePickerStateContext.Provider>;
};
export default DatePickerStateProvider;
