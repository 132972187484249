import { Api } from '../settings';
import getUtcDate from '../utils/getUtcDate';
import useAuthorisedQuery from './useAuthorisedQuery';

const ONE_MINUTE = 60000;

const useLatestDataStatusQuery = (startDate: Date, endDate: Date) => {
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  return useAuthorisedQuery(
    ['latest-data-status', 'limit-1'],
    `data-status/?start_datetime=${startDateValue}&end_datetime=${endDateValue}&limit=1`,
    {
      refetchInterval: ONE_MINUTE
    },
    Api.serve
  );
};

export default useLatestDataStatusQuery;
