import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckIcon from '@mui/icons-material/Check';
import { Direction } from './usePlantParameters';
import { EzerTheme } from '../../../../../EzerThemeProvider';

type Props = {
  direction: Direction;
  className: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    display: 'inline-block',
    height: spacing(5),
    width: spacing(5)
  },
  noChange: {
    backgroundColor: palette.leaf
  },
  change: {
    backgroundColor: palette.flame
  },
  icon: {
    marginTop: spacing(0.9),
    marginLeft: spacing(1)
  },
  arrowUp: {
    transform: 'rotate(-0.25turn)'
  },
  arrowDown: {
    transform: 'rotate(0.25turn)'
  }
}));

const DirectionIcon = ({ direction, className }: Props) => {
  const styles = useStyles();
  const change = [Direction.increase, Direction.decrease].includes(direction);
  const noChange = direction === Direction.nochange;
  return (
    <span className={classNames(styles.root, { [styles.change]: change, [styles.noChange]: noChange }, className)}>
      {change && (
        <ArrowRightAltIcon
          className={classNames(
            styles.icon,
            {
              [styles.arrowUp]: direction === Direction.increase
            },
            {
              [styles.arrowDown]: direction === Direction.decrease
            }
          )}
        />
      )}
      {noChange && <CheckIcon className={styles.icon} />}
    </span>
  );
};

export default DirectionIcon;
