import { useSearchParams } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import opentelemetry from '@opentelemetry/api';
import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useState } from 'react';
import AuthenticatingPage from './AuthenticatingPage';
import UnauthorisedPage from './UnauthorisedPage';
import { AUTH0_AUDIENCE } from '../settings';

type Props = {
  children: React.ReactNode;
};

export const GetBearerTokenContext = createContext<() => Promise<string>>(async () => '');
GetBearerTokenContext.displayName = 'GetBearerTokenContext';

export interface Auth0Token {
  email: string;
  permissions: string[];
}

type TokenStatuses = 'authenticating' | 'wrongKiln' | 'ok';

const GetBearerTokenProvider = ({ children }: Props) => {
  const [tokenStatus, setTokenStatus] = useState<TokenStatuses>('authenticating');
  const { getAccessTokenSilently, logout } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const setCustomerSearchParam = (customer: string) => {
      const url = new URL(window.location.href);
      url.searchParams.set('customer', customer);
      setSearchParams(url.searchParams);
    };

    (async () => {
      const openTeleSpan = opentelemetry.trace.getTracer('ezer').startSpan('getAccessToken');
      const token = await getAccessTokenSilently({ audience: AUTH0_AUDIENCE });
      openTeleSpan.setAttributes({
        request: 'succeeded'
      });
      openTeleSpan.end();

      const decodedToken = jwtDecode<Auth0Token>(token);
      const tokenCustomers = (decodedToken.permissions ?? [])
        .filter((p) => p.startsWith('v2:customer:'))
        .map((p) => p.split(':')[2]);
      // <--- not tested
      if (tokenCustomers.length === 0) {
        logout();
        return;
      }
      // --->

      const customerParam = searchParams.get('customer');

      if (!customerParam) {
        setCustomerSearchParam(tokenCustomers[0]);
        return;
      }

      const authorisedKiln = tokenCustomers.includes(customerParam);
      if (authorisedKiln) {
        setTokenStatus('ok');
        return;
      }

      setTokenStatus('wrongKiln');

      setTimeout(() => {
        setCustomerSearchParam(tokenCustomers[0]);
      }, 5_000);
    })();
  }, [logout, getAccessTokenSilently, searchParams, setSearchParams]);

  return (
    <GetBearerTokenContext.Provider value={getAccessTokenSilently}>
      {tokenStatus === 'authenticating' && <AuthenticatingPage />}
      {tokenStatus === 'wrongKiln' && <UnauthorisedPage />}
      {tokenStatus === 'ok' && children}
    </GetBearerTokenContext.Provider>
  );
};

export default withAuthenticationRequired(GetBearerTokenProvider, {
  onRedirecting: () => <AuthenticatingPage />
});
