// the cache key date is unique to the minute
const getCacheKeyDate = (date: Date) => {
  const formatAsTwoCharacters = (number: number) => `0${number}`.slice(-2);
  const year = date.getUTCFullYear();
  const month = formatAsTwoCharacters(date.getUTCMonth() + 1);
  const day = formatAsTwoCharacters(date.getUTCDate());
  const hours = formatAsTwoCharacters(date.getUTCHours());
  const minutes = formatAsTwoCharacters(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export default getCacheKeyDate;
