import React from 'react';
import { TooltipProps } from 'recharts';
import { FormattedMessage } from 'react-intl';
import { ChartTooltip } from '../../../../lib';
import { getLocalDateAndTime, useTimezone } from '../../../../utils';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import { Feature } from '../../schema';

type Props = TooltipProps<string, string> & {
  leftFeature: Feature;
  rightFeature: Feature;
  leftUnit: string;
  rightUnit: string;
};

type TooltipLabelProps = {
  children: number;
};
const TooltipLabel = ({ children }: TooltipLabelProps) => {
  const timezone = useTimezone();
  const convertToTime = (timestamp: number) => (timestamp ? getLocalDateAndTime(new Date(timestamp), timezone) : '');
  return (
    <>
      <FormattedMessage
        defaultMessage="Date: {date}"
        id="page.HistoricalGasAnalyser.chart.tooltip"
        values={{ date: convertToTime(children) }}
      />
    </>
  );
};

const TooltipContent = ({ label, payload = [], leftFeature, rightFeature, leftUnit, rightUnit }: Props) => {
  const { palette } = useEzerTheme();

  const getLabel = (color?: string) => {
    if (color === palette.white) {
      return rightFeature.title;
    }
    if (color === palette.leaf) {
      return leftFeature.title;
    }
    return color;
  };

  const getUnit = (color?: string) => {
    if (color === palette.white) {
      return rightUnit;
    }
    if (color === palette.leaf) {
      return leftUnit;
    }
    return '';
  };

  const itemListData = payload.map(({ value, color }) => ({
    label: `${getLabel(color)}: ${value} ${getUnit(color)}`,
    color
  }));

  return <ChartTooltip label={<TooltipLabel>{label}</TooltipLabel>} itemListData={itemListData} />;
};

export default TooltipContent;
