import { addHours, subHours } from 'date-fns';
import { LoadingMessage, PageTitle } from '../../lib';
import { useHistoricalPlantData, useHistoricalSoftSensors } from '../../utils';
import useNow from '../../useNow';
import FilterControls from '../../FilterControls/FilterControls';
import ChartContainer from './ChartContainer';
import Table from './Table/Table';
import useStartDate from '../../FilterControls/useStartDate';
import useEndDate from '../../FilterControls/useEndDate';
import { TemplateSchema } from './schema';

const HistoricalQualityGatewayPage = ({ label, options: { chartConfigs } }: TemplateSchema) => {
  const now = useNow();
  const startDate = useStartDate(subHours(now, 24));
  const endDate = useEndDate(addHours(now, 6));

  const modelledFeatureNames = chartConfigs.length > 0 ? [chartConfigs[0].featureName] : [];

  const { data: modelledData, isLoading: isLoadingModelledData } = useHistoricalSoftSensors(
    modelledFeatureNames,
    startDate,
    endDate
  );

  const measuredFeatureNames = chartConfigs.length > 1 ? [chartConfigs[1].featureName] : [];

  const { data: measuredData, isLoading: isLoadingMeasuredData } = useHistoricalPlantData(
    measuredFeatureNames,
    startDate,
    endDate
  );

  const isLoading = isLoadingModelledData || isLoadingMeasuredData;

  return (
    <>
      <PageTitle>{label.default}</PageTitle>
      <FilterControls isLoading={isLoading} showFutureWindow />
      {isLoading && <LoadingMessage />}
      {!isLoading && startDate && endDate && chartConfigs.length >= 2 && (
        <>
          <ChartContainer
            modelledData={modelledData}
            measuredData={measuredData}
            modelled={chartConfigs[0].featureName}
            measured={chartConfigs[1].featureName}
            startDate={startDate}
            endDate={endDate}
            xAxisLabel={chartConfigs[0].xAxisLabel}
            yAxisLabel={chartConfigs[0].yAxisLabel}
            rangeStart={chartConfigs[0].rangeStart}
            rangeEnd={chartConfigs[0].rangeEnd}
          />
          <Table modelledData={modelledData} measuredData={measuredData} chartConfigs={chartConfigs} />
        </>
      )}
    </>
  );
};

export default HistoricalQualityGatewayPage;
