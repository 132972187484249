import { useContext, useMemo } from 'react';
import { FixedNowContext } from './FixedNowProvider';

const useToday = (): Date => {
  const realNow = useMemo(() => new Date(), []);
  const context = useContext(FixedNowContext);
  if (context === undefined) {
    throw new Error('useNow must be used within a FixedNowProvider');
  }
  return context === null ? realNow : context;
};

export default useToday;
