import useConfig from '../Config/useConfig';

export type ShiftData = {
  shiftLength: number;
  shiftStartTime: string;
};

type Response = {
  data: ShiftData;
  isLoading: boolean;
};

const useShiftData = (): Response => {
  const { data: config, isLoading } = useConfig();
  return {
    data: {
      shiftLength: config?.app.shiftLength ?? 0,
      shiftStartTime: config?.app.shiftStartTime || '00:00:00'
    },
    isLoading
  };
};
export default useShiftData;
