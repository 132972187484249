import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { green, grey } from '@mui/material/colors';

type Props = {
  children: React.ReactNode;
};

const MuiThemeProvider = ({ children }: Props) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: green[900]
      },
      secondary: {
        main: grey[900]
      }
    }
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiThemeProvider;
