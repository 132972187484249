import { useMemo } from 'react';
import QualityChartData from '../types/QualityChartData';
import round from './round';

const TICK_COUNT = 5;

const findFirstMeasuredValue = (data: QualityChartData) => data.find((dataItem) => !!dataItem.measured)?.measured;
const findFirstPredictedValue = (data: QualityChartData) => data.find((dataItem) => !!dataItem.predicted)?.predicted;

const getFirstValue = (data: QualityChartData) =>
  Math.floor(findFirstMeasuredValue(data) || findFirstPredictedValue(data) || 0);

const roundHalf = (num: number) => Math.ceil(num * 2) / 2;

const generateLimitsFromData = (data: QualityChartData, rangeStart: number | undefined) => {
  const startValue = rangeStart === undefined ? getFirstValue(data) : rangeStart;
  return data.reduce(
    ([prevMinValue, prevMaxValue], { measured, predicted }) => {
      const minParams = [prevMinValue];
      const maxParams = [prevMaxValue];
      if (measured !== undefined) {
        minParams.push(measured);
        maxParams.push(measured);
      }
      if (predicted !== undefined) {
        minParams.push(predicted);
        maxParams.push(predicted);
      }

      const newMinValue = Math.floor(Math.min(...minParams));
      const newMaxValue = Math.ceil(Math.max(...maxParams));
      return [newMinValue, newMaxValue];
    },
    [startValue, startValue]
  );
};

const useQualityChartYTicks = (data: QualityChartData, rangeStart: undefined | number, rangeEnd: undefined | number) =>
  useMemo(() => {
    const limitsDefinedInConfig = rangeStart === undefined || rangeEnd === undefined;
    const [minValue, maxValue] = limitsDefinedInConfig
      ? generateLimitsFromData(data, rangeStart)
      : [rangeStart, rangeEnd];

    const stepSize = roundHalf((maxValue - minValue) / (TICK_COUNT - 1));
    const result = [];
    for (let i = 0; i < TICK_COUNT; i++) {
      result.push(round(minValue + stepSize * i, 1));
    }
    return result;
  }, [data, rangeStart, rangeEnd]);

export default useQualityChartYTicks;
