type Props = {
  children: string;
  className?: string;
};

const Unit = ({ children, className }: Props) => {
  const label = children === 'C' ? '°C' : children;
  return <span className={className}>{label}</span>;
};

export default Unit;
