import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { EzerTheme } from './EzerThemeProvider';

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  header: {
    marginTop: spacing(2),
    fontSize: spacing(10)
  },
  para: {
    marginTop: spacing(2),
    fontSize: spacing(5)
  }
}));

const NotFoundPage = () => {
  const styles = useStyles();
  return (
    <>
      <h1 className={styles.header}>
        <FormattedMessage defaultMessage="Page not found" id="page.NotFound.header" />
      </h1>
      <p className={styles.para}>
        <FormattedMessage defaultMessage="Please navigate away using the top menu." id="page.NotFound.advice" />
      </p>
    </>
  );
};

export default NotFoundPage;
