import { defineMessage } from 'react-intl';

export const PlantStatusShutdown = defineMessage({
  id: 'message.plantStatus.shutdown',
  defaultMessage: 'Plant is in shutdown'
});
export const PlantStatusWarmingUp = defineMessage({
  id: 'message.plantStatus.warmingUp',
  defaultMessage: 'Plant is warming up'
});
export const PlantStatusCoolingDown = defineMessage({
  id: 'message.plantStatus.coolingDown',
  defaultMessage: 'Plant is cooling down'
});
export const PlantStatusLightingUp = defineMessage({
  id: 'message.plantStatus.lightingUp',
  defaultMessage: 'Plant is lighting up'
});
export const PlantStatusGapsInFeed = defineMessage({
  id: 'message.plantStatus.gapsInFeed',
  defaultMessage: 'Plant has gaps in feed'
});
export const PlantStatusReducedThroughput = defineMessage({
  id: 'message.plantStatus.reducedThroughput',
  defaultMessage: 'Plant has reduced throughput'
});
export const PlantStatusUnknown = defineMessage({ id: 'message.plantStatus.unknown', defaultMessage: 'Unknown' });
export const PlantStatusOk = defineMessage({ id: 'message.plantStatus.ok', defaultMessage: 'Plant is okay' });
