/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Legend } from 'recharts';
import { useEzerTheme } from '../../EzerThemeProvider';
import { useLegendToggles } from '../../utils';
import { KEY } from '../../utils/useLegendToggles';
import LegendItem from './LegendItem';

export type PayloadItem = {
  id: string;
  value: React.ReactNode;
  type: 'square' | 'line';
  color: string;
  supportToggle: boolean;
};

type Props = {
  payload: PayloadItem[];
};

const LegendToggles = ({ payload }: Props) => {
  const { spacing } = useEzerTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const legendToggles = useLegendToggles();

  const onClick = ({ id }: any) => {
    if (legendToggles.includes(id)) {
      const index = legendToggles.indexOf(id);
      legendToggles.splice(index, 1);
    } else {
      legendToggles.push(id);
    }

    searchParams.set(KEY, legendToggles.join(','));
    setSearchParams(searchParams);
  };
  const formatter = (value: ReactNode, entry: any) => (
    <LegendItem id={entry.id} supportToggle={entry.supportToggle}>
      {entry.value}
    </LegendItem>
  );

  return <Legend verticalAlign="top" height={spacing(4)} onClick={onClick} formatter={formatter} payload={payload} />;
};

export default LegendToggles;
