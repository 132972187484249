import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';

type Props = {
  children: string | React.ReactNode;
  className?: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    fontSize: spacing(2),
    color: palette.white,
    textAlign: 'center'
  }
}));

const ChartBottomLabel = ({ children, className }: Props) => {
  const styles = useStyles();
  return <p className={classNames(styles.root, className)}>{children}</p>;
};

export default ChartBottomLabel;
