[
  {
    "date": "2022-12-06",
    "value": 987.65
  },
  {
    "date": "2022-12-05",
    "value": 987.65
  },
  {
    "date": "2022-12-04",
    "value": 987.65
  },
  {
    "date": "2022-12-03",
    "value": 987.65
  },
  {
    "date": "2022-12-02",
    "value": 987.65
  },
  {
    "date": "2022-12-01",
    "value": 987.65
  },
  {
    "date": "2022-11-30",
    "value": 987.65
  },
  {
    "date": "2022-11-29",
    "value": 987.65
  },
  {
    "date": "2022-11-28",
    "value": 987.65
  },
  {
    "date": "2022-11-27",
    "value": 987.65
  },
  {
    "date": "2022-11-26",
    "value": 987.65
  },
  {
    "date": "2022-11-25",
    "value": 1044.0
  },
  {
    "date": "2022-11-24",
    "value": 987.65
  },
  {
    "date": "2022-11-23",
    "value": 987.65
  },
  {
    "date": "2022-11-22",
    "value": 987.65
  },
  {
    "date": "2022-11-21",
    "value": 987.65
  },
  {
    "date": "2022-11-20",
    "value": 987.65
  },
  {
    "date": "2022-11-19",
    "value": 987.65
  },
  {
    "date": "2022-11-18",
    "value": 987.65
  },
  {
    "date": "2022-11-17",
    "value": 987.65
  },
  {
    "date": "2022-11-16",
    "value": 987.65
  },
  {
    "date": "2022-11-15",
    "value": 987.65
  },
  {
    "date": "2022-11-14",
    "value": 987.65
  },
  {
    "date": "2022-11-13",
    "value": 987.65
  },
  {
    "date": "2022-11-12",
    "value": 987.65
  },
  {
    "date": "2022-11-11",
    "value": 987.65
  },
  {
    "date": "2022-11-10",
    "value": 987.65
  },
  {
    "date": "2022-11-09",
    "value": 987.65
  },
  {
    "date": "2022-11-08",
    "value": 987.65
  },
  {
    "date": "2022-11-07",
    "value": 987.65
  },
  {
    "date": "2022-11-06",
    "value": 987.65
  }
]
