import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../EzerThemeProvider';

type Props = {
  children: string | ReactNode;
};

const useStyles = createUseStyles(({ spacing, palette, fontWeight }: EzerTheme) => ({
  root: {
    fontSize: spacing(3),
    marginTop: spacing(1),
    textAlign: 'center',
    fontWeight: fontWeight.medium,
    color: palette.white
  }
}));

const ChartTitle = ({ children }: Props) => {
  const styles = useStyles();
  return <h2 className={styles.root}>{children}</h2>;
};

export default ChartTitle;
