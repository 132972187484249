{
  "periods": [
    { "period": "2021-W10", "average_value": 1.64, "percent_outside_target": 44 },
    { "period": "2021-W11", "average_value": 1.93, "percent_outside_target": 48 },
    { "period": "2021-W12", "average_value": 1.88, "percent_outside_target": 58 },
    { "period": "2021-W13", "average_value": 2.09, "percent_outside_target": 61 },
    { "period": "2021-W14", "average_value": 1.99, "percent_outside_target": 64 },
    { "period": "2021-W15", "average_value": 1.63, "percent_outside_target": 39 },
    { "period": "2021-W16", "average_value": 2.11, "percent_outside_target": 75 },
    { "period": "2021-W17", "average_value": 2.35, "percent_outside_target": 94 },
    { "period": "2021-W18", "average_value": 2.49, "percent_outside_target": 100 },
    { "period": "2021-W19", "average_value": 2.34, "percent_outside_target": 88 },
    { "period": "2021-W20", "average_value": 1.78, "percent_outside_target": 42 },
    { "period": "2021-W21", "average_value": 2.0, "percent_outside_target": 74 },
    { "period": "2021-W22", "average_value": 1.93, "percent_outside_target": 60 },
    { "period": "2021-W23", "average_value": 1.96, "percent_outside_target": 59 },
    { "period": "2021-W24", "average_value": 2.03, "percent_outside_target": 70 },
    { "period": "2021-W25", "average_value": 2.1, "percent_outside_target": 78 },
    { "period": "2021-W26", "average_value": 2.17, "percent_outside_target": 79 },
    { "period": "2021-W27", "average_value": 1.51, "percent_outside_target": 30 },
    { "period": "2021-W28", "average_value": 1.95, "percent_outside_target": 80 },
    { "period": "2021-W29", "average_value": 1.98, "percent_outside_target": 72 },
    { "period": "2021-W30", "average_value": 1.84, "percent_outside_target": 58 },
    { "period": "2021-W31", "average_value": 1.31, "percent_outside_target": 12 },
    { "period": "2021-W32", "average_value": 1.56, "percent_outside_target": 25 },
    { "period": "2021-W33", "average_value": 1.39, "percent_outside_target": 15 },
    { "period": "2021-W34", "average_value": 1.24, "percent_outside_target": 15 },
    { "period": "2021-W35", "average_value": 1.06, "percent_outside_target": 8 },
    { "period": "2021-W36", "average_value": 0.79, "percent_outside_target": 0 },
    { "period": "2021-W37", "average_value": 0.67, "percent_outside_target": 0 },
    { "period": "2021-W38", "average_value": 1.09, "percent_outside_target": 0 },
    { "period": "2021-W39", "average_value": 1.09, "percent_outside_target": 0 },
    { "period": "2021-W40", "average_value": 1.09, "percent_outside_target": 0 },
    { "period": "2021-W41", "average_value": 1.12, "percent_outside_target": 2 },
    { "period": "2021-W42", "average_value": 1.61, "percent_outside_target": 21 },
    { "period": "2021-W43", "average_value": 1.53, "percent_outside_target": 31 },
    { "period": "2021-W44", "average_value": 2.16, "percent_outside_target": 74 },
    { "period": "2021-W45", "average_value": 1.45, "percent_outside_target": 22 },
    { "period": "2021-W46", "average_value": 1.59, "percent_outside_target": 0 },
    { "period": "2021-W47", "average_value": 1.53, "percent_outside_target": 2 },
    { "period": "2021-W48", "average_value": 1.6, "percent_outside_target": 24 },
    { "period": "2021-W49", "average_value": 1.56, "percent_outside_target": 35 },
    { "period": "2021-W50", "average_value": 1.58, "percent_outside_target": 27 },
    { "period": "2021-W51", "average_value": 1.5, "percent_outside_target": 2 },
    { "period": "2021-W52", "average_value": 1.62, "percent_outside_target": 0 },
    { "period": "2022-W01", "average_value": 1.62, "percent_outside_target": 0 },
    { "period": "2022-W02", "average_value": 1.42, "percent_outside_target": 2 },
    { "period": "2022-W03", "average_value": 1.26, "percent_outside_target": 6 },
    { "period": "2022-W04", "average_value": 1.06, "percent_outside_target": 1 },
    { "period": "2022-W05", "average_value": 1.09, "percent_outside_target": 5 },
    { "period": "2022-W06", "average_value": 1.03, "percent_outside_target": 0 },
    { "period": "2022-W07", "average_value": 1.06, "percent_outside_target": 0 },
    { "period": "2022-W08", "average_value": 1.05, "percent_outside_target": 0 },
    { "period": "2022-W09", "average_value": 0.98, "percent_outside_target": 0 },
    { "period": "2022-W10", "average_value": 0.79, "percent_outside_target": 0 },
    { "period": "2022-W11", "average_value": 0.79, "percent_outside_target": 0 },
    { "period": "2022-W12", "average_value": 0.79, "percent_outside_target": 0 },
    { "period": "2022-W13", "average_value": 0.81, "percent_outside_target": 0 },
    { "period": "2022-W14", "average_value": 0.95, "percent_outside_target": 0 },
    { "period": "2022-W15", "average_value": 1.19, "percent_outside_target": 2 },
    { "period": "2022-W16", "average_value": 1.32, "percent_outside_target": 7 },
    { "period": "2022-W17", "average_value": 1.27, "percent_outside_target": 7 },
    { "period": "2022-W18", "average_value": 1.69, "percent_outside_target": 45 },
    { "period": "2022-W19", "average_value": 1.64, "percent_outside_target": 30 },
    { "period": "2022-W20", "average_value": 1.26, "percent_outside_target": 8 },
    { "period": "2022-W21", "average_value": 1.08, "percent_outside_target": 0 },
    { "period": "2022-W22", "average_value": 1.16, "percent_outside_target": 0 },
    { "period": "2022-W23", "average_value": 1.24, "percent_outside_target": 2 },
    { "period": "2022-W24", "average_value": 1.13, "percent_outside_target": 2 },
    { "period": "2022-W25", "average_value": 1.05, "percent_outside_target": 0 },
    { "period": "2022-W26", "average_value": 1.21, "percent_outside_target": 5 },
    { "period": "2022-W27", "average_value": 1.35, "percent_outside_target": 5 },
    { "period": "2022-W28", "average_value": 1.3, "percent_outside_target": 2 },
    { "period": "2022-W29", "average_value": 1.29, "percent_outside_target": 12 },
    { "period": "2022-W30", "average_value": 1.39, "percent_outside_target": 10 },
    { "period": "2022-W31", "average_value": 1.26, "percent_outside_target": 0 },
    { "period": "2022-W32", "average_value": 1.24, "percent_outside_target": 7 },
    { "period": "2022-W33", "average_value": 1.41, "percent_outside_target": 5 },
    { "period": "2022-W34", "average_value": 1.38, "percent_outside_target": 14 },
    { "period": "2022-W35", "average_value": 1.55, "percent_outside_target": 13 },
    { "period": "2022-W36", "average_value": 1.4, "percent_outside_target": 10 },
    { "period": "2022-W37", "average_value": 1.35, "percent_outside_target": 11 },
    { "period": "2022-W38", "average_value": 2.14, "percent_outside_target": 93 },
    { "period": "2022-W39", "average_value": 2.02, "percent_outside_target": 75 },
    { "period": "2022-W40", "average_value": 1.95, "percent_outside_target": 87 },
    { "period": "2022-W41", "average_value": 1.98, "percent_outside_target": 100 },
    { "period": "2022-W42", "average_value": 1.86, "percent_outside_target": 56 },
    { "period": "2022-W43", "average_value": 2.04, "percent_outside_target": 68 },
    { "period": "2022-W44", "average_value": 1.66, "percent_outside_target": 38 },
    { "period": "2022-W45", "average_value": 2.1, "percent_outside_target": 72 },
    { "period": "2022-W46", "average_value": 1.99, "percent_outside_target": 71 },
    { "period": "2022-W47", "average_value": 1.17, "percent_outside_target": 0 },
    { "period": "2022-W48", "average_value": 1.69, "percent_outside_target": 35 },
    { "period": "2022-W49", "average_value": 1.34, "percent_outside_target": 19 },
    { "period": "2022-W50", "average_value": 2.17, "percent_outside_target": 59 },
    { "period": "2022-W51", "average_value": 1.63, "percent_outside_target": 34 },
    { "period": "2022-W52", "average_value": 2.11, "percent_outside_target": 63 },
    { "period": "2023-W01", "average_value": 0.7, "percent_outside_target": 0 },
    { "period": "2023-W02", "average_value": 1.23, "percent_outside_target": 0 },
    { "period": "2023-W03", "average_value": 1.31, "percent_outside_target": 12 },
    { "period": "2023-W04", "average_value": 1.66, "percent_outside_target": 39 },
    { "period": "2023-W05", "average_value": 2.27, "percent_outside_target": 81 },
    { "period": "2023-W06", "average_value": 1.63, "percent_outside_target": 39 },
    { "period": "2023-W07", "average_value": 1.78, "percent_outside_target": 41 },
    { "period": "2023-W08", "average_value": 1.16, "percent_outside_target": 0 }
  ],
  "targets": [{ "start_period": "2021-W10", "end_period": "2023-W08", "value": 1.8 }],
  "total_percent_outside_target": 29
}
