import { addDays, subDays } from 'date-fns';
import useToday from '../../useToday';
import { FilterControls, useEndDate, useStartDate } from '../../FilterControls';
import { useHistoricalFeedbacks, useHistoricalInferenceRecommendations, useHistoricalPlantData } from '../../utils';
import { LoadingMessage, PageTitle } from '../../lib';
import Chart from './Chart/Chart';
import Table from './Table/Table';
import { TemplateSchema } from './schema';

const HistoricalRecommendationsMultiAxisPage = ({ options, label }: TemplateSchema) => {
  const { recommendedFeatures, historicalFeatures } = options;
  const today = useToday();
  const startDate = useStartDate(subDays(today, 30));
  const endDate = useEndDate(addDays(today, 1));

  const recommendationFeatureNames = recommendedFeatures.map(({ featureName }) => featureName);

  const { isLoading: isLoadingRecommendationData, data: recommendationData } = useHistoricalInferenceRecommendations(
    recommendationFeatureNames,
    startDate,
    endDate
  );
  const plantDataFeatureNames = historicalFeatures.map(({ featureName }) => featureName);

  const { data: plantData, isLoading: isLoadingPlantData } = useHistoricalPlantData(
    plantDataFeatureNames,
    startDate,
    endDate
  );

  const isLoading = isLoadingRecommendationData || isLoadingPlantData;

  const { data: feedbackData, isLoading: isLoadingFeedbackData } = useHistoricalFeedbacks(startDate, endDate);

  return (
    <>
      <PageTitle>{label.default}</PageTitle>
      <FilterControls isLoading={isLoading} />
      {isLoading && <LoadingMessage />}
      {!isLoading && recommendedFeatures.length >= 1 && historicalFeatures.length >= 3 && (
        <Chart
          options={options}
          recommendationData={recommendationData}
          plantData={plantData}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {!isLoading && !isLoadingFeedbackData && (
        <Table
          plantData={plantData}
          recommendationData={recommendationData}
          feedbackData={feedbackData}
          features={[...recommendedFeatures, ...historicalFeatures]}
        />
      )}
    </>
  );
};

export default HistoricalRecommendationsMultiAxisPage;
