import opentelemetry from '@opentelemetry/api';
import React, { ReactNode } from 'react';
import ErrorBoundary from './ErrorBoundary';

type Props = {
  children: ReactNode;
};

const ErrorHandler = ({ children }: Props) => {
  const onError = () => {
    opentelemetry.trace.getTracer('ezer').startSpan('errorBoundaryDisplayed').end();
  };
  return <ErrorBoundary onError={onError}>{children}</ErrorBoundary>;
};

export default ErrorHandler;
