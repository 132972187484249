import { PlantData } from '../../../utils/useHistoricalPlantData';
import useColumnData from './useColumnData';
import useTableData from './useTableData';
import { DataTable } from '../../../lib';
import { Feature } from '../schema';

type Props = {
  leftFeature: Feature;
  rightFeature: Feature;
  data: PlantData;
};

const Table = ({ leftFeature, rightFeature, data }: Props) => {
  const columnData = useColumnData(leftFeature, rightFeature);
  const tableData = useTableData(leftFeature, rightFeature, data);
  return <DataTable data={tableData} columns={columnData} />;
};

export default Table;
