import React from 'react';
import { DataTable } from '../../../lib';
import useColumns from './useColumns';
import useRows from './useRows';
import type { PlantData } from '../../../utils/useHistoricalPlantData';
import type { InferenceRecommendation } from '../../../types/InferenceRecommendation';
import { Feedback } from '../../../utils/useHistoricalFeedbacks';
import type { Feature } from '../schema';

type Props = {
  recommendationData: InferenceRecommendation[];
  plantData: PlantData;
  feedbackData: Feedback[];
  features: Feature[];
};

const Table = ({ recommendationData, plantData, feedbackData, features }: Props) => {
  const columns = useColumns(features);
  const rows = useRows(features, recommendationData, plantData, feedbackData);
  return (
    <div data-testid="historical-recommendations-table">
      <DataTable columns={columns} data={rows} />
    </div>
  );
};

export default Table;
