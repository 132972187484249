import { differenceInDays } from 'date-fns';

const useSampleRate = (startDate: Date, endDate: Date): number => {
  const difference = differenceInDays(endDate, startDate);
  if (difference <= 2) {
    return 2;
  }
  if (difference <= 7) {
    return 5;
  }
  return 10;
};

export default useSampleRate;
