import { createUseStyles } from 'react-jss';
import AnalyticsPageTracker from './AnalyticsPageTracker';
import AppRoutes from './AppRoutes';
import ErrorHandler from './ErrorHandler/ErrorHandler';
import EzerThemeProvider from './EzerThemeProvider';
import FixedNowProvider from './FixedNowProvider';
import GetBearerTokenProvider from './GetBearerTokenProvider/GetBearerTokenProvider';
import Header from './Header/Header';
import HoneyCombTracker from './HoneyCombPageTracker';
import LocalisationProviderSwitcher from './LocalisationProviderSwitcher';
import MuiThemeProvider from './MuiThemeProvider';
import PageContainer from './PageContainer';
import ReactQueryProvider from './ReactQueryProvider/ReactQueryProvider';
import fontStyles from './styles/fonts.styles';
import globalStyles from './styles/global.styles';
import rechartsStyles from './styles/recharts.styles';
import resetStyles from './styles/reset.styles';
import VersionTracker from './VersionTracker';

const useStyles = createUseStyles({
  '@global': {
    ...fontStyles,
    ...resetStyles,
    ...globalStyles,
    ...rechartsStyles
  }
});

function App() {
  useStyles();
  return (
    <AnalyticsPageTracker>
      <EzerThemeProvider>
        <MuiThemeProvider>
          <GetBearerTokenProvider>
            <ReactQueryProvider>
              <VersionTracker>
                <LocalisationProviderSwitcher>
                  <HoneyCombTracker>
                    <FixedNowProvider>
                      <Header />
                      <PageContainer>
                        <ErrorHandler>
                          <AppRoutes />
                        </ErrorHandler>
                      </PageContainer>
                    </FixedNowProvider>
                  </HoneyCombTracker>
                </LocalisationProviderSwitcher>
              </VersionTracker>
            </ReactQueryProvider>
          </GetBearerTokenProvider>
        </MuiThemeProvider>
      </EzerThemeProvider>
    </AnalyticsPageTracker>
  );
}

export default App;
