import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { Timezone } from '../../../../Config/sharedSchemas';
import { EzerTheme } from '../../../../EzerThemeProvider';
import { getLocalDateAndTime, useTimezone } from '../../../../utils';
import { useActiveInferenceRecommendation } from '../../utils';

const getDate = (date: Date, timezone: Timezone) => (date ? getLocalDateAndTime(date, timezone) : '');

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    display: 'inline-block',
    marginLeft: spacing(0.5),
    paddingLeft: spacing(1),
    paddingRight: spacing(1)
  }
}));

const RecommendationFor = () => {
  const { data } = useActiveInferenceRecommendation();
  const styles = useStyles();
  const timezone = useTimezone();
  return (
    <p className={styles.root}>
      {data?.timestamp && (
        <>
          <FormattedMessage
            id="page.today.recommendationForDate"
            defaultMessage="For {date}"
            values={{
              date: getDate(data.timestamp, timezone)
            }}
          />
        </>
      )}
    </p>
  );
};
export default RecommendationFor;
