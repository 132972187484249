import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { Bar, ComposedChart, ReferenceLine, Tooltip, XAxis, YAxis, ResponsiveContainer, Legend, Line } from 'recharts';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { CartesianGrid, LabelledContainer, LocalisedLabel } from '../../../lib';
import { getDateAsWeek, useLocalisedLabel, useUnit } from '../../../utils';
import { ChartData } from '../../../utils/useKpiByWeekData';
import TooltipContent from './TooltipContent';
import { ChartConfig } from '../schema';

type Props = {
  chartConfig: ChartConfig;
  data: ChartData;
};

const getInterval = (periods: unknown[]) => {
  if (periods.length > 100) {
    return 2;
  }
  if (periods.length > 25) {
    return 1;
  }
  return 0;
};

const getBarSize = (periods: unknown[]) => {
  const barCount = periods.length;
  const percentage = 100 / barCount;
  const offset = percentage / 6;
  return Math.round(percentage + offset);
};

const useStyles = createUseStyles(({ spacing, fontWeight }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  },
  title: {
    textAlign: 'center',
    fontSize: spacing(4),
    marginTop: spacing(2)
  },
  label: {
    fontSize: spacing(2.5),
    fontWeight: fontWeight.medium
  }
}));

const Baseline = () => <FormattedMessage defaultMessage="Baseline" id="labels.baseline" />;
const KpiChart = ({ chartConfig, data }: Props) => {
  const { palette, spacing } = useEzerTheme();
  const { periods = [] } = data;
  const { title, xAxisLabel, yAxisLabel, featureName } = chartConfig;
  const rangeStart = chartConfig?.rangeStart ?? 'auto';
  const rangeEnd = chartConfig?.rangeEnd ?? 'auto';
  const numberOfTicks = chartConfig?.numberOfTicks ?? 5;
  const localisedTitle = useLocalisedLabel(title);
  const tooltipLabel = useLocalisedLabel(yAxisLabel);
  const styles = useStyles();
  const { data: unit } = useUnit(featureName);
  const baseline = periods?.[0].baseline;

  return (
    <>
      <h2 className={styles.title}>{localisedTitle}</h2>
      <LabelledContainer xAxisLabel={xAxisLabel} yAxisLabel={yAxisLabel} unit={unit}>
        <ResponsiveContainer width="100%" height={spacing(84)} className={styles.root}>
          <ComposedChart data={periods} margin={{ top: 0, right: spacing(2), bottom: spacing(4), left: spacing(2) }}>
            <Legend
              verticalAlign="top"
              height={36}
              payload={[
                {
                  value: (
                    <>
                      <LocalisedLabel>{yAxisLabel}</LocalisedLabel> ({unit})
                    </>
                  ),
                  type: 'square',
                  color: palette.leaf
                },
                {
                  value: (
                    <>
                      <Baseline /> ({unit})
                    </>
                  ),
                  type: 'line',
                  color: palette.white
                }
              ]}
            />

            <CartesianGrid />
            <Bar dataKey="averageValue" barSize={spacing(getBarSize(periods))} fill={palette.leaf} />
            {baseline !== 0 && (
              <ReferenceLine y={baseline} stroke={palette.white} strokeDasharray="3 3" strokeWidth={2} />
            )}
            <Line
              dataKey="baseline"
              stroke={palette.white}
              strokeWidth={0}
              isAnimationActive={false}
              dot={false}
              activeDot={false}
            />
            <XAxis
              dataKey="date"
              allowDataOverflow
              tickFormatter={(tick: number) => getDateAsWeek(new Date(tick))}
              textAnchor="end"
              angle={-45}
              interval={getInterval(periods)}
            />
            <YAxis dataKey="averageValue" domain={[rangeStart, rangeEnd]} tickCount={numberOfTicks} allowDataOverflow />
            <Tooltip content={<TooltipContent featureLabel={tooltipLabel} unit={unit} />} />
          </ComposedChart>
        </ResponsiveContainer>
      </LabelledContainer>
    </>
  );
};
export default KpiChart;
