import { PlantData } from '../../../utils/useHistoricalPlantData';
import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import { useHistoricalQualityColumnData } from '../../../utils';
import useTableData from './useTableData';
import { DataTable } from '../../../lib';
import { ChartConfig } from '../schema';

type Props = {
  modelledData: SoftSensor[];
  measuredData: PlantData;
  chartConfigs: ChartConfig[];
};

const Table = ({ modelledData, measuredData, chartConfigs }: Props) => {
  const measured = chartConfigs[1].featureName;

  const columnData = useHistoricalQualityColumnData(chartConfigs);
  const tableData = useTableData(modelledData, measuredData, measured);

  return <DataTable data={tableData} columns={columnData} />;
};

export default Table;
