import { differenceInMinutes } from 'date-fns';
import useNow from '../../../useNow';
import useLatestRecommendations, { Recommendation } from './useLatestRecommendations';

type Response = {
  data: Recommendation | null;
  isLoading: boolean;
  isFetching: boolean;
};

const findActiveRecommendation = (now: Date, recommendations: Recommendation[]): Recommendation | null => {
  // no recommendations in response
  if (recommendations.length === 0) {
    return null;
  }
  // only one recommendation in the response
  if (recommendations.length === 1) {
    return recommendations[0];
  }

  // if the first one is over 10 minutes old, return the first recommendation
  if (differenceInMinutes(now, recommendations[0].forDatetime) > 10) {
    return recommendations[0];
  }

  // if the second one has been accepted, but not rated, return the second one
  if (recommendations[1].status === 'ACCEPTED' && recommendations[1].rating === 0) {
    return recommendations[1];
  }

  return recommendations[0];
};
const useActiveRecommendation = (): Response => {
  const { data, isLoading, isFetching } = useLatestRecommendations();
  const now = useNow();

  return {
    data: findActiveRecommendation(now, data),
    isLoading,
    isFetching
  };
};

export default useActiveRecommendation;
