import { Static, Type } from '@sinclair/typebox';
import { LabelSchema } from '../../Config/sharedSchemas';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import getTemplateSchema from '../getTemplateSchema';

const featureSchema = Type.Object(
  {
    title: LabelSchema({ title: 'Title', description: 'The title of the feature' }),
    rangeStart: Type.Optional(
      Type.Number({ title: 'Range Start', description: 'The start of the range on the Y axis' })
    ),
    rangeEnd: Type.Optional(Type.Number({ title: 'Range End', description: 'The end of the range on the Y axis' })),
    recommendedFeatureName: selectableEnum(FeatureName, {
      title: 'Recommended Feature Name',
      description: 'The name of the recommended feature'
    }),
    historicalFeatureName: selectableEnum(FeatureName, {
      title: 'Historical Feature Name',
      description: 'The name of the historical feature'
    })
  },
  { title: 'Feature', description: 'A feature to display on the Historical Recommendatins' }
);
export type Feature = Static<typeof featureSchema>;

const options = Type.Object(
  {
    features: Type.Array(featureSchema, {
      title: 'Features',
      description: 'The list of features to be shown in this page'
    })
  },
  { title: 'Options', description: 'Options for the Historical Recommendations template' }
);

export const templateSlug = 'HistoricalRecommendationsNewConfigPage';
export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Historical Recommendations',
  description: 'Historical Recommendations page for new config etc etc' // TODO improve description
});
export type TemplateSchema = Static<typeof templateSchema>;
export const templateUiSchema = {
  featureFlag: {
    'ui:options': {
      // placeholder to show how to set a field option
      disabled: false
    }
  }
};
