import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { Payload as LegendPayload } from 'recharts/types/component/DefaultLegendContent';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import { Feature } from '../../schema';

const High = () => <FormattedMessage defaultMessage="High" id="page.HistoricalGasAnalyser.legend.high" />;
const Low = () => <FormattedMessage defaultMessage="Low" id="page.HistoricalGasAnalyser.legend.low" />;
const useLegendPayload = (
  leftFeature: Feature,
  rightFeature: Feature,
  performanceFeature: Feature,
  leftUnit: string,
  rightUnit: string
): LegendPayload[] => {
  const { palette } = useEzerTheme();
  return [
    {
      value: (
        <>
          {leftFeature.title} ({leftUnit})
        </>
      ),
      type: 'line',
      color: palette.leaf
    },
    {
      value: (
        <>
          {rightFeature.title} ({rightUnit})
        </>
      ),
      type: 'line',
      color: palette.white
    },
    {
      value: (
        <>
          <High /> {performanceFeature.title}
        </>
      ),
      type: 'square',
      color: palette.glow
    },
    {
      value: (
        <>
          <Low /> {performanceFeature.title}
        </>
      ),
      type: 'square',
      color: palette.lava
    }
  ];
};
export default useLegendPayload;
