import { addDays, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import useTimezone from './useTimezone';
import getLocalDate from './getLocalDate';
import getTimezoneOffset from './getTimezoneOffset';

const filterTicks = (ticks: number[], maxTicks: number) => {
  const stepSize = Math.ceil(ticks.length / (maxTicks - 1));
  return ticks.filter((_, index) => index === 0 || index === ticks.length - 1 || index % stepSize === 0);
};

const useTicksByDate = (startDate: Date, endDate: Date, maxTicks?: number): number[] => {
  const timezone = useTimezone();
  const timezoneOffset = getTimezoneOffset(timezone, startDate);

  return useMemo(() => {
    const ticks = [];
    const firstDay = new Date(`${getLocalDate(startDate, timezone)} 00:00:00`);
    let countingDate = new Date(startOfDay(firstDay).getTime() + timezoneOffset);
    if (countingDate.getTime() === startDate.getTime()) {
      ticks.push(countingDate.getTime());
    }
    countingDate = addDays(countingDate, 1);
    while (countingDate.getTime() <= endDate.getTime()) {
      ticks.push(countingDate.getTime());
      countingDate = addDays(countingDate, 1);
    }
    return maxTicks && ticks.length > maxTicks ? filterTicks(ticks, maxTicks) : ticks;
  }, [timezone, startDate, endDate, maxTicks, timezoneOffset]);
};

export default useTicksByDate;
