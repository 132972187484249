import getCacheKeyDate from '../utils/getCacheKeyDate';
import getUtcDate from '../utils/getUtcDate';
import useAuthorisedQuery from './useAuthorisedQuery';
import { Api } from '../settings';

export const buildQueryKey = (startDateValue: string, endDateValue: string) => [
  'feedbacks',
  startDateValue,
  endDateValue
];

const useRecommendationsQuery = (startDate: Date, endDate: Date) => {
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  return useAuthorisedQuery(
    buildQueryKey(getCacheKeyDate(startDate), getCacheKeyDate(endDate)),
    `feedback/?start_time=${startDateValue}&end_time=${endDateValue}`,
    {},
    Api.serve
  );
};

export default useRecommendationsQuery;
