import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';
import LocalisedLabel from './LocalisedLabel';
import { Label } from '../types';

type Props = {
  children: ReactNode;
  xAxisLabel?: Label;
  yAxisLabel?: Label;
  dataTestId?: string;
  unit?: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    position: 'relative',
    paddingLeft: spacing(2)
  },
  label: {
    fontSize: spacing(2.5),
    color: palette.white,
    textAlign: 'center'
  },
  leftLabel: {
    transform: 'rotate(180deg)',
    top: 0,
    bottom: spacing(2.5),
    left: 0,
    position: 'absolute',
    writingMode: 'vertical-lr'
  }
}));

const LabelledContainer = ({ children, yAxisLabel, xAxisLabel, dataTestId, unit }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.root} data-testid={dataTestId}>
      {children}
      {yAxisLabel && (
        <div className={classNames(styles.leftLabel, styles.label)}>
          <LocalisedLabel>{yAxisLabel}</LocalisedLabel>
          {unit && <> ({unit})</>}
        </div>
      )}
      {xAxisLabel && (
        <div className={styles.label}>
          <LocalisedLabel>{xAxisLabel}</LocalisedLabel>
        </div>
      )}
    </div>
  );
};

export default LabelledContainer;
