import { FeatureName } from '../../../types';
import { useHistoricalPlantData, useUnit } from '../../../utils';
import useRecommendationDataFeatureConfig from '../../../utils/useRecommendationDataFeatureConfig';

type Payload = {
  value: number;
  date: number;
};

type ChartData = {
  items: Payload[];
  unit: string;
};

const useHistoricalFeatureName = (recommendedFeatureName: FeatureName): FeatureName => {
  const featureConfig = useRecommendationDataFeatureConfig(recommendedFeatureName);
  return featureConfig?.target?.name ?? recommendedFeatureName;
};

const useHistoricalChartData = (featureName: FeatureName, startDate: Date, endDate: Date): ChartData => {
  const historicalFeatureName = useHistoricalFeatureName(featureName);
  const {
    data: { items: plantDataItems = [] }
  } = useHistoricalPlantData([historicalFeatureName], startDate, endDate);
  const items = plantDataItems
    .filter((item) => !!item.data[historicalFeatureName])
    .map((item) => ({
      value: item.data[historicalFeatureName]?.value ?? 0,
      date: new Date(item.timestamp).getTime()
    }));
  const { data: unit = '' } = useUnit(historicalFeatureName);
  return {
    items,
    unit
  };
};

export default useHistoricalChartData;
