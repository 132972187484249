import React from 'react';
import type { Payload as LegendPayload } from 'recharts/types/component/DefaultLegendContent';
import {} from '../../../../lib';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import { GasAnalyserChartConfig } from '../../schema';

const useLegendPayload = (config: GasAnalyserChartConfig, leftUnit: string, rightUnit: string): LegendPayload[] => {
  const { palette } = useEzerTheme();
  return [
    {
      value: (
        <>
          {config.leftFeatureConfig.feature.label.default} ({leftUnit})
        </>
      ),
      type: 'line',
      color: palette.leaf
    },
    {
      value: (
        <>
          {config.rightFeatureConfig.feature.label.default} ({rightUnit})
        </>
      ),
      type: 'line',
      color: palette.white
    },
    {
      value: config.performanceFeatureConfig.highLabel.default,
      type: 'square',
      color: palette.glow
    },
    {
      value: config.performanceFeatureConfig.lowLabel.default,
      type: 'square',
      color: palette.lava
    }
  ];
};
export default useLegendPayload;
