import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_AUDIENCE } from './settings';

type Props = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      audience={AUTH0_AUDIENCE}
      redirectUri={window.location.origin}>
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
