import React from 'react';
import { TooltipProps } from 'recharts';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { getLocalDateAndTime, useTimezone } from '../../../utils';
import { EzerTheme } from '../../../EzerThemeProvider';
import * as msg from '../../../constants/Message';
import { LocalisedMessage } from '../../../lib';

interface Props extends TooltipProps<string, string> {
  unit: string;
}

const useStyles = createUseStyles(({ palette, fontWeight, spacing }: EzerTheme) => ({
  root: {
    backgroundColor: palette.rich,
    borderRadius: spacing(0.5),
    padding: [0, spacing(2), spacing(2), spacing(2)],
    overflow: 'hidden'
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  title: {
    color: 'white',
    fontSize: spacing(2),
    lineHeight: '1em',
    fontWeight: fontWeight.semiBold,
    padding: 0,
    marginTop: spacing(2)
  },
  item: {
    color: 'white',
    fontSize: spacing(2),
    marginTop: spacing(0.5),
    padding: 0,
    lineHeight: '1em'
  },
  recommendedItem: {
    color: palette.leaf
  }
}));

const TooltipContent = ({ label, payload = [], unit }: Props) => {
  // @ts-ignore
  const recommendedValue = payload.find((payloadItem) => payloadItem.stroke === '#8aad66')?.value as number;
  // @ts-ignore
  const measuredValue = payload.find((payloadItem) => payloadItem.stroke === '#fff')?.value as number;
  const styles = useStyles();
  const timezone = useTimezone();
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        <LocalisedMessage descriptor={msg.DateTime} />: {label ? getLocalDateAndTime(new Date(label), timezone) : ''}
      </h2>
      <ul className={styles.list}>
        {recommendedValue !== undefined && (
          <li className={classNames(styles.item, styles.recommendedItem)}>
            <LocalisedMessage descriptor={msg.Recommended} />: {recommendedValue.toFixed(2)} {unit}
          </li>
        )}
        {measuredValue !== undefined && (
          <li className={styles.item}>
            <LocalisedMessage descriptor={msg.Historical} />: {measuredValue.toFixed(2)} {unit}
          </li>
        )}
      </ul>
    </div>
  );
};

export default TooltipContent;
