import React from 'react';
import { createUseStyles } from 'react-jss';
import { Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { WeeklyPerformanceData } from '../useWeeklyPerformanceData';
import { AxisTitle, CartesianGrid, LocalisedMessage, VerticalTitle } from '../../../lib';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { getDateAsWeek, round, useToggles } from '../../../utils';
import ToggleLegend from './ToggleLegend/ToggleLegend';
import useLegendToggledItems from './ToggleLegend/useLegendToggledItems';
import LegendKey from './ToggleLegend/LegendKey';
import TooltipContent from './TooltipContent';
import { convertStringToKey } from '../utils';
import { Options } from '../schema';
import * as msg from '../../../constants/Message';

type Props = {
  options: Options;
  data: WeeklyPerformanceData;
  startDate: Date;
  endDate: Date;
};

const useStyles = createUseStyles(({ spacing, fontWeight }: EzerTheme) => ({
  root: {
    marginTop: spacing(2),
    paddingRight: spacing(2),
    position: 'relative'
  },
  leftLabel: {
    left: 0
  },
  rightLabel: {
    right: 0
  },
  container: {
    marginTop: spacing(2)
  },
  label: {
    fontSize: spacing(2.5),
    fontWeight: fontWeight.medium
  }
}));

const Chart = ({ options, data, startDate, endDate }: Props) => {
  const { toggleOptions, leftAxisTitle } = options;
  const { palette, spacing } = useEzerTheme();
  const styles = useStyles();
  const toggles = useToggles();
  const showIncidentCountValues = toggles.includes(convertStringToKey(toggleOptions[0]?.buttonText));
  const showAverageLengthValues = toggles.includes(convertStringToKey(toggleOptions[1]?.buttonText));
  const legendToggledItems = useLegendToggledItems();
  const rightAxisTitle = showIncidentCountValues ? toggleOptions[0].rightAxisTitle : toggleOptions[1].rightAxisTitle;
  return (
    <div className={styles.root} data-testid="weekly-performance-chart">
      <ResponsiveContainer width="100%" height={spacing(84)} className={styles.container}>
        <ComposedChart margin={{ top: 0, right: spacing(2), bottom: spacing(4), left: spacing(1) }} data={data.periods}>
          {ToggleLegend({ options })}
          <CartesianGrid />
          <XAxis
            dataKey="date"
            allowDataOverflow
            tickFormatter={(tick: number) => getDateAsWeek(new Date(tick))}
            angle={-45}
            textAnchor="end"
            domain={[startDate.getTime(), endDate.getTime()]}
          />
          {legendToggledItems.includes(LegendKey.OkPercentageTime) && (
            <Bar dataKey={LegendKey.OkPercentageTime} fill={palette.leaf} stackId="stack" yAxisId="left-axis" />
          )}
          {legendToggledItems.includes(LegendKey.HighPercentageTime) && (
            <Bar dataKey={LegendKey.HighPercentageTime} fill={palette.glow} stackId="stack" yAxisId="left-axis" />
          )}
          {legendToggledItems.includes(LegendKey.LowPercentageTime) && (
            <Bar dataKey={LegendKey.LowPercentageTime} fill={palette.lava} stackId="stack" yAxisId="left-axis" />
          )}
          {showIncidentCountValues && legendToggledItems.includes(LegendKey.HighPeriodCount) && (
            <Line
              dataKey={LegendKey.HighPeriodCount}
              stroke={palette.lightning}
              strokeWidth={4}
              yAxisId="right-axis"
              isAnimationActive={false}
            />
          )}
          {showIncidentCountValues && legendToggledItems.includes(LegendKey.LowPeriodCount) && (
            <Line
              dataKey={LegendKey.LowPeriodCount}
              stroke={palette.electric}
              strokeWidth={4}
              yAxisId="right-axis"
              isAnimationActive={false}
            />
          )}
          {showAverageLengthValues && legendToggledItems.includes(LegendKey.AverageHighPeriodDuration) && (
            <Line
              dataKey={LegendKey.AverageHighPeriodDuration}
              stroke={palette.lightning}
              strokeWidth={4}
              yAxisId="right-axis"
              isAnimationActive={false}
            />
          )}
          {showAverageLengthValues && legendToggledItems.includes(LegendKey.AverageLowPeriodDuration) && (
            <Line
              dataKey={LegendKey.AverageLowPeriodDuration}
              stroke={palette.electric}
              strokeWidth={4}
              yAxisId="right-axis"
              isAnimationActive={false}
            />
          )}
          <YAxis
            width={spacing(8)}
            domain={[0, 100]}
            ticks={[0, 25, 50, 75, 100]}
            tickFormatter={(tick: number) => `${tick}.00`}
            dataKey="percentagePerformance"
            allowDataOverflow
            yAxisId="left-axis"
          />
          <YAxis
            width={spacing(8)}
            dataKey={showIncidentCountValues ? LegendKey.HighPeriodCount : LegendKey.AverageHighPeriodDuration}
            tickFormatter={(tick) => (showIncidentCountValues ? tick : round(tick / 60, 0))}
            allowDataOverflow
            yAxisId="right-axis"
            orientation="right"
          />
          <Tooltip content={<TooltipContent title={leftAxisTitle} />} />
        </ComposedChart>
      </ResponsiveContainer>
      <VerticalTitle title={{ default: leftAxisTitle, id: '123' }} className={styles.leftLabel} />
      <VerticalTitle title={{ default: rightAxisTitle, id: '123' }} className={styles.rightLabel} />
      <AxisTitle title={<LocalisedMessage descriptor={msg.Date} />} />
    </div>
  );
};
export default Chart;
