/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PickersDayProps } from '@mui/x-date-pickers';
import { getDayOfYear } from 'date-fns';
import useDatePickerState from './useDatePickerState';
import CustomPickersDay from './CustomPickersDay';

const DefaultPickerDay = (day: string, selectedDates: string[], pickersDayProps: PickersDayProps<string>) => {
  const { currentValue } = useDatePickerState();
  if (!currentValue) {
    return <CustomPickersDay {...pickersDayProps} />;
  }
  const selectedDay = getDayOfYear(new Date(day));
  const currentDay = getDayOfYear(currentValue);
  const dayIsSelected = selectedDay === currentDay;
  return <CustomPickersDay {...pickersDayProps} selected={dayIsSelected} isInBetween={false} />;
};

export default DefaultPickerDay;
