import { FeatureName } from '../../../types';
import { PlantData } from '../../../utils/useHistoricalPlantData';

type Payload = {
  value: number;
  date: number;
};

type ChartData = Payload[];
const useHistoricalChartData = (featureName: FeatureName, historicalData: PlantData): ChartData => {
  const { items = [] } = historicalData;
  return items
    .filter((item) => !!item.data[featureName])
    .map((item) => ({
      value: item.data[featureName]?.value ?? 0,
      date: new Date(item.timestamp).getTime()
    }));
};

export default useHistoricalChartData;
