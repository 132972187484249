import { useMemo } from 'react';
import { FeatureName } from '../../../types';
import { PlantData } from '../../../utils/useHistoricalPlantData';

const useChartData = (data: PlantData, activeFeatures: FeatureName[] = []) =>
  useMemo(() => {
    const newChartData = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const field of activeFeatures ?? []) {
      newChartData.set(field, []);
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const item of data.items) {
      const timestamp = new Date(item.timestamp).getTime();
      // eslint-disable-next-line no-restricted-syntax
      for (const field of activeFeatures ?? []) {
        if (item.data[field]) {
          newChartData.get(field).unshift({ x: timestamp, y: item.data[field]?.value });
        }
      }
    }
    return newChartData;
  }, [data, activeFeatures]);

export default useChartData;
