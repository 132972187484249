import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { usePath } from '../utils';
import { useStyles } from './IconLink';

type ConfigType = 'form' | 'json';

type Props = {
  configType: ConfigType;
};

const ConfigNavLink = ({ configType }: Props) => {
  let configPath;
  let defaultMessage;
  let messageId;
  if (configType === 'form') {
    configPath = '/config';
    defaultMessage = 'Config (form)';
    messageId = 'buttons.configForm';
  } else {
    configPath = '/config-json';
    defaultMessage = 'Config (json)';
    messageId = 'buttons.configJson';
  }
  const path = usePath(configPath);
  const styles = useStyles();
  return (
    <NavLink to={path} className={styles.root}>
      <FormattedMessage defaultMessage={defaultMessage} id={messageId} />
    </NavLink>
  );
};

export default ConfigNavLink;
