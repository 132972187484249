import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../../../EzerThemeProvider';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    backgroundColor: palette.rich,
    borderTopRightRadius: spacing(0.5),
    paddingBottom: spacing(2)
  }
}));

const SectionContent = ({ children, className }: Props) => {
  const styles = useStyles();
  return <div className={classNames(styles.root, className)}>{children}</div>;
};

export default SectionContent;
