import React, { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ToggleButton from './ToggleButton';

export type TogglesData = { value: string; label: ReactNode }[];

type Props = {
  data: TogglesData;
  exclusiveMode: boolean;
  disabled?: boolean;
};
const Toggles = ({ data, exclusiveMode, disabled = false }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const toggleSearchParam = searchParams.get('toggles');
  const isParamSet = !!toggleSearchParam;

  useEffect(() => {
    if (!isParamSet && data.length) {
      const defaultValue = exclusiveMode ? data[0].value : data.map((d) => d.value).join(',');
      searchParams.set('toggles', defaultValue);

      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, isParamSet, data, exclusiveMode]);
  return (
    <>
      {data.map((dataItem) => (
        <ToggleButton
          key={dataItem.value}
          value={dataItem.value}
          disabled={disabled || !isParamSet}
          exclusiveMode={exclusiveMode}>
          {dataItem.label}
        </ToggleButton>
      ))}
    </>
  );
};
export default Toggles;
