import { theme } from '../EzerThemeProvider';
import { Payload } from '../types/QualityChartData';

type Props = {
  cx: number;
  cy: number;
  index: number;
  payload: Payload;
  points: {
    value: number;
  }[];
};

const { white } = theme.palette;

const MeasuredValueDot = ({ cx, cy, index, payload, points }: Props) => {
  const currentValue = payload.measured;
  const previousValue = index < points.length - 1 ? points[index + 1].value : 0;
  const width = 10;
  const radius = width / 2;

  if (currentValue !== previousValue && cy !== null) {
    return (
      <svg x={cx - radius} y={cy - radius} fill={white} width={width} height={width}>
        <circle cx={radius} cy={radius} r={radius} />
      </svg>
    );
  }
  return <></>;
};

export default MeasuredValueDot;
