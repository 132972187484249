import { addDays, subDays, getDay } from 'date-fns';

const MS_IN_ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

const getMonday = (firstOfJan: Date) => {
  const dayOfWeek = getDay(firstOfJan) === 0 ? 6 : getDay(firstOfJan) - 1;
  if (dayOfWeek === 0) {
    return firstOfJan;
  }
  if (dayOfWeek <= 3) {
    return subDays(firstOfJan, 7 - dayOfWeek);
  }
  return addDays(firstOfJan, 7 - dayOfWeek);
};

const convertPeriodToTime = (period: string): number => {
  const parts = period.split('-W');
  const firstMonday = getMonday(new Date(Date.UTC(Number(parts[0]), 0)));
  return firstMonday.getTime() + MS_IN_ONE_WEEK * (Number(parts[1]) - 1);
};

export default convertPeriodToTime;
