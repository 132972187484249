import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { ConfigType, ConfigWrapperType } from '../Config/ConfigSchema';
import { Api, API_SERVE_BASE_URL } from '../settings';
import useAuthorisedRequest from './useAuthorisedRequest';

export function getClashingPaths(formData: ConfigType): string[] {
  const emptyPaths: Record<string, number> = {};
  const paths = formData.pages
    .flatMap((page) => (page.type === 'category' ? page.pages : page))
    .reduce((acc, elem) => {
      acc[elem.path] = (acc[elem.path] || 0) + 1;
      return acc;
    }, emptyPaths);
  return Object.entries(paths)
    .filter(([, count]) => count > 1)
    .map(([path]) => path);
}

const useSaveConfig = () => {
  const authorisedRequest = useAuthorisedRequest(Api.serve);
  const [searchParams] = useSearchParams();

  const mutationFn = async (configWrapper: ConfigWrapperType) => {
    const clashingPaths = getClashingPaths(configWrapper.config);
    if (clashingPaths.length > 0) {
      throw new Error(`Clashing paths: ${clashingPaths.join(', ')}`);
    }
    const url = new URL(`${API_SERVE_BASE_URL}/config/`);
    const customer = searchParams.get('customer');
    const alias = searchParams.get('configAlias');

    return authorisedRequest(url.toString(), {
      method: 'POST',
      body: JSON.stringify({
        plant_ref: customer,
        config: configWrapper.config,
        alias,
        existing_version_num: configWrapper.version?.number
      })
    }).then(async (response) => {
      if (response.ok) {
        return response;
      }
      throw new Error(await response.text());
    });
  };

  return useMutation({ mutationFn });
};

export default useSaveConfig;
