import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { EzerTheme } from '../../../EzerThemeProvider';

type Props = {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
};

const useStyles = createUseStyles(({ palette, spacing }: EzerTheme) => ({
  root: {
    background: palette.carbon,
    borderRadius: spacing(0.5),
    marginTop: spacing(2),
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2)
  }
}));

const Section = ({ children, dataTestId, className }: Props) => {
  const styles = useStyles();
  return (
    <div data-testid={dataTestId} className={classNames(styles.root, className)}>
      {children}
    </div>
  );
};

export default Section;
