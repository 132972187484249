import { useSearchParams } from 'react-router-dom';
import type { Timezone } from '../Config/sharedSchemas';
import useConfig from '../Config/useConfig';

const useTimezone = (): Timezone => {
  const { data: config } = useConfig();

  const [searchParams] = useSearchParams();
  return (searchParams.get('timezone') as Timezone) || config?.app.timezone || 'Europe/London';
};

export default useTimezone;
