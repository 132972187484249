import { ReactNode } from 'react';
import { InferenceRecommendation } from '../../../types/InferenceRecommendation';
import { getLocalDateAndTime, useTimezone } from '../../../utils';
import { Feedback } from '../../../utils/useHistoricalFeedbacks';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import type { Feature } from '../schema';

type RowKeys = 'id' | 'timestamp' | 'feedback' | string;
type RowValues = string | number | ReactNode;
type Row = Record<RowKeys, RowValues>;

const useRows = (
  features: Feature[],
  data: InferenceRecommendation[],
  plantData: PlantData,
  feedbackData: Feedback[]
): Row[] => {
  const newRows: Row[] = [];
  const { items: plantDataItems = [] } = plantData;
  const timezone = useTimezone();

  data.forEach((dataItem) => {
    const { timestamp, value, feature } = dataItem;
    const plantDataItem = plantDataItems.find((datum) => new Date(datum.timestamp).getTime() === timestamp.getTime());
    const newRow: Row = {};
    newRow.id = timestamp.getTime();
    newRow.timestamp = getLocalDateAndTime(new Date(timestamp), timezone);

    features.forEach(({ historicalFeatureName, recommendedFeatureName }) => {
      newRow[`${historicalFeatureName}-historical`] = plantDataItem?.data?.[historicalFeatureName]?.value;
      if (feature === recommendedFeatureName) {
        newRow[`${recommendedFeatureName}-recommended`] = value;
      }
    });

    newRow.feedback = <FeedbackForm recommendation={dataItem} feedbacks={feedbackData} />;
    newRows.push(newRow);
  });
  return newRows.reverse();
};

export default useRows;
