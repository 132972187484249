import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TooltipProps } from 'recharts';
import { ChartTooltip, LocalisedLabel, LocalisedMessage } from '../../lib';
import { getLocalDate } from '../../utils';
import * as msg from './messages';
import { Label } from '../../types';

interface Props extends TooltipProps<string, string> {
  yAxisLabel: Label;
}

const WeekStart = () => <FormattedMessage defaultMessage="Week Start" id="labels.weekStart" />;
const TimeWithinBounds = () => <LocalisedMessage descriptor={msg.TimeInBounds} />;
const StandardDeviation = () => <LocalisedMessage descriptor={msg.StdDev} />;

const TooltipContent = ({ label, payload = [], yAxisLabel }: Props) => {
  const getLabel = (name?: string) => {
    switch (name) {
      case 'percentWithinBounds': {
        return <TimeWithinBounds />;
      }
      case 'standardDeviation': {
        return <StandardDeviation />;
      }
      default: {
        return <LocalisedLabel>{yAxisLabel}</LocalisedLabel>;
      }
    }
  };

  const itemListData = payload.map(({ value, color, dataKey }) => ({
    label: (
      <>
        {getLabel(String(dataKey))}: {value} %
      </>
    ),
    color
  }));
  const tooltipLabel = label ? (
    <>
      <WeekStart />: {getLocalDate(new Date(label), 'UTC')}
    </>
  ) : (
    <></>
  );
  return <ChartTooltip label={tooltipLabel} itemListData={itemListData} />;
};

export default TooltipContent;
